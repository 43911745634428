import React, { useState, useEffect } from 'react';

import { Form, Modal, Select, Input, InputNumber, Flex } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAppDispatch } from '@store/hooks';

import { getEnumList, toFinanceString } from '@extensions/utils';
import { warning, exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';

import { ITransactionParams } from '@entities/transaction-params';
import { IUserAccount } from '@entities/user-account';

import { IEnumItem } from '@enums/enum-item';
import { Currency, enumSign } from '@enums/currency';
import { CurrencyRateType, enumLabel as rateTypeLabel } from '@enums/currency-rate-type';
import { CurrencyRateCommissionType } from '@enums/currency-rate-commission-type';

import { RubleFilledIcon, UsdFilledIcon, EuroFilledIcon } from '@icons/filled/index';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ICBRates {
    Valute: any;
}

interface ICurrencyRate {
    CharCode: string;
    Value: number;
}

interface ITransfer {
    userId: string;
    userLogin: string;
    accounts: Array<IUserAccount>;
    onSave: (value: ITransactionParams) => void;
    onCancel: () => void;
    api: any;
}

const TransferAccountsModal = (props: ITransfer) => {
    const d = useAppDispatch();

    const cbUrl: string = process.env.REACT_APP_CB_URL || '';

    const [form] = Form.useForm();

    const { userLogin, userId, accounts, onSave, onCancel, api } = props;

    const [currentFromUserAccount, setCurrentFromUserAccount] = useState<IUserAccount>();
    const [currentUserAccount, setCurrentUserAccount] = useState<IUserAccount>();
    const [usdCBRate, setUsdCBRate] = useState<ICurrencyRate>();
    const [eurCBRate, setEurCBRate] = useState<ICurrencyRate>();
    const [loadingCBRate, setLoadingCBRate] = useState<boolean>(false);
    const [rateTypes] = useState<Array<IEnumItem>>(getEnumList(CurrencyRateType, rateTypeLabel));
    const [rateCommissionTypes] = useState<Array<IEnumItem>>(getEnumList(CurrencyRateCommissionType));

    const [currentRateType, setCurrentRateType] = useState<CurrencyRateType>(CurrencyRateType.CentralBank);

    useEffect(() => {
        form.setFieldsValue({
            userId: userId,
            currencyRateCommissionType: CurrencyRateCommissionType.Percentage,
        });

        setLoadingCBRate(true);

        fetch(cbUrl)
            .then((res) => res.json())
            .then((data: ICBRates) => {
                setUsdCBRate(data.Valute['USD']);
                setEurCBRate(data.Valute['EUR']);

                setLoadingCBRate(false);
            })
            .catch((ex) => {
                setLoadingCBRate(false);
                exception(api, 'Ошибка получения курса доллара ЦБ', ex, () => d(userLoaded(undefined)));
            });
    }, []);

    useEffect(() => {
        if (
            currentFromUserAccount?.currency == Currency.Rub &&
            currentUserAccount &&
            currentRateType &&
            currentRateType == CurrencyRateType.CentralBank
        ) {
            form.setFieldValue('currencyRate', currentUserAccount?.currency == Currency.Usd ? usdCBRate?.Value : eurCBRate?.Value);
        } else {
            form.setFieldValue('currencyRate', undefined);
        }

        calcaulateAmount();
    }, [currentFromUserAccount, currentUserAccount, currentRateType]);

    const onSaveTransaction = (entity: ITransactionParams) => {
        if (!entity.amount) return;

        let fromAccount = accounts.find((a) => a.id == entity.fromUserAccountId);

        if (!fromAccount?.amount || fromAccount?.amount < entity.amount) {
            warning(api, 'Недостаточно средств');
            return;
        }

        entity.currency = fromAccount.currency;

        onSave(entity);
    };

    const calcaulateAmount = () => {
        let amount = form.getFieldValue('amount');

        if (!amount) {
            form.setFieldValue('amount', undefined);
            return;
        }

        let currencyRate = form.getFieldValue('currencyRate');

        let rateCommission = form.getFieldValue('currencyRateCommission');
        let rateCommissionType: CurrencyRateCommissionType = form.getFieldValue('currencyRateCommissionType');

        if (rateCommission && rateCommissionType) {
            if (rateCommissionType == CurrencyRateCommissionType.Percentage) {
                currencyRate += currencyRate * rateCommission * 0.01;
            } else {
                currencyRate += rateCommission;
            }
        }

        console.log('haha', currencyRate);


        if (currencyRate) {
            var result = amount / currencyRate;
            form.setFieldValue('resultAmount', result);
        } else {
            form.setFieldValue('resultAmount', amount);
        }
    };

    return (
        <Modal
            destroyOnClose={true}
            width={550}
            title={`Перевод между счетами "${userLogin}"`}
            open={true}
            okText='ОК'
            onOk={() => {
                form.submit();
            }}
            onCancel={() => onCancel()}
        >
            <Form
                colon={false}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ marginTop: 20 }}
                form={form}
                onFinish={onSaveTransaction}
            >
                <Form.Item hidden name='userId'>
                    <Input />
                </Form.Item>
                <Form.Item hidden name='currencyRateCommissionType'>
                    <Input />
                </Form.Item>

                <Form.Item
                    name='fromUserAccountId'
                    label='Счет cписания'
                    rules={[{ required: true, message: 'Укажите счет для списания' }]}
                >
                    <Select
                        onChange={(value: string) => {
                            setCurrentFromUserAccount(accounts.find((a) => a.id == value));
                            form.setFieldValue('userAccountId', undefined);
                        }}
                        options={accounts.map((a) => {
                            return {
                                value: a.id,
                                label: (
                                    <Flex align='center'>
                                        {a.currency == Currency.Rub ? (
                                            <RubleFilledIcon style={{ fontSize: 18, marginRight: 2 }} />
                                        ) : a.currency == Currency.Usd ? (
                                            <UsdFilledIcon style={{ fontSize: 18, marginRight: 2 }} />
                                        ) : (
                                            <EuroFilledIcon style={{ fontSize: 18, marginRight: 2 }} />
                                        )}

                                        <span>{toFinanceString(a.amount || 0, 2)}</span>
                                    </Flex>
                                ),
                            };
                        })}
                    />
                </Form.Item>
                <Form.Item
                    name='userAccountId'
                    label='Счет пополнения'
                    rules={[{ required: true, message: 'Укажите счет для пополнения' }]}
                >
                    <Select
                        disabled={!currentFromUserAccount}
                        onChange={(value: string) => {
                            setCurrentUserAccount(accounts.find((a) => a.id == value));
                        }}
                        options={accounts
                            .filter((a) => a.id != currentFromUserAccount?.id)
                            .map((a) => {
                                return {
                                    value: a.id,
                                    label: (
                                        <Flex align='center'>
                                            {a.currency == Currency.Rub ? (
                                                <RubleFilledIcon style={{ fontSize: 18, marginRight: 2 }} />
                                            ) : a.currency == Currency.Usd ? (
                                                <UsdFilledIcon style={{ fontSize: 18, marginRight: 2 }} />
                                            ) : (
                                                <EuroFilledIcon style={{ fontSize: 18, marginRight: 2 }} />
                                            )}

                                            <span>{toFinanceString(a.amount || 0, 2)}</span>
                                        </Flex>
                                    ),
                                };
                            })}
                    />
                </Form.Item>
                <Form.Item label='Сумма' name='amount' wrapperCol={{ span: 9 }} rules={[{ required: true, message: 'Укажите сумму' }]}>
                    <InputNumber
                        precision={2}
                        decimalSeparator=','
                        min={0}
                        style={{ width: '100%' }}
                        onChange={(value: number | null) => {
                            form.setFieldValue('amount', value);
                            calcaulateAmount();
                        }}
                    />
                </Form.Item>
                {currentFromUserAccount?.currency == Currency.Rub && (
                    <Form.Item
                        initialValue={currentRateType}
                        label='Источник курс'
                        name='currencyRateType'
                        rules={[{ required: true, message: 'Укажите источник курс' }]}
                    >
                        <Select
                            loading={loadingCBRate}
                            onChange={(value: CurrencyRateType) => {
                                setCurrentRateType(value);
                            }}
                            options={rateTypes.map((a) => {
                                return { value: a.value, label: a.label };
                            })}
                        />
                    </Form.Item>
                )}

                <Form.Item label='Курс'>
                    <Flex align='center' gap='middle'>
                        <Form.Item
                            name='currencyRate'
                            rules={[{ required: true, message: 'Укажите курс' }]}
                            style={{ width: '50%', marginBottom: 0 }}
                        >
                            <InputNumber
                                decimalSeparator=','
                                precision={4}
                                style={{ width: '100%' }}
                                min={0}
                                onChange={(value: number | null) => {
                                    form.setFieldValue('currencyRate', value);
                                    calcaulateAmount();
                                }}
                            />
                        </Form.Item>

                        <PlusOutlined />

                        <Form.Item name='currencyRateCommission' style={{ width: '50%', marginBottom: 0 }}>
                            <InputNumber
                                precision={2}
                                decimalSeparator=','
                                min={0}
                                onChange={(value: number | null) => {
                                    form.setFieldValue('currencyRateCommission', value);
                                    calcaulateAmount();
                                }}
                                addonAfter={
                                    <Select
                                        defaultValue={CurrencyRateCommissionType.Percentage}
                                        disabled={!currentUserAccount}
                                        onChange={(value: CurrencyRateCommissionType) => {
                                            form.setFieldValue('currencyRateCommissionType', value);
                                            calcaulateAmount();
                                        }}
                                        options={rateCommissionTypes.map((c) => {
                                            return {
                                                value: c.value,
                                                label:
                                                    c.value == CurrencyRateCommissionType.Percentage
                                                        ? '%'
                                                        : enumSign(currentFromUserAccount?.currency),
                                            };
                                        })}
                                    />
                                }
                            />
                        </Form.Item>
                    </Flex>
                </Form.Item>
                <Form.Item name='resultAmount' label='ИТОГО'>
                    <InputNumber
                        decimalSeparator=','
                        disabled={true}
                        precision={2}
                        min={0}
                        style={{ width: '50%', background: '#f6fefa' }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default TransferAccountsModal;

import React from 'react';
import Icon from '@ant-design/icons';

const CombinedIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18 }} {...props} />;
};
export default CombinedIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
            <g>
                <g>
                    <path d='M132.129,0v132.129H0V512h379.871V379.871H512V0H132.129z M330.323,462.452H49.548V181.677h82.581v198.194h198.194 V462.452z M330.323,330.323H181.677V181.677h148.645V330.323z M462.452,330.323h-82.581V132.129H181.677V49.548h280.774V330.323z'></path>
                </g>
            </g>
        </g>
    </svg>
);

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Input, Form, Space, Select, notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';

import { getEnumList } from '@extensions/utils';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IWarehouse } from '@entities/warehouse';
import { ICity } from '@entities/city';

import { Permission, hasPermission } from '@enums/permission';
import { WarehouseType, enumLabel } from '@src/core/enums/warehouse-type';
import { IEnumItem } from '@enums/enum-item';

const Warehouse = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [cities, setCities] = useState<Array<ICity>>([]);

    const [entity, setEntity] = useState<IWarehouse>({
        id: undefined,
        cityId: undefined,
        code: undefined,
        type: undefined,
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [manageCity] = useState<boolean>(hasPermission(userSession.permissions, Permission.ManageWarehouses));
    const [types] = useState<Array<IEnumItem>>(getEnumList(WarehouseType, enumLabel));

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (id) {
                promises.push(
                    await serverFetch(`warehouses/${id}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения склада', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCities(result[0][0]);

                setEntity(result[0][1] || entity);

                setLoading(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, []);

    const onSave = () => {
        setLoading(true);

        serverFetch(`warehouses`, { method: 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения склада', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader title='Добавить склад' />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} onFinish={onSave}>
                    <Form.Item
                        initialValue={entity?.cityId}
                        required
                        label='Город доставки'
                        name='city'
                        rules={[{ required: true, message: 'Выберите город' }]}
                    >
                        <Select
                            autoFocus
                            showSearch
                            onChange={(value) => setEntity({ ...entity, cityId: value })}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={cities.map((c) => {
                                return { value: c.id, label: c.name };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.code}
                        required
                        label='Код'
                        name='code'
                        rules={[{ required: true, message: 'Укажите код' }]}
                    >
                        <Input
                            disabled={!manageCity}
                            onChange={(data) => {
                                setEntity({ ...entity, code: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity?.type}
                        required
                        label='Тип'
                        name='type'
                        rules={[{ required: true, message: 'Выберите тип' }]}
                    >
                        <Select
                            autoFocus
                            showSearch
                            onChange={(value) => setEntity({ ...entity, type: value })}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={types.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />
                    </Form.Item>
                    <Form.Item label='' wrapperCol={{ offset: 3, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='link' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {manageCity && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default Warehouse;

import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, InputNumber, Input, Form, Space, Select, Switch } from 'antd';
import { notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';
import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { getEnumList } from '@extensions/utils';

import { exception, error } from '@extensions/notification';
import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IWaypoint } from '@entities/waypoint';
import { IWarehouse } from '@entities/warehouse';

import { WarehouseType } from '@enums/warehouse-type';
import { Permission, hasPermission } from '@enums/permission';
import { WaypointType, enumLabel as waypointTypeLabel } from '@enums/waypoint-type';
import { RouteZone, enumLabel as routeZoneLabel } from '@enums/route-zone';

const Waypoint = () => {
    const { id, routeZone, headerId } = useParams();

    const navigate = useNavigate();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [api, contextHolder] = notification.useNotification();

    const [entity, setEntity] = useState<IWaypoint>({
        id: undefined,
        type: undefined,
        sequence: 0,
        name: undefined,
        routeZone: routeZone ? (+routeZone as RouteZone) : undefined,
        allowAcceptance: false,
        archived: false,
        warehouseId: undefined,
        headerId: headerId,
    });

    const [warehouses, setWarehouses] = useState<Array<IWarehouse>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [waypointTypes] = useState(getEnumList(WaypointType, waypointTypeLabel));
    const [routeZones] = useState(getEnumList(RouteZone, routeZoneLabel));
    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageWaypoints));

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('warehouses', { method: 'GET', queryParams: { type: WarehouseType.Main } })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения складов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (id) {
                promises.push(
                    await serverFetch(`waypoints/${id}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения маршрутной точки', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setWarehouses(result[0][0]);

                if (id) setEntity(result[0][1]);

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    const onFinish = () => {
        setLoading(true);

        serverFetch(`waypoints`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения маршрутной точки', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader
                label={`${id ? (isViewOnly ? 'Информация о маршрутной точке' : 'Изменить маршрутную точку') : 'Добавить маршрутную точку'}`}
            />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 5 }} onFinish={onFinish}>
                    <Form.Item
                        initialValue={entity.routeZone}
                        label='Зона маршрута'
                        name='routeZone'
                        required
                        rules={[{ required: true, message: 'Укажите зону маршрута' }]}
                    >
                        <Select
                            allowClear
                            disabled={isViewOnly}
                            onChange={(value) => {
                                setEntity({ ...entity, routeZone: value });
                            }}
                            options={routeZones.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.type}
                        label='Тип'
                        name='type'
                        required
                        rules={[{ required: true, message: 'Укажите тип точки' }]}
                    >
                        <Select
                            allowClear
                            disabled={isViewOnly}
                            onChange={(value) => {
                                setEntity({ ...entity, type: value });
                            }}
                            options={waypointTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />
                    </Form.Item>
                    <Form.Item initialValue={entity.warehouseId} label='Склад' name='warehouseId'>
                        <Select
                            allowClear
                            disabled={isViewOnly}
                            onChange={(value) => {
                                setEntity({ ...entity, warehouseId: value });
                            }}
                            options={warehouses.map((t) => {
                                return { value: t.id, label: t.code };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={entity.sequence}
                        label='Порядковый номер'
                        name='sequence'
                        required
                        rules={[{ required: true, message: 'Укажите порядковый номер' }]}
                    >
                        <InputNumber
                            disabled={isViewOnly}
                            onChange={(value: number | null) => {
                                setEntity({ ...entity, sequence: value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='Приемка груза' name='allowAcceptance' valuePropName='checked'>
                        <Switch
                            disabled={isViewOnly}
                            defaultChecked={entity?.allowAcceptance}
                            onChange={(value) => {
                                setEntity({ ...entity, allowAcceptance: value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item initialValue={entity.name} label='Название' name='name'>
                        <Input
                            disabled={isViewOnly}
                            autoFocus
                            onChange={(data) => {
                                setEntity({ ...entity, name: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='' wrapperCol={{ offset: 3, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='link' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            {!isViewOnly && (
                                <Button type='primary' htmlType='submit' loading={loading}>
                                    Сохранить
                                </Button>
                            )}
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default Waypoint;

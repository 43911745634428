import React from 'react';
import Icon from '@ant-design/icons';

const TruckIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 20 }} {...props} />;
};
export default TruckIcon;

const svg = ({ ...props }) => (
    <svg version='1.1' id='_x32_' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='currentColor' {...props}>
        <g>
            <path
                d='M499.29,82.175H214.519c-7.022,0-12.71,6.024-12.71,13.452v200.679c0,7.428,5.688,13.434,12.71,13.434H499.29
       c7.022,0,12.71-6.005,12.71-13.434V95.627C512,88.199,506.312,82.175,499.29,82.175z'
            />
            <path
                d='M102.781,354.37c-19.705,0-35.692,16.878-35.692,37.723c0,20.836,15.987,37.732,35.692,37.732
       c19.697,0,35.665-16.896,35.665-37.732C138.446,371.248,122.478,354.37,102.781,354.37z'
            />
            <path
                d='M501.41,325.55H197.455c-5.847,0-10.599-5.026-10.599-11.208v-167.32c0-6.192-4.734-11.208-10.599-11.208
       H79.711c-3.144,0-6.12,1.483-8.126,4.027L2.455,227.573C0.866,229.578,0,232.122,0,234.737v76.955v40.24
       c0,6.183,4.743,11.199,10.59,11.199h53.267c8.629-12.93,22.85-21.418,38.924-21.418c16.066,0,30.278,8.488,38.916,21.418h217.407
       c8.629-12.93,22.84-21.418,38.916-21.418c16.074,0,30.276,8.488,38.915,21.418h64.476c5.847,0,10.59-5.016,10.59-11.199v-15.174
       C512,330.567,507.257,325.55,501.41,325.55z M38.703,243.648v-7.754c0-2.65,0.856-5.22,2.438-7.296l44.126-57.463
       c2.085-2.702,5.193-4.265,8.479-4.265h43.234c6.041,0,10.934,5.166,10.934,11.543v65.236c0,6.377-4.894,11.544-10.934,11.544H49.62
       C43.596,255.192,38.703,250.025,38.703,243.648z'
            />
            <path
                d='M398.02,354.37c-19.705,0-35.674,16.878-35.674,37.723c0,20.836,15.97,37.732,35.674,37.732
       c19.696,0,35.673-16.896,35.673-37.732C433.693,371.248,417.715,354.37,398.02,354.37z'
            />
        </g>
    </svg>
);

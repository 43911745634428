import React from 'react';
import Icon from '@ant-design/icons';

const RefundIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 20, marginTop: 1 }} {...props} />;
};
export default RefundIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
            <g>
                <path fill='none' d='M0 0h24v24H0z'></path>
                <path d='M5.671 4.257c3.928-3.219 9.733-2.995 13.4.672 3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0A9.993 9.993 0 0 1 2.25 9.767l.077-.313 1.934.51a8 8 0 1 0 3.053-4.45l-.221.166 1.017 1.017-4.596 1.06 1.06-4.596 1.096 1.096zM13 6v2h2.5v2H10a.5.5 0 0 0-.09.992L10 11h4a2.5 2.5 0 1 1 0 5h-1v2h-2v-2H8.5v-2H14a.5.5 0 0 0 .09-.992L14 13h-4a2.5 2.5 0 1 1 0-5h1V6h2z'></path>
            </g>
        </g>
    </svg>
);

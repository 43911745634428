import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Divider, Col, Row, Input, Form, Space, Button, DatePicker, Tooltip, Modal, Select, notification } from 'antd';
import { RollbackOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import FormHeader from '@controls/form-header/form-header';
import Trackpoints from './trackpoints';

import { exception } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { ITruck } from '@entities/truck';
import { IWaypointHeader } from '@entities/waypoint-header';

import { TruckStatus, enumLabel } from '@enums/truck-status';
import { TruckType } from '@enums/truck-type';
import { Permission, hasPermission } from '@enums/permission';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Truck = () => {
    const { TextArea } = Input;

    const navigate = useNavigate();
    const [api, notificationContextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const { id } = useParams();

    const [modal, modalContextHolder] = Modal.useModal();

    const [entity, setEntity] = useState<ITruck>({
        id: undefined,
        number: undefined,
        departureOn: null,
        type: TruckType.Import,
        status: TruckStatus.New,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [canManageTruck] = useState<boolean>(hasPermission(userSession.permissions, Permission.ManageTruck));

    const [headers, setHeaders] = useState<Array<IWaypointHeader>>([]);

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch(`trucks/${id}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машины', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`waypointheaders`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маршрутов', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                if (id) {
                    setEntity(result[0][0]);
                }

                setHeaders(result[0][1]);

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    const onFinish = () => {
        setLoading(true);

        serverFetch(`trucks`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения машины', ex, () => d(userLoaded(undefined)));
            });
    };

    const onRevertOnway = () => {
        serverFetch(`warehouse/${id}/revertacceptance`, { method: 'POST' })
            .then(() => {
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка изменения статуса', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <Row>
                <FormHeader title={`${id ? (canManageTruck ? 'Информация о машине' : 'Управление машиной') : 'Добавить машину'}`} />
            </Row>

            {!loading && (
                <>
                    <Row>
                        <Col span={10}>
                            <Form colon={false} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onFinish}>
                                <Form.Item
                                    initialValue={entity.number}
                                    required={true}
                                    label='Номер'
                                    name='number'
                                    rules={[{ required: true, message: 'Укажите номер машины' }]}
                                >
                                    <Input
                                        disabled={!canManageTruck}
                                        autoFocus
                                        onChange={(data: React.ChangeEvent<HTMLInputElement>) => {
                                            setEntity({ ...entity, number: data.target.value });
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    required={true}
                                    label='Маршрут'
                                    name='waypointHeaderId'
                                    initialValue={entity.waypointHeaderId}
                                    rules={[{ required: true, message: 'Укажите маршрут' }]}
                                >
                                    <Select
                                        disabled={entity.status == TruckStatus.Finished || entity.status == TruckStatus.Cancelled}
                                        onChange={(value) => {
                                            if (entity.status != TruckStatus.New) {
                                                modal.confirm({
                                                    title: `Маршурт будет перестроить, продолжить?`,
                                                    icon: <QuestionCircleOutlined />,
                                                    okType: 'primary',
                                                    okText: 'ОК',
                                                    cancelText: 'Отмена',
                                                    onOk: () => setEntity({ ...entity, waypointHeaderId: value }),
                                                });
                                            } else {
                                                setEntity({ ...entity, waypointHeaderId: value });
                                            }
                                        }}
                                        options={headers.map((h) => {
                                            return { value: h.id, label: h.name };
                                        })}
                                    />
                                </Form.Item>

                                {id && (
                                    <Form.Item label='Статус'>
                                        <Space.Compact style={{ width: '100%' }}>
                                            <Input disabled={true} defaultValue={enumLabel(entity.status)} />
                                            {hasPermission(userSession.permissions, Permission.FullAccess) &&
                                                entity.status === TruckStatus.Finished && (
                                                    <Tooltip title='Вернуть статус "В Пути"'>
                                                        <Button
                                                            type='primary'
                                                            icon={<RollbackOutlined />}
                                                            onClick={() => {
                                                                modal.confirm({
                                                                    title: `Изменить статус машины на "В Пути"?`,
                                                                    okType: 'primary',
                                                                    icon: <QuestionCircleOutlined />,
                                                                    okText: 'ОК',
                                                                    cancelText: 'Отмена',
                                                                    onOk: () => {
                                                                        onRevertOnway();
                                                                    },
                                                                });
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                        </Space.Compact>
                                    </Form.Item>
                                )}

                                {id && (
                                    <Form.Item
                                        label='Дата отправки'
                                        name='departureOn'
                                        initialValue={entity.departureOn && dayjs.utc(entity.departureOn).local()}
                                    >
                                        <DatePicker
                                            disabled={!canManageTruck}
                                            format={'DD.MM.YYYY'}
                                            onChange={(data) => {
                                                setEntity({
                                                    ...entity,
                                                    departureOn: dayjs(data).utc(true).set('hour', 0).set('minute', 0).set('second', 0),
                                                });
                                            }}
                                        />
                                    </Form.Item>
                                )}
                                <Form.Item initialValue={entity.trackingUrl} label='Трекинг URL' name='trackingUrl'>
                                    <Input
                                        disabled={!canManageTruck}
                                        onChange={(data: React.ChangeEvent<HTMLInputElement>) => {
                                            if (!entity) return;

                                            setEntity({ ...entity, trackingUrl: data.target.value });
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label='Комментарий' name='comment' initialValue={entity.comment}>
                                    <TextArea
                                        disabled={!canManageTruck && !hasPermission(userSession.permissions, Permission.SetCommentTruck)}
                                        rows={4}
                                        onChange={(data) => {
                                            setEntity({ ...entity, comment: data.target.value });
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item wrapperCol={{ offset: 6, span: 10 }}>
                                    <Space size={'small'} style={{ float: 'right' }}>
                                        {
                                            <Button type='link' onClick={() => navigate(-1)}>
                                                Закрыть
                                            </Button>
                                        }

                                        {(canManageTruck || hasPermission(userSession.permissions, Permission.SetCommentTruck)) && (
                                            <Button type='primary' htmlType='submit' loading={loading}>
                                                Сохранить
                                            </Button>
                                        )}
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Col>

                        <Col offset={2} span={12}>
                            {id && entity.status !== TruckStatus.New && (
                                <>
                                    <Divider style={{ marginTop: 0 }} orientation='left'>
                                        Трекинг
                                    </Divider>
                                    <Trackpoints truck={entity} viewOnly={!canManageTruck || entity.status === TruckStatus.Finished} />
                                </>
                            )}
                        </Col>
                    </Row>
                </>
            )}

            {notificationContextHolder}
            {modalContextHolder}
        </>
    );
};

export default Truck;

import React from 'react';
import Icon from '@ant-design/icons';

const CreditCardIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18 }} {...props} />;
};
export default CreditCardIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} viewBox='-1 0 19 19' xmlns='http://www.w3.org/2000/svg'>
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
            <path d='M7.782 8.282H.818v-.874A1.112 1.112 0 0 1 1.926 6.3h6.629a4.767 4.767 0 0 0-.773 1.982zm7.09 4.859v.971a1.112 1.112 0 0 1-1.108 1.108H1.926a1.112 1.112 0 0 1-1.108-1.108V9.39h6.93a4.782 4.782 0 0 0 6.623 3.998 4.792 4.792 0 0 0 .501-.247zM7.92 12.113H3.33v1.109h4.59zm8.262-3.129a3.672 3.672 0 1 1-3.672-3.671 3.672 3.672 0 0 1 3.672 3.671zm-2.215.812a1.073 1.073 0 0 0-.265-.705 1.427 1.427 0 0 0-.397-.316 1.657 1.657 0 0 0-.41-.148 1.782 1.782 0 0 0-.375-.039 1.29 1.29 0 0 1-.216-.016.811.811 0 0 1-.221-.072.511.511 0 0 1-.164-.131.315.315 0 0 1-.074-.187.324.324 0 0 1 .182-.267.985.985 0 0 1 .5-.133 1.077 1.077 0 0 1 .175.03l.022.004a1.126 1.126 0 0 1 .214.075.583.583 0 0 1 .168.116.396.396 0 1 0 .56-.56 1.374 1.374 0 0 0-.403-.278 1.904 1.904 0 0 0-.352-.122v-.242a.396.396 0 1 0-.791 0v.232a1.669 1.669 0 0 0-.516.209 1.107 1.107 0 0 0-.55.936 1.094 1.094 0 0 0 .25.686 1.29 1.29 0 0 0 .425.34 1.583 1.583 0 0 0 .445.145 2.06 2.06 0 0 0 .346.027.987.987 0 0 1 .207.021.87.87 0 0 1 .21.075.645.645 0 0 1 .169.136.285.285 0 0 1 .07.184c0 .044 0 .135-.166.242a.983.983 0 0 1-.51.14 1.608 1.608 0 0 1-.224-.027 1.08 1.08 0 0 1-.208-.066.418.418 0 0 1-.154-.12.396.396 0 0 0-.598.519 1.192 1.192 0 0 0 .459.336 1.839 1.839 0 0 0 .345.107v.237a.396.396 0 0 0 .791 0v-.242a1.683 1.683 0 0 0 .53-.22 1.049 1.049 0 0 0 .526-.906z'></path>
        </g>
    </svg>
);

export enum WaypointType {
    Departure = 1,
    Onway = 2,
    Control = 3,
    Destination = 10,
}

export const enumLabel = (value?: WaypointType) => {
    switch (value) {
        case WaypointType.Departure:
            return 'Отправление';
        case WaypointType.Onway:
            return 'В пути';
        case WaypointType.Control:
            return 'Оформление';
        case WaypointType.Destination:
            return 'Назначение';
        default:
            return '';
    }
};

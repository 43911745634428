import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';

import { Input, Select, Table, Modal, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    ReloadOutlined,
    FilterFilled,
    PrinterOutlined,
    UnorderedListOutlined,
    PlusOutlined,
    UsergroupAddOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
} from '@ant-design/icons';

import { usePDF } from '@react-pdf/renderer';
import printJS from 'print-js';

import PrintProcessing from '@controls/print-processing';
import Toolbar from '@controls/toolbar/toolbar';
import BarcodePdf from '@print-forms/barcode-pdf';

import Filter from '@controls/filter/filter';

import { exception, noAccessError } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { serverFetch } from '@src/core/server';
import { toUrl, delayAction } from '@extensions/utils';

import { userLoaded } from '@store/actions';
import { useAppSelector } from '@store/hooks';

import { IConsignment } from '@entities/consignment';
import { IBoxGroup } from '@entities/box-group';
import { ICity } from '@entities/city';
import { IUserSession } from '@entities/user-session';
import { ICountry } from '@entities/country';
import { IConsignmentHeader } from '@entities/consignment-header';

import { AreaType } from '@enums/area-type';

import '../warehouse.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IBoxGroupState {
    id?: string;
    comment?: string;
}

const ExtraConsignments = () => {
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const { TextArea } = Input;

    const d = useDispatch();

    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [filter, setFilter] = useState<any>({});
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [countries, setCountries] = useState<Array<ICountry>>([]);

    const [headers, setHeaders] = useState<Array<IConsignmentHeader>>([]);
    const [currentHeader, setCurrentHeader] = useState<IConsignmentHeader>();

    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [openComment, setOpenComment] = useState<boolean>(false);
    const [boxGroupState, setBoxGroupState] = useState<IBoxGroupState>({});

    const [selectedCityRowKeys, setSelectedCityRowKeys] = useState<React.Key[]>([]);

    const [selectedConsignmentRowKeys, setSelectedConsignmentRowKeys] = useState<React.Key[]>([]);
    const [currentConsignment, setCurrentConsignment] = useState<IConsignment>();

    const [printData, setPrintData] = useState<Array<IConsignment>>();
    const [startPrint, setStartPrint] = useState<boolean>(false);
    const [loadingPrintData, setLoadingPrintData] = useState(false);

    const [pdfInstance, updatePdf] = usePDF({
        document: <BarcodePdf labels={printData} />,
    });

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setHeaders([]);
        setCities([]);

        const fetchData = async () => {
            setLoading(true);

            let queryParams: any = {};
            if (filter.markingCode) queryParams.markingCode = filter.markingCode;
            if (filter.cityIds) queryParams.cityIds = filter.cityIds;

            let promises = [
                await serverFetch('consignments/extra', { method: 'GET', queryParams })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения догрузов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('consignments/extra/cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                let entities = result[0][0];

                setHeaders(entities);
                setCities(result[0][1]);
                setCountries(result[0][2]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    const onSaveComment = () => {
        if (!boxGroupState) return;

        serverFetch(`warehouse/boxgroup/${boxGroupState.id}/comment`, { method: 'POST', bodyData: boxGroupState.comment })
            .then(() => {
                setBoxGroupState({});
                setRefreshRequired(true);
                setOpenComment(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения комментария', ex, () => d(userLoaded(undefined)));
            });
    };

    const getPrintData = () => {
        setLoadingPrintData(true);

        serverFetch(`consignments/extra/${toUrl(currentHeader?.cityKey)}/print`, { method: 'GET' })
            .then((data) => {
                setPrintData(data);
            })
            .catch((ex) => {
                setLoadingPrintData(false);
                exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    useEffect(() => {
        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (printData) {
            updatePdf();
            setLoadingPrintData(false);
            setStartPrint(true);
        }
    }, [printData]);

    useEffect(() => {
        if (startPrint && !pdfInstance.loading && pdfInstance.blob) {
            setStartPrint(false);
            setPrintData(undefined);

            const blobURL = URL.createObjectURL(pdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrint, pdfInstance]);

    const onDelete = () => {
        setLoading(true);

        serverFetch(`consignments/${currentConsignment?.id}`, { method: 'DELETE' })
            .then(() => {
                setLoading(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка удаления партии', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить партию',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                noAccessError(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            navigate(`/warehouse/extraconsignments/new`);
                        },
                    },
                    {
                        label: 'Удалить партию',
                        key: 'delete',
                        disabled: !currentConsignment,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteWarehouseConsignment)) {
                                noAccessError(api, [Permission.DeleteWarehouseConsignment]);
                                return;
                            }

                            Modal.confirm({
                                title: `Удалить партию "${currentConsignment?.markingCode}"?`,
                                okType: 'primary',
                                icon: <QuestionCircleOutlined />,
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                    {
                        label: 'Детали партии',
                        key: 'show',
                        disabled: !currentConsignment,
                        icon: <UnorderedListOutlined />,
                        onClick: () => {
                            navigate(`/warehouse/extraconsignments/consignments/${currentConsignment?.id}`);
                        },
                    },
                    {
                        label: 'Печать',
                        key: 'printBarcode',
                        disabled: loading || !currentHeader || startPrint,
                        icon: <PrinterOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                noAccessError(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            getPrintData();
                        },
                    },
                    {
                        label: 'Назначить исполнителя',
                        key: 'assignJob',
                        disabled: cities.length <= 0,
                        icon: <UsergroupAddOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                noAccessError(api, [Permission.ManageWarehouse]);

                                return;
                            }

                            navigate(`/warehouse/performers/${AreaType.Acceptance}`);
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                items={[
                    <Select
                        key='city'
                        placeholder='Город доставки'
                        value={filter.cityIds}
                        allowClear={true}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        style={{ width: 200 }}
                        showSearch
                        onChange={(value) => setFilter({ ...filter, cityIds: value })}
                        optionFilterProp='children'
                        options={cities.map((c) => {
                            return { value: c.id, label: c.name };
                        })}
                    ></Select>,
                    <Input
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter.markingCode}
                        onChange={(data) => {
                            setFilter({ ...filter, markingCode: data.target.value });
                        }}
                    />,
                ]}
                onClear={() => setFilter({ cityIds: [] })}
            />
        );
    };

    const onSelectConsignmentChange = (selectedRowKeys: React.Key[]) => {
        setSelectedConsignmentRowKeys(selectedRowKeys);

        setCurrentHeader(undefined);
        setSelectedCityRowKeys([]);

        if (selectedRowKeys.length == 1) {
            headers.map((h) => {
                h.consignments?.map((c) => {
                    if (c.id === selectedRowKeys[0]) {
                        setCurrentConsignment(c);
                    }
                });
            });
        } else {
            setCurrentConsignment(undefined);
        }
    };

    const expandedConsignments = (record: IConsignmentHeader) => {
        let consignments = record.consignments?.map((p: IConsignment) => {
            let boxGroups: any = [];
            let totalQty = 0;
            let totalScannedQty = 0;

            p.boxGroups?.map((b: IBoxGroup) => {
                if (b.countryId) {
                    boxGroups[b.countryId] = { qty: b.actualBoxQty, scannedQty: b.scannedBoxQty };
                    totalQty += b.actualBoxQty || 0;
                    totalScannedQty += b.scannedBoxQty || 0;
                }
            });

            let header: IConsignment = {
                ...p,
                ...boxGroups,
                totalQty: totalQty,
                totalScannedQty: totalScannedQty,
            };
            return header;
        });

        const columns: ColumnsType<IConsignment> = [
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                align: 'center',
                width: 80,
            },

            {
                title: 'Маркировка',
                dataIndex: 'markingCode',
                width: 150,
            },
        ];

        countries.map((c) => {
            columns.push({
                title: c.name,
                width: 120,
                align: 'center',
                render: (_: any, record: any) => {
                    let groupBox = c.id && record[c.id];
                    return (
                        groupBox && (
                            <>
                                <span style={{ fontWeight: 600, color: groupBox.scannedQty === groupBox.qty ? '#228B22' : 'red' }}>
                                    {groupBox.scannedQty}
                                </span>
                                <span> / </span>
                                <span>{groupBox.qty}</span>
                            </>
                        )
                    );
                },
            });
        });

        columns.push({
            align: 'center',
            width: 120,
            dataIndex: 'totalQty',
            onCell: (record) => ({
                style: {
                    background: '#fafafa',
                    fontWeight: 600,
                },
            }),
            render: (_, record: IConsignment) => {
                return (
                    <>
                        <span style={{ fontWeight: 600, color: record.totalScannedQty === record.totalQty ? '#228B22' : 'red' }}>
                            {record.totalScannedQty}
                        </span>
                        <span> / </span>
                        <span>{record.totalQty}</span>
                    </>
                );
            },
        });

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={consignments}
                pagination={false}
                rowSelection={{
                    type: 'radio',
                    selectedRowKeys: selectedConsignmentRowKeys,
                    onChange: onSelectConsignmentChange,
                }}
            />
        );
    };

    const onSelectCityChange = (selectedRowKeys: React.Key[]) => {
        setSelectedCityRowKeys(selectedRowKeys);

        setCurrentConsignment(undefined);
        setSelectedConsignmentRowKeys([]);

        if (selectedRowKeys.length == 1) {
            let entity = headers.find((h) => h.cityKey === selectedRowKeys[0]);
            setCurrentHeader(entity);
        } else {
            setCurrentHeader(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<IConsignmentHeader> = [
            {
                title: 'Город доставки',
                key: 'cityName',
                width: 200,
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span>{record.cityName}</span>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Исполнитель',
                key: 'performerName',
                dataIndex: 'performerName',
                width: 200,
            },
            { title: ' ' },
            {
                title: 'Кол-во / шт',
                dataIndex: 'totalQty',
                width: 150,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
                render: (_, record: IConsignmentHeader) => {
                    return (
                        <>
                            <span style={{ color: record.totalScannedQty === record.totalQty ? '#228B22' : 'red' }}>
                                {record.totalScannedQty}
                            </span>
                            <span> / </span>
                            <span>{record.totalQty}</span>
                        </>
                    );
                },
            },
        ];

        return (
            <Table
                rowKey='cityKey'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                dataSource={headers}
                expandable={{
                    expandedRowRender: expandedConsignments,
                    defaultExpandedRowKeys: headers && headers.map((h) => h.cityKey || ''),
                }}
                rowSelection={{
                    type: 'radio',
                    selectedRowKeys: selectedCityRowKeys,
                    onChange: onSelectCityChange,
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 180px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            <Modal title='Комментарий' open={openComment} onOk={() => onSaveComment()} onCancel={() => setOpenComment(false)}>
                <TextArea
                    rows={4}
                    value={boxGroupState.comment}
                    onChange={(e) => setBoxGroupState({ ...boxGroupState, comment: e.target.value })}
                />
            </Modal>

            {(loadingPrintData || startPrint) && <PrintProcessing />}

            {contextHolder}
        </>
    );
};

export default ExtraConsignments;

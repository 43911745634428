import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from 'antd';

import { Space, Button, Col, Row, Select, Input, InputNumber, Form } from 'antd';

import FormHeader from '@controls/form-header/form-header';

import { useAppSelector, useAppDispatch } from '@store/hooks';
import { userLoaded } from '@store/actions';

import { exception } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { serverFetch } from '@src/core/server';
import { IUserSession } from '@entities/user-session';
import { IBoxGroup } from '@entities/box-group';
import { ICountry } from '@entities/country';
import { IMarking } from '@entities/marking';

const ExtraConsignment = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    const navigate = useNavigate();

    const { TextArea } = Input;

    const { consignmentId } = useParams();

    const [form] = Form.useForm();

    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [markings, setMarkings] = useState<Array<IMarking>>([]);
    const [entity, setEntity] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageWarehouse));

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('markings', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маркировок', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (consignmentId) {
                promises.push(
                    await serverFetch(`consignments/${consignmentId}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения партии', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0]);
                setMarkings(result[0][1]);

                let entity = result[0][2] || { boxGroups: [] };

                var boxGroups: any = [];
                entity.boxGroups.map((b: IBoxGroup) => {
                    if (b.countryId) boxGroups[b.countryId] = { qty: b.qty, volumeWeight: b.volumeWeight };
                });

                entity = {
                    ...entity,
                    ...boxGroups,
                };

                if (consignmentId) {
                    onMarkingChanged(entity.markingId);
                }

                setEntity(entity);
                setLoading(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, []);

    const onMarkingChanged = async (value: string) => {
        form.setFieldsValue({
            markingId: value,
        });
    };

    const onFinish = (data: any) => {
        setLoading(true);

        let result = { ...entity, ...data };

        serverFetch(`consignments/extra`, { method: consignmentId ? 'PUT' : 'POST', bodyData: result })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения партии', ex, () => d(userLoaded(undefined)));
            });
    };

    const getboxGroups = () => {
        return countries.map((c: ICountry) => {
            return (
                <Form.Item key={c.id} label={`${c.name}`} style={{ marginBottom: 0 }}>
                    <Form.Item
                        initialValue={c.id && entity[c.id] && entity[c.id].qty}
                        name={`${c.id}qty`}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        <InputNumber
                            disabled={isViewOnly}
                            addonAfter='шт'
                            onChange={(value) => {
                                let boxGroups = [...entity.boxGroups];

                                let detail = boxGroups.find((d) => d.countryId === c.id);
                                if (detail) {
                                    if (value) {
                                        detail.qty = value;
                                    } else {
                                        boxGroups = boxGroups.filter((d) => d.countryId !== detail.countryId);
                                    }
                                } else {
                                    boxGroups.push({ countryId: c.id, qty: value });
                                }

                                setEntity({ ...entity, boxGroups: boxGroups });
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={c.id && entity[c.id] && entity[c.id].volumeWeight}
                        name={`${c.id}volumeWeight`}
                        style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
                    >
                        <InputNumber
                            decimalSeparator=','
                            disabled={isViewOnly}
                            stringMode
                            step='0.01'
                            addonAfter={
                                <>
                                    м<sup>3</sup>
                                </>
                            }
                            onChange={(value) => {
                                let boxGroups = [...entity.boxGroups];

                                let detail = boxGroups.find((d) => d.countryId === c.id);
                                if (detail) {
                                    if (value) {
                                        detail.volumeWeight = value;
                                    } else {
                                        boxGroups = boxGroups.filter((d) => d.countryId !== detail.countryId);
                                    }
                                } else {
                                    boxGroups.push({ countryId: c.id, volumeWeight: value });
                                }

                                setEntity({ ...entity, boxGroups: boxGroups });
                            }}
                        />
                    </Form.Item>
                </Form.Item>
            );
        });
    };

    return (
        <>
            <Row>
                <FormHeader title={`${consignmentId ? (isViewOnly ? 'Информация о партии' : 'Изменить') : 'Добавить партию'}`} />
            </Row>
            {!loading && (
                <Row>
                    <Col span={24}>
                        <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} form={form} onFinish={onFinish}>
                            <Form.Item
                                initialValue={entity.markingId}
                                required
                                label='Маркировка'
                                name='markingId'
                                rules={[{ required: true, message: 'Выберите маркировку' }]}
                            >
                                <Select
                                    disabled={isViewOnly}
                                    autoFocus
                                    showSearch
                                    onChange={(value) => onMarkingChanged(value)}
                                    optionFilterProp='children'
                                    filterOption={(input, option) =>
                                        (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                    }
                                    filterSort={(a, b) =>
                                        (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                    }
                                    options={markings.map((m) => {
                                        return { value: m.id, label: `${m.code} / ${m.cityName}` };
                                    })}
                                ></Select>
                            </Form.Item>
                            <Form.Item initialValue={entity.comment} label='Комментарий' name='comment'>
                                <TextArea
                                    disabled={isViewOnly}
                                    rows={3}
                                    onChange={(data) => {
                                        setEntity({ ...entity, comment: data.target.value });
                                    }}
                                />
                            </Form.Item>

                            {getboxGroups()}

                            <Form.Item label='' wrapperCol={{ span: 11 }}>
                                <Space size={'small'} style={{ float: 'right' }}>
                                    <Button type='link' onClick={() => navigate(-1)}>
                                        Закрыть
                                    </Button>
                                    {!isViewOnly && (
                                        <Button type='primary' htmlType='submit' loading={loading}>
                                            Сохранить
                                        </Button>
                                    )}
                                </Space>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            )}

            {contextHolder}
        </>
    );
};

export default ExtraConsignment;

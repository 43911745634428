import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { notification } from 'antd';

import { Table, Row, Col, Divider } from 'antd';
import { CheckOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons';

import FormHeader from '@controls/form-header/form-header';
import Toolbar from '@controls/toolbar/toolbar';
import { exception, noAccessError } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import './performers.css';
import { IUser } from '@entities/user';
import { IJob } from '@entities/job';
import { IUserSession } from '@entities/user-session';
import { ColumnsType } from 'antd/es/table';
import { AreaType } from '@enums/area-type';
import { JobType } from '@enums/job-type';
import { JobStatus, enumLabel as jobStatusLabel } from '@enums/job-status';
import { Permission, hasPermission } from '@enums/permission';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Performers = () => {
    const { truckId, areaType } = useParams();
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [users, setUsers] = useState<Array<IUser>>([]);
    const [currentUser, setCurrentUser] = useState<IUser>();

    const [jobs, setJobs] = useState<Array<IJob>>([]);

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [jobsRefreshRequired, setJobsRefreshRequired] = useState<boolean>(false);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
    const [loadingJobs, setLoadingJobs] = useState<boolean>(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);
        init();
    }, [refreshRequired]);

    useEffect(() => {
        if (!jobsRefreshRequired) return;

        setJobsRefreshRequired(false);
        loadJobs();
    }, [jobsRefreshRequired]);

    useEffect(() => {
        if (currentUser) {
            setJobsRefreshRequired(true);
        } else {
            setJobs([]);
        }
    }, [currentUser]);

    const init = () => {
        setUsers([]);
        setCurrentUser(undefined);
        setSelectedIds([]);

        setLoadingUsers(true);

        serverFetch('jobs/performers', { method: 'GET' })
            .then((data) => {
                setUsers(data);
                setLoadingUsers(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения исполнителей', ex, () => d(userLoaded(undefined)));
                setLoadingUsers(false);
            });
    };

    const loadJobs = () => {
        setLoadingJobs(true);

        let queryParams = {
            userId: currentUser?.id,
            statuses: [JobStatus.Created, JobStatus.Started],
        };

        serverFetch(`jobs`, { method: 'GET', queryParams })
            .then((data) => {
                setJobs(data);
                setLoadingJobs(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения задач', ex, () => d(userLoaded(undefined)));
                setLoadingJobs(false);
            });
    };

    const onAssign = () => {
        if (!currentUser || !areaType) return;

        let job: IJob = {
            id: undefined,
            userId: currentUser.id,
            truckId: truckId as string,
            areaType: +areaType,
            status: JobStatus.Created,
            type: JobType.Wms,
        };

        serverFetch(`jobs`, { method: 'POST', bodyData: job })
            .then(() => {
                setJobsRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка назначения задачи', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loadingUsers,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Назначить',
                        key: 'assign',
                        type: 'primary',
                        disabled: loadingUsers || !currentUser,
                        icon: <CheckOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWarehouse)) {
                                noAccessError(api, [Permission.ManageWarehouse]);
                                return;
                            }

                            onAssign();
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = users.find((o) => o.id == selectedRowKeys[0]);
            setCurrentUser(entity);
        } else {
            setCurrentUser(undefined);
        }
    };

    const renderUsersTable = () => {
        const columns: ColumnsType<IUser> = [
            {
                title: 'ФИО',
                dataIndex: 'fullName',
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                }}
                columns={columns}
                dataSource={users}
                loading={{
                    spinning: loadingUsers,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    const renderJobsTable = () => {
        const columns: ColumnsType<IJob> = [
            {
                title: 'Машина',
                width: 150,
                render: (_, record) => {
                    return record.truckNumber || '- ДОГРУЗ -';
                },
            },
            {
                title: 'Зона',
                width: 80,
                align: 'center',
                render: (_, record) => {
                    return <div className={`area ${record.areaType === AreaType.Acceptance ? 'acceptance' : 'shipment'}`}></div>;
                },
            },
            {
                title: 'Статус',
                width: 120,
                render: (_, record) => {
                    return <>{jobStatusLabel(record.status)} </>;
                },
            },
            {
                title: 'Дата изменения',
                dataIndex: 'lastChangedOn',
                width: 130,
                render: (_, record) => {
                    return dayjs.utc(record.lastChangedOn).local().format('DD.MM.YYYY HH:mm');
                },
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={jobs}
                loading={{
                    spinning: loadingJobs,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 150px)` }}
            />
        );
    };

    return (
        <>
            <Row>
                <FormHeader title='Назначить исполнителя' />
            </Row>
            <Row>
                <Col span={6}>
                    {renderToolbar()}
                    {renderUsersTable()}
                </Col>
                <Col offset={1} span={17}>
                    <Divider orientation='left' style={{ marginTop: 0 }}>
                        Задачи
                    </Divider>
                    {renderJobsTable()}
                </Col>
            </Row>

            {contextHolder}
        </>
    );
};

export default Performers;

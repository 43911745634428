import React, { useState, useEffect } from 'react';

import { v4 as uuid } from 'uuid';

import { Modal, Tooltip } from 'antd';
import { DollarOutlined } from '@ant-design/icons';

import { groupBy, toFinanceString } from '@extensions/utils';

import { IBill } from '@src/core/entities/bill';
import { UnitType } from '@src/core/enums/unit-type';

import { PenIcon } from '@src/core/icons';

import './bill.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IBillModal {
    bills: Array<IBill>;
    onClose: () => void;
}

const Bill = (props: IBillModal) => {
    const { bills, onClose } = props;

    const [totalPrice, setTotalPrice] = useState<number | undefined>();
    const [countryGroups, setGroups] = useState<Record<any, IBill[]>>();

    useEffect(() => {
        setTotalPrice(undefined);

        let result: number = 0;
        bills.map((b) => {
            result += b.totalPriceFinal || b.totalPrice || 0;
        });

        if (result > 0) setTotalPrice(result);

        setGroups(groupBy(bills, (b) => b.countryName || ''));
    }, [bills]);

    const renderImportTable = () => {
        return (
            countryGroups &&
            Object.entries(countryGroups).map(([countryName, countryValues]) => {
                let markingGroups = groupBy(countryValues, (v) => v.markingCode || '');

                return Object.entries(markingGroups).map(([markingCode, markingValues]) => {
                    let cityName = markingValues[0].cityName;
                    let loadingOn = markingValues[0].loadingOn;
                    let isChanged = markingValues[0].isChanged;
                    let isPaid = markingValues[0].isPaid;
                    let changedOn = markingValues[0].changedOn;

                    let untiTypeGroups = groupBy(markingValues, (v) => v.unitType || 0);

                    return Object.entries(untiTypeGroups).map(([unitType, items]) => {
                        return (
                            <div className='bill' style={{ marginBottom: 20 }} key={uuid()}>
                                <div className='bill-header'>
                                    <div className='bill-header-item-start'>Страна: {countryName}</div>
                                    <div className='bill-header-item-end'>
                                        Отгрузка: {loadingOn && dayjs.utc(loadingOn).local().format('DD.MM.YYYY')}
                                        {isChanged && (
                                            <Tooltip title={`Счет изменен ${dayjs.utc(changedOn).local().format('DD.MM.YYYY')}`}>
                                                <PenIcon style={{ marginLeft: 5 }} />
                                            </Tooltip>
                                        )}
                                        {isPaid && (
                                            <Tooltip title='Оплачен' color='green'>
                                                <DollarOutlined style={{ fontSize: 18, color: 'green', marginLeft: 5 }} />
                                            </Tooltip>
                                        )}
                                    </div>
                                </div>
                                <div className='bill-sub-header'>
                                    <span className='bill-header-item-start'>Маркировка: {markingCode}</span>
                                    <span className='bill-header-item-end'>
                                        Доставка: {countryName} - {cityName}
                                    </span>
                                </div>
                                {+unitType == UnitType.Weight
                                    ? renderWeightBillTable(items)
                                    : +unitType == UnitType.Cart
                                    ? renderCartBillTable(items)
                                    : renderPalletBillTable(items)}
                            </div>
                        );
                    });
                });
            })
        );
    };

    const renderCartBillTable = (items: Array<IBill>) => {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Тариф за телегу</th>
                        <th>Количество, шт</th>
                        <th>Итого</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((b) => {
                        return (
                            <>
                                <tr key={uuid()}>
                                    <td>
                                        {toFinanceString(b.pricePerTruck)} <b>$</b>
                                    </td>
                                    <td>{b.adjustmentQty}</td>
                                    <td>{toFinanceString(b.totalPrice, 2)} $</td>
                                </tr>

                                <tr key={uuid()}>
                                    <td colSpan={2} style={{ fontWeight: 700 }}>
                                        ИТОГО СО СКИДКОЙ
                                    </td>
                                    <>
                                        <td style={{ fontWeight: 700 }}>{toFinanceString(b.totalPriceFinal || b.totalPrice, 2)} $</td>
                                    </>
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const renderPalletBillTable = (items: Array<IBill>) => {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Тариф за паллет</th>
                        <th>Наименование | На паллете, шт</th>
                        <th>Тариф за коробку</th>
                        <th>Коробок, шт</th>
                        <th>Итого</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((b) => {
                        return (
                            <>
                                {b.packages && b.packages.length > 0 ? (
                                    b.packages.map((p) => {
                                        let index = b.packages?.indexOf(p);
                                        return (
                                            <tr key={uuid()}>
                                                <td>
                                                    {b.pricePerTruck} <b>$</b>
                                                </td>
                                                <td>
                                                    {p.templateName} | {p.qtyPerPallet}
                                                </td>
                                                <td>
                                                    {toFinanceString((b.pricePerTruck || 0) / p.qtyPerPallet, 2)} <b>$</b>
                                                </td>
                                                <td>{p.boxQty}</td>
                                                {index === 0 && <td rowSpan={b.packages?.length}>{toFinanceString(b.totalPrice, 2)} $</td>}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr key={uuid()}>
                                        <td>
                                            {b.pricePerTruck} <b>$</b>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>{b.adjustmentQty}</td>
                                        <td>{toFinanceString(b.totalPrice, 2)} $</td>
                                    </tr>
                                )}
                                <tr key={uuid()}>
                                    <td colSpan={4} style={{ fontWeight: 700 }}>
                                        ИТОГО СО СКИДКОЙ
                                    </td>
                                    <td style={{ fontWeight: 700 }}>{toFinanceString(b.totalPriceFinal || b.totalPrice, 2)} $</td>
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    const renderWeightBillTable = (items: Array<IBill>) => {
        return (
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th>AWB</th>
                        <th>Количество</th>
                        <th>Объем</th>
                        <th>Перелеты</th>
                        <th>Доставка</th>
                        <th>Итого</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((b) => {
                        return (
                            <>
                                <tr key={uuid()}>
                                    <td>{b.awbNumber}</td>
                                    <td>{b.qty}</td>
                                    <td>{b.volumeWeight}</td>
                                    <td>
                                        {b.pricePerFlight || '-'} <b>$</b>
                                    </td>
                                    <td>
                                        {toFinanceString(b.pricePerTruck)} <b>$</b>
                                    </td>
                                    <td>
                                        {toFinanceString(b.totalPrice)} <b>$</b>
                                    </td>
                                </tr>
                                <tr key={uuid()}>
                                    <td colSpan={5} style={{ fontWeight: 700 }}>
                                        ИТОГО СО СКИДКОЙ
                                    </td>
                                    <td style={{ fontWeight: 700 }}>{toFinanceString(b.totalPriceFinal || b.totalPrice, 2)} $</td>
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    return (
        <Modal
            width={950}
            title={`Счет на сумму ${totalPrice ? toFinanceString(totalPrice, 2) : toFinanceString(0, 2)} $`}
            open={true}
            onCancel={() => onClose()}
            cancelText='Закрыть'
            okButtonProps={{ style: { display: 'none' } }}
        >
            {renderImportTable()}
        </Modal>
    );
};

export default Bill;

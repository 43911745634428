import React from 'react';
import Icon from '@ant-design/icons';

const CalculatorIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18, marginTop:2 }} {...props} />;
};
export default CalculatorIcon;

const svg = ({ ...props }) => (
    <svg {...props} fill='currentColor' version='1.1' id='_x32_' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <g id='SVGRepo_bgCarrier'></g>
        <g id='SVGRepo_tracerCarrier'></g>
        <g id='SVGRepo_iconCarrier'>
            <style type='text/css'> </style>
            <g>
                <path d='M394.656,0H117.359C92.313,0,72,20.313,72,45.344v421.313C72,491.688,92.313,512,117.359,512h277.297 C419.703,512,440,491.688,440,466.656V45.344C440,20.313,419.703,0,394.656,0z M184,440h-48v-48h48V440z M184,360h-48v-48h48V360z M184,280h-48v-48h48V280z M280,440h-48v-48h48V440z M280,360h-48v-48h48V360z M280,280h-48v-48h48V280z M376,440h-48V312h48V440z M376,280h-48v-48h48V280z M376,160H136V80h240V160z'></path>
            </g>
        </g>
    </svg>
);

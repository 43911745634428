import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, InputNumber, Input, Form, Space, Select, Switch } from 'antd';
import { notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';
import { userLoaded } from '@store/actions';
import { useAppDispatch } from '@store/hooks';

import { exception } from '@extensions/notification';
import { serverFetch } from '@src/core/server';

import { IWaypointHeader } from '@entities/waypoint-header';

const WaypointHeader = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const d = useAppDispatch();

    const [api, contextHolder] = notification.useNotification();

    const [entity, setEntity] = useState<IWaypointHeader>({
        id: undefined,
        name: undefined,
    });

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!id) return;

        setLoading(true);

        serverFetch(`waypointhearers/${id}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения названия маршрута', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, []);

    const onFinish = () => {
        setLoading(true);

        serverFetch(`waypointheaders`, { method: id ? 'PUT' : 'POST', bodyData: entity })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения названия маршрута', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader title={id ? 'Изменить название маршрута' : 'Добавить название маршрута'} />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 5 }} onFinish={onFinish}>
                    <Form.Item initialValue={entity.name} label='Название' name='name'>
                        <Input
                            autoFocus
                            onChange={(data) => {
                                setEntity({ ...entity, name: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item label='' wrapperCol={{ span: 8 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button type='link' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            <Button type='primary' htmlType='submit' loading={loading}>
                                Сохранить
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default WaypointHeader;

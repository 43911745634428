import React from 'react';
import Icon from '@ant-design/icons';

const MiniLogoIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18 }} {...props} />;
};
export default MiniLogoIcon;

const svg = ({ ...props }) => (
    <svg
        {...props}
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        width='100%'
        viewBox='0 0 1654 1653'
        enableBackground='new 0 0 1654 1653'
    >
        <path
            fill='#1E513C'
            opacity='1.000000'
            stroke='none'
            d='
M871.000000,1654.000000 
	C580.666748,1654.000000 290.833496,1654.000000 1.000194,1654.000000 
	C1.000130,1103.000244 1.000130,552.000488 1.000065,1.000564 
	C552.332947,1.000376 1103.665894,1.000376 1654.999023,1.000188 
	C1654.999390,551.999451 1654.999390,1102.998901 1654.999756,1653.999146 
	C1393.833374,1654.000000 1132.666626,1654.000000 871.000000,1654.000000 
M580.494812,932.335632 
	C578.861450,934.000549 577.241577,935.678955 575.592773,937.328491 
	C463.324280,1049.650269 351.052979,1161.969238 238.787476,1274.293945 
	C237.309723,1275.772339 235.901764,1277.320679 234.402527,1278.898193 
	C282.784607,1326.973511 330.721527,1374.606445 379.143188,1422.721069 
	C380.132080,1421.563721 381.332001,1419.950073 382.738525,1418.543091 
	C495.128662,1306.106567 607.534119,1193.685303 719.947327,1081.271973 
	C721.825439,1079.393921 723.813110,1077.625244 726.238403,1075.986816 
	C726.410706,1076.008911 726.583069,1076.030884 726.867249,1076.977661 
	C726.867249,1240.368164 726.867249,1403.758545 726.867249,1567.019531 
	C795.093872,1567.019531 862.526062,1567.019531 930.529419,1567.019531 
	C930.529419,1403.061523 930.529419,1239.385620 930.529419,1074.725464 
	C932.406555,1076.424805 933.531433,1077.357788 934.560425,1078.386719 
	C1048.161743,1191.976562 1161.770142,1305.559326 1275.277344,1419.243042 
	C1278.503296,1422.473999 1280.234009,1422.192383 1283.220703,1419.136475 
	C1302.894165,1399.007202 1322.654419,1378.961060 1342.545288,1359.046509 
	C1368.259277,1333.302002 1394.123535,1307.707764 1419.913452,1282.039185 
	C1420.955200,1281.002441 1421.900269,1279.868530 1423.324097,1278.302979 
	C1421.841431,1277.076538 1420.464233,1276.114258 1419.294434,1274.944702 
	C1306.162231,1161.828857 1193.045044,1048.697998 1079.936035,935.558899 
	C1078.536499,934.158875 1077.258423,932.637512 1075.994629,930.693665 
	C1075.994629,930.693665 1076.015503,930.209717 1076.938965,930.052490 
	C1240.350098,930.052490 1403.761353,930.052490 1566.997803,930.052490 
	C1566.997803,861.860718 1566.997803,794.300720 1566.997803,726.185913 
	C1403.096924,726.185913 1239.588989,726.185913 1076.081055,726.185913 
	C1075.841431,725.760620 1075.601807,725.335327 1075.362183,724.910034 
	C1191.174438,609.153259 1306.986572,493.396454 1422.617310,377.821106 
	C1374.494141,329.894287 1326.599609,282.195221 1277.719238,233.514297 
	C1162.205322,348.955597 1046.364136,464.723877 930.235474,580.779480 
	C930.235474,417.047424 930.235474,253.346100 930.235474,89.451103 
	C862.272156,89.451103 794.855042,89.451103 726.883789,89.451103 
	C726.883789,91.782509 726.883789,93.895157 726.883789,96.007797 
	C726.883789,255.489716 726.884277,414.971619 726.878479,574.453552 
	C726.878418,576.617676 726.798035,578.781799 726.270813,580.977905 
	C726.270813,580.977905 725.802429,581.105408 725.210266,580.815186 
	C723.204773,578.835449 721.187927,576.867065 719.195435,574.874329 
	C607.020508,462.684692 494.843018,350.497711 382.725586,238.250732 
	C381.245819,236.769241 380.530945,234.523819 379.459076,232.634949 
	C379.287170,233.606415 379.115265,234.577881 378.943390,235.549347 
	C330.962616,283.125732 282.981842,330.702087 234.748581,378.528839 
	C350.214355,494.033081 465.761322,609.618591 581.308289,725.204041 
	C581.039612,725.667725 580.770874,726.131409 580.502136,726.595093 
	C417.089935,726.595093 253.677734,726.595093 90.127823,726.595093 
	C90.127823,794.700684 90.127823,862.216187 90.127823,930.068176 
	C92.907852,930.068176 95.222008,930.068237 97.536171,930.068237 
	C256.019226,930.068237 414.502289,930.067566 572.985352,930.074280 
	C575.316162,930.074341 577.646973,930.165100 580.356873,931.022522 
	C580.520203,931.271484 580.683594,931.520386 580.494812,932.335632 
z'
        />
        <path
            fill='#FFFFFF'
            opacity='1.000000'
            stroke='none'
            d='
M1075.923584,931.172791 
	C1077.258423,932.637512 1078.536499,934.158875 1079.936035,935.558899 
	C1193.045044,1048.697998 1306.162231,1161.828857 1419.294434,1274.944702 
	C1420.464233,1276.114258 1421.841431,1277.076538 1423.324097,1278.302979 
	C1421.900269,1279.868530 1420.955200,1281.002441 1419.913452,1282.039185 
	C1394.123535,1307.707764 1368.259277,1333.302002 1342.545288,1359.046509 
	C1322.654419,1378.961060 1302.894165,1399.007202 1283.220703,1419.136475 
	C1280.234009,1422.192383 1278.503296,1422.473999 1275.277344,1419.243042 
	C1161.770142,1305.559326 1048.161743,1191.976562 934.560425,1078.386719 
	C933.531433,1077.357788 932.406555,1076.424805 930.529419,1074.725464 
	C930.529419,1239.385620 930.529419,1403.061523 930.529419,1567.019531 
	C862.526062,1567.019531 795.093872,1567.019531 726.867249,1567.019531 
	C726.867249,1403.758545 726.867249,1240.368164 726.902588,1076.192871 
	C726.903809,1075.187378 726.869751,1074.966553 727.007690,1074.465332 
	C809.051270,992.400330 890.922852,910.615662 973.797058,827.829407 
	C971.765442,826.409180 970.058716,825.552612 968.776367,824.271362 
	C898.785889,754.339783 828.825806,684.377747 758.877747,614.403687 
	C748.168640,603.690552 737.531677,592.905212 726.902466,581.845459 
	C726.880798,581.340454 726.817993,581.143188 726.755127,580.945923 
	C726.798035,578.781799 726.878418,576.617676 726.878479,574.453552 
	C726.884277,414.971619 726.883789,255.489716 726.883789,96.007797 
	C726.883789,93.895157 726.883789,91.782509 726.883789,89.451103 
	C794.855042,89.451103 862.272156,89.451103 930.235474,89.451103 
	C930.235474,253.346100 930.235474,417.047424 930.235474,580.779480 
	C1046.364136,464.723877 1162.205322,348.955597 1277.719238,233.514297 
	C1326.599609,282.195221 1374.494141,329.894287 1422.617310,377.821106 
	C1306.986572,493.396454 1191.174438,609.153259 1075.362183,724.910034 
	C1075.601807,725.335327 1075.841431,725.760620 1076.081055,726.185913 
	C1239.588989,726.185913 1403.096924,726.185913 1566.997803,726.185913 
	C1566.997803,794.300720 1566.997803,861.860718 1566.997803,930.052490 
	C1403.761353,930.052490 1240.350098,930.052490 1076.202393,930.091797 
	C1075.618408,930.478333 1075.770996,930.825562 1075.923584,931.172791 
z'
        />
        <path
            fill='#E01039'
            opacity='1.000000'
            stroke='none'
            d='
M726.861389,582.153259 
	C737.531677,592.905212 748.168640,603.690552 758.877747,614.403687 
	C828.825806,684.377747 898.785889,754.339783 968.776367,824.271362 
	C970.058716,825.552612 971.765442,826.409180 973.797058,827.829407 
	C890.922852,910.615662 809.051270,992.400330 726.771973,1074.581299 
	C726.159424,1075.253662 725.954529,1075.529663 725.749634,1075.805542 
	C723.813110,1077.625244 721.825439,1079.393921 719.947327,1081.271973 
	C607.534119,1193.685303 495.128662,1306.106567 382.738525,1418.543091 
	C381.332001,1419.950073 380.132080,1421.563721 379.143188,1422.721069 
	C330.721527,1374.606445 282.784607,1326.973511 234.402527,1278.898193 
	C235.901764,1277.320679 237.309723,1275.772339 238.787476,1274.293945 
	C351.052979,1161.969238 463.324280,1049.650269 575.592773,937.328491 
	C577.241577,935.678955 578.861450,934.000549 580.907410,931.891479 
	C580.872559,931.036072 580.425171,930.624817 579.977783,930.213562 
	C577.646973,930.165100 575.316162,930.074341 572.985352,930.074280 
	C414.502289,930.067566 256.019226,930.068237 97.536171,930.068237 
	C95.222008,930.068237 92.907852,930.068176 90.127823,930.068176 
	C90.127823,862.216187 90.127823,794.700684 90.127823,726.595093 
	C253.677734,726.595093 417.089935,726.595093 580.502136,726.595093 
	C580.770874,726.131409 581.039612,725.667725 581.308289,725.204041 
	C465.761322,609.618591 350.214355,494.033081 234.748581,378.528839 
	C282.981842,330.702087 330.962616,283.125732 378.943390,235.549347 
	C379.115265,234.577881 379.287170,233.606415 379.459076,232.634949 
	C380.530945,234.523819 381.245819,236.769241 382.725586,238.250732 
	C494.843018,350.497711 607.020508,462.684692 719.195435,574.874329 
	C721.187927,576.867065 723.204773,578.835449 725.615967,581.194153 
	C726.301636,581.766479 726.581482,581.959900 726.861389,582.153259 
z'
        />
        <path
            fill='#36493C'
            opacity='1.000000'
            stroke='none'
            d='
M580.167358,930.618042 
	C580.425171,930.624817 580.872559,931.036072 581.083496,931.608337 
	C580.683594,931.520386 580.520203,931.271484 580.167358,930.618042 
z'
        />
        <path
            fill='#487261'
            opacity='1.000000'
            stroke='none'
            d='
M726.902466,581.845459 
	C726.581482,581.959900 726.301636,581.766479 725.912109,581.339233 
	C725.802429,581.105408 726.270813,580.977905 726.512939,580.961914 
	C726.817993,581.143188 726.880798,581.340454 726.902466,581.845459 
z'
        />
        <path
            fill='#2C5C48'
            opacity='1.000000'
            stroke='none'
            d='
M1075.959106,930.933228 
	C1075.770996,930.825562 1075.618408,930.478333 1075.740723,930.170410 
	C1076.015503,930.209717 1075.994629,930.693665 1075.959106,930.933228 
z'
        />
        <path
            fill='#487261'
            opacity='1.000000'
            stroke='none'
            d='
M725.994019,1075.896240 
	C725.954529,1075.529663 726.159424,1075.253662 726.599976,1074.861572 
	C726.869751,1074.966553 726.903809,1075.187378 726.846680,1075.730469 
	C726.583069,1076.030884 726.410706,1076.008911 725.994019,1075.896240 
z'
        />
    </svg>
);

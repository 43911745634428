export enum CurrencyRateType {
    CentralBank = 1,
    Internal = 2,
}

export const enumLabel = (value?: CurrencyRateType) => {
    switch (value) {
        case CurrencyRateType.CentralBank:
            return 'Центробанк';
        case CurrencyRateType.Internal:
            return 'Внутренний';
        default:
            return '';
    }
};

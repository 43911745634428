import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Space, Button, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import './form-header.css';

const FormHeader = ({ ...props }) => {
    const { Title } = Typography;

    const { title, hideBack } = props;

    const navigate = useNavigate();

    return (
        <div className='form-header-container'>
            <Space size={'small'}>
                {!hideBack && (
                    <Button size='large' type='link' onClick={() => navigate(-1)}>
                        <LeftOutlined style={{ fontSize: 24 }} />
                    </Button>
                )}
                <Title level={3} style={{ marginTop: 6 }}>
                    {title}
                </Title>
            </Space>
        </div>
    );
};

export default FormHeader;

import React from 'react';

import { Permission, enumLabel } from '@enums/permission';
import { NotificationInstance } from 'antd/es/notification/interface';

const duration: number = 15;

export const exception = (api: NotificationInstance, message: string, ex: any, logout: any) => {
    api.error({
        message: message,
        description: ex && ex.userMessage.replace(/\r/g, ', '),
        placement: 'bottomRight',
        duration: duration,
    });

    if (ex.statusCode === 401 && logout) logout();
};

export const error = (api: NotificationInstance, message: string, description: string = '') => {
    api.error({
        message: message,
        description: description,
        placement: 'bottomRight',
        duration: duration,
    });
};

export const success = (api: NotificationInstance, message: string, description: string = '') => {
    api.success({
        message: message,
        description: description,
        placement: 'bottomRight',
        duration: duration,
    });
};

export const warning = (api: NotificationInstance, message: string, description: any = undefined) => {
    api.warning({
        message: message,
        description: description,
        placement: 'bottomRight',
        duration: duration,
    });
};

export const info = (api: NotificationInstance, message: string, description: any = undefined) => {
    api.info({
        message: message,   
        description: description,
        placement: 'bottomRight',
        duration: duration,
    });
};

export const noAccessError = (api: NotificationInstance, permissions: Array<Permission> = []) => {
    api.error({
        message: 'Извините, у вас нет прав доступа',
        description: (
            <>
                <ul style={{ fontWeight: 600 }}>
                    {permissions.map((p) => {
                        return <li key={p}>{enumLabel(p)}</li>;
                    })}
                </ul>
                Пожалуйста, обратитесь к администратору ресурса.
            </>
        ),
        placement: 'bottomRight',
        duration: duration,
    });
};

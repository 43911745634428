import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { v4 as uuid } from 'uuid';

import {
    Modal,
    Button,
    Switch,
    Input,
    Form,
    Space,
    Divider,
    Select,
    Row,
    Col,
    Table,
    Tooltip,
    notification,
    Flex,
    InputNumber,
    Tag,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    LoadingOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    CheckOutlined,
} from '@ant-design/icons';

import { MaskedInput } from 'antd-mask-input';

import FormHeader from '@controls/form-header/form-header';
import Toolbar from '@controls/toolbar/toolbar';

import Employee from '@components/users/employee';

import { getEnumList, toFinanceString } from '@extensions/utils';

import { exception, warning, success } from '@extensions/notification';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@src/store/hooks';

import { serverFetch } from '@src/core/server';
import { IUserSession } from '@entities/user-session';
import { IUser } from '@entities/user';
import { IUserPermission } from '@entities/user-permission';
import { IWarehouse } from '@entities/warehouse';
import { ICompany } from '@entities/company';
import { ICompanyFilter } from '@entities/company-filter';
import { IUserDeliverySetting } from '@entities/user-delivery-setting';
import { IUserAccount } from '@entities/user-account';
import { IUserDiscount } from '@entities/user-discount';
import { IUserDiscountFilter } from '@entities/user-discount-filter';
import { IDebt } from '@entities/debt';
import { IUserDebt } from '@entities/user-debt';
import { IUserDebtFilter } from '@entities/user-debt-filter';

import { UserType, enumLabel as userTypeLabel } from '@enums/user-type';
import { Permission, hasPermission, enumLabel as permissionLabel } from '@enums/permission';
import { DayOfWeek, enumLabel as dayOfWeekLabel } from '@enums/day-of-week';
import { DiscountType, enumLabel as discountTypeLabel } from '@enums/discount-type';
import { Currency, enumSign as currencySign, enumLabel as enumCurrencyLabel } from '@src/core/enums/currency';
import { IEnumItem } from '@enums/enum-item';

import { MobileIcon, MobileNoneIcon } from '@icons/index';
import { RubleFilledIcon, UsdFilledIcon, EuroFilledIcon } from '@icons/filled/index';

import './user.css';

interface IUserParams {
    user: IUser;
    userPermissions: Array<IUserPermission>;
    companies?: Array<ICompany>;
    discounts?: Array<IUserDiscount>;
    userDebts?: Array<IUserDebt>;
    deliverySetting?: IUserDeliverySetting;
}

interface IDay {
    label: string;
    type: DayOfWeek;
    checked: boolean;
}

const User = () => {
    const { TextArea } = Input;

    const { id } = useParams();
    const navigate = useNavigate();

    const [userDebtForm] = Form.useForm();
    const [discountForm] = Form.useForm();
    const [companyForm] = Form.useForm();
    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const [api, contextHolder] = notification.useNotification();

    const [entity, setEntity] = useState<IUser>();

    const [discounts, setDiscounts] = useState<Array<IUserDiscount>>([]);
    const [selectedDiscountIds, setSelectedDiscountIds] = useState<React.Key[]>([]);
    const [currentDiscount, setCurrentDiscount] = useState<IUserDiscount>();
    const [discountsRefreshRequired, setDiscountsRefreshRequired] = useState<boolean>();
    const [discountOpen, setDiscountOpen] = useState<boolean>(false);
    const [loadingDiscounts, setLoadingDiscounts] = useState<boolean>(false);
    const [discountTypes] = useState(getEnumList(DiscountType, discountTypeLabel));

    const [userDebts, setUserDebts] = useState<Array<IUserDebt>>([]);
    const [selectedUserDebtIds, setSelectedUserDebtIds] = useState<React.Key[]>([]);
    const [currentUserDebt, setCurrentUserDebt] = useState<IUserDebt>();
    const [userDebtsRefreshRequired, setUserDebtsRefreshRequired] = useState<boolean>();
    const [userDebtOpen, setUserDebtOpen] = useState<boolean>(false);
    const [loadingUserDebts, setLoadingUserDebts] = useState<boolean>(false);
    const [debts, setDebts] = useState<Array<IDebt>>([]);
    const [currencies] = useState<Array<IEnumItem>>(getEnumList(Currency, enumCurrencyLabel));

    const [companies, setCompanies] = useState<Array<ICompany>>([]);
    const [selectedCompanyIds, setSelectedCompanyIds] = useState<React.Key[]>([]);
    const [currentCompany, setCurrentCompany] = useState<ICompany>();
    const [companiesRefreshRequired, setCompaniesRefreshRequired] = useState<boolean>();
    const [companyOpen, setCompanyOpen] = useState<boolean>(false);
    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false);
    const [allowSetPassword, setAllowSetPassword] = useState<boolean>(!id);
    const [isViewOnly] = useState<boolean>(!hasPermission(userSession.permissions, Permission.ManageUsers));
    const [userTypes, setUserTypes] = useState<Array<IEnumItem>>(
        getEnumList(UserType, userTypeLabel).filter((t) => t.value != UserType.Employee && t.value != UserType.MainClient)
    );

    const [warehouses, setWarehouses] = useState<Array<IWarehouse>>([]);
    const [days, setDays] = useState<Array<IDay>>([]);

    const [accounts, setAccounts] = useState<Array<IUserAccount>>([]);

    const [loadedUserPermissions, setLoadedUserPermissions] = useState<Array<IUserPermission>>();
    const [userPermissions, setUserPermissions] = useState<Array<IUserPermission>>([]);
    const [permissions] = useState<Array<IEnumItem>>(getEnumList(Permission, permissionLabel));
    const [dayOfWeeks] = useState<Array<IEnumItem>>(getEnumList(DayOfWeek, dayOfWeekLabel));

    const [phone, setPhone] = useState<string>();

    const [employees, setEmployees] = useState<Array<IUser>>([]);
    const [openEmployee, setOpenEmployee] = useState<boolean>(false);
    const [currentEmployee, setCurrentEmployee] = useState<IUser>();
    const [selectedEmployeeIds, setEmployeeSelectedIds] = useState<React.Key[]>([]);
    const [employeesRefreshRequired, setEmployeesRefreshRequired] = useState<boolean>();
    const [loadingEmployees, setLoadingEmployees] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('warehouses', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения складов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('debts', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения типов долга', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            if (id) {
                promises.push(
                    await serverFetch(`users/${id}`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения пользователя', ex, () => d(userLoaded(undefined)));
                        }),

                    await serverFetch(`users/${id}/permissions`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения прав', ex, () => d(userLoaded(undefined)));
                        }),

                    await serverFetch(`users/${id}/deliverysetting`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения настроек доставки', ex, () => d(userLoaded(undefined)));
                        }),

                    await serverFetch(`users/${id}/accounts`, { method: 'GET' })
                        .then((data) => {
                            return data;
                        })
                        .catch((ex) => {
                            exception(api, 'Ошибка получения счетов', ex, () => d(userLoaded(undefined)));
                        })
                );
            }

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setWarehouses(result[0][0] || []);
                setDebts(result[0][1]);

                let user = result[0][2];
                if (user) {
                    setEntity(user);
                    setPhone(user.phone);
                } else {
                    setEntity({
                        id: undefined,
                        login: undefined,
                        email: undefined,
                        fullName: undefined,
                        isActive: undefined,
                        phone: undefined,
                        isArchived: false,
                        type: UserType.System,
                        userSettings: {
                            showBills: false,
                            showNotifications: false,
                        },
                    });
                }

                if (user && user.id && user.type != UserType.System) {
                    setDiscountsRefreshRequired(true);
                    setCompaniesRefreshRequired(true);
                    setEmployeesRefreshRequired(true);
                    setUserDebtsRefreshRequired(true);
                }

                if (user && user.type == UserType.MainClient) {
                    setUserTypes([{ value: UserType.MainClient, label: userTypeLabel(UserType.MainClient) }]);
                }

                let userPermissions = result[0][3];

                if (userPermissions) {
                    setLoadedUserPermissions(userPermissions);
                } else {
                    setLoadedUserPermissions([]);
                }

                let deliverySetting: IUserDeliverySetting = result[0][4];
                let selectedDays: Array<DayOfWeek> = [];

                if (deliverySetting) {
                    selectedDays = JSON.parse(deliverySetting.daysOfWeek);
                }

                let tmpDays: Array<IDay> = [];
                dayOfWeeks.map((d) => {
                    let selectedDay = selectedDays.find((s) => s == d.value);

                    let day: IDay = {
                        type: d.value,
                        label: d.label,
                        checked: selectedDay != undefined,
                    };

                    tmpDays.push(day);
                });

                setDays(tmpDays);

                setAccounts(result[0][5]);

                setLoading(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, []);

    useEffect(() => {
        if (!userDebtsRefreshRequired) return;

        setUserDebts([]);

        setSelectedUserDebtIds([]);
        setCurrentUserDebt(undefined);

        setUserDebtsRefreshRequired(false);

        setLoadingUserDebts(true);

        var userDebtFilter: IUserDebtFilter = {
            userId: id,
        };

        serverFetch(`userdebts`, { method: 'GET', queryParams: userDebtFilter })
            .then((data) => {
                setUserDebts(data);
                setLoadingUserDebts(false);
            })
            .catch((ex) => {
                setLoadingUserDebts(false);
                exception(api, 'Ошибка получения долгов перед клиентом', ex, () => d(userLoaded(undefined)));
            });
    }, [userDebtsRefreshRequired]);

    useEffect(() => {
        if (!discountsRefreshRequired) return;

        setDiscounts([]);

        setSelectedDiscountIds([]);
        setCurrentDiscount(undefined);

        setDiscountsRefreshRequired(false);

        setLoadingDiscounts(true);

        var discountFilter: IUserDiscountFilter = {
            userId: id,
        };

        serverFetch(`userdiscounts`, { method: 'GET', queryParams: discountFilter })
            .then((data) => {
                setDiscounts(data);
                setLoadingDiscounts(false);
            })
            .catch((ex) => {
                setLoadingDiscounts(false);
                exception(api, 'Ошибка получения скидок', ex, () => d(userLoaded(undefined)));
            });
    }, [discountsRefreshRequired]);

    useEffect(() => {
        if (!companiesRefreshRequired) return;

        setCompanies([]);

        setSelectedCompanyIds([]);
        setCurrentCompany(undefined);

        setCompaniesRefreshRequired(false);

        setLoadingCompanies(true);

        var companyFilter: ICompanyFilter = {
            userId: id,
        };

        serverFetch(`companies`, { method: 'GET', queryParams: companyFilter })
            .then((data) => {
                setCompanies(data);
                setLoadingCompanies(false);
            })
            .catch((ex) => {
                setLoadingCompanies(false);
                exception(api, 'Ошибка получения контрагентов', ex, () => d(userLoaded(undefined)));
            });
    }, [companiesRefreshRequired]);

    useEffect(() => {
        if (!employeesRefreshRequired) return;

        setCompanies([]);

        setEmployeeSelectedIds([]);
        setCurrentEmployee(undefined);

        setEmployeesRefreshRequired(false);

        setLoadingEmployees(true);

        serverFetch(`users/${id}/employees`, { method: 'GET' })
            .then((data) => {
                setEmployees(data);
                setLoadingEmployees(false);
            })
            .catch((ex) => {
                setLoadingEmployees(false);
                exception(api, 'Ошибка получения сотрудников', ex, () => d(userLoaded(undefined)));
            });
    }, [employeesRefreshRequired]);

    useEffect(() => {
        if (loadedUserPermissions) {
            let entities: Array<IUserPermission> = [];
            permissions.map((p) => {
                let permission = loadedUserPermissions.find((e) => e.permissionCode === p.value);
                if (permission) {
                    entities.push({ userId: id as string, name: p.label, permissionCode: p.value, isActive: true });
                } else {
                    entities.push({ userId: id as string, name: p.label, permissionCode: p.value, isActive: false });
                }
            });

            setUserPermissions(entities);
        }
    }, [loadedUserPermissions]);

    const onFinish = () => {
        if (!entity) return;

        setLoading(true);

        let activeUserPermissions = userPermissions.filter((p) => p.isActive);
        let activeDays = days.filter((p) => p.checked).map((d) => d.type);

        let deliverySetting: IUserDeliverySetting = {
            userId: id,
            daysOfWeek: JSON.stringify(activeDays),
        };

        entity.phone = phone;

        let data: IUserParams = {
            user: entity,
            userPermissions: activeUserPermissions,
            companies: id ? undefined : companies,
            discounts: id ? undefined : discounts,
            userDebts: id ? undefined : userDebts,
            deliverySetting: deliverySetting,
        };

        serverFetch(`users`, { method: id ? 'PUT' : 'POST', bodyData: data })
            .then(() => {
                setLoading(false);
                success(api, 'Изменения успешно сохранены');
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения пользователя', ex, () => d(userLoaded(undefined)));
            });
    };

    const onRestore = () => {
        serverFetch(`users/restore/${id}`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка восстановления пользователя', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSaveUserDebt = (data: IUserDebt) => {
        if (id) {
            onUserDebtServerSave(data);
        } else {
            onUserDebtBuffSave(data);
        }
    };

    const onUserDebtServerSave = (data: IUserDebt) => {
        if (!data) return;

        setLoadingUserDebts(true);

        data.userId = id;

        serverFetch(`userdebts`, { method: data.id ? 'PUT' : 'POST', bodyData: data })
            .then(() => {
                setLoadingUserDebts(false);

                setUserDebtsRefreshRequired(true);

                userDebtForm.resetFields();
                setUserDebtOpen(false);
            })
            .catch((ex) => {
                setLoadingUserDebts(false);
                exception(api, 'Ошибка сохранения долга', ex, () => d(userLoaded(undefined)));
            });
    };

    const onUserDebtBuffSave = (data: IUserDebt) => {
        let tmpUserDebts = [...userDebts];
        let userDebt = tmpUserDebts.find((c) => c.id === data.id);

        if (!userDebt) {
            tmpUserDebts.push({ ...data, id: uuid() });
        } else {
            userDebt = tmpUserDebts.find((p) => p.id === data.id);

            if (userDebt) {
                var index = tmpUserDebts.indexOf(userDebt);
                if (index !== -1) {
                    tmpUserDebts[index] = data;
                }
            }
        }

        setUserDebts(tmpUserDebts);

        setSelectedUserDebtIds([]);
        setCurrentUserDebt(undefined);

        userDebtForm.resetFields();
        setUserDebtOpen(false);
    };

    const onSaveDiscount = (data: IUserDiscount) => {
        if (id) {
            onDiscountServerSave(data);
        } else {
            onDiscountBuffSave(data);
        }
    };

    const onDiscountServerSave = (data: IUserDiscount) => {
        if (!data) return;

        setLoadingDiscounts(true);

        data.userId = id;

        serverFetch(`userdiscounts`, { method: data.id ? 'PUT' : 'POST', bodyData: data })
            .then(() => {
                setLoadingDiscounts(false);

                setDiscountsRefreshRequired(true);

                discountForm.resetFields();
                setDiscountOpen(false);
            })
            .catch((ex) => {
                setLoadingDiscounts(false);
                exception(api, 'Ошибка сохранения скидки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDiscountBuffSave = (data: IUserDiscount) => {
        let tmpDiscounts = [...discounts];
        let discount = tmpDiscounts.find((c) => c.id === data.id);

        if (!discount) {
            tmpDiscounts.push({ ...data, id: uuid() });
        } else {
            discount = tmpDiscounts.find((p) => p.id === data.id);

            if (discount) {
                var index = tmpDiscounts.indexOf(discount);
                if (index !== -1) {
                    tmpDiscounts[index] = data;
                }
            }
        }

        setDiscounts(tmpDiscounts);

        setSelectedDiscountIds([]);
        setCurrentDiscount(undefined);

        discountForm.resetFields();
        setDiscountOpen(false);
    };

    const onSaveCompany = (data: ICompany) => {
        if (id) {
            onCompanyServerSave(data);
        } else {
            onCompanyBuffSave(data);
        }
    };

    const onCompanyServerSave = (data: ICompany) => {
        if (!data) return;

        setLoadingCompanies(true);

        data.userId = id;

        serverFetch(`companies`, { method: data.id ? 'PUT' : 'POST', bodyData: data })
            .then(() => {
                setLoadingCompanies(false);

                setCompaniesRefreshRequired(true);

                companyForm.resetFields();
                setCompanyOpen(false);
            })
            .catch((ex) => {
                setLoadingCompanies(false);
                exception(api, 'Ошибка сохранения контрагента', ex, () => d(userLoaded(undefined)));
            });
    };

    const onCompanyBuffSave = (data: ICompany) => {
        let tmpCompanies = [...companies];
        let company = tmpCompanies.find((c) => c.id === data.id);

        if (!company) {
            tmpCompanies.push({ ...data, id: uuid() });
        } else {
            company = tmpCompanies.find((p) => p.id === data.id);

            if (company) {
                var index = tmpCompanies.indexOf(company);
                if (index !== -1) {
                    tmpCompanies[index] = data;
                }
            }
        }

        setCompanies(tmpCompanies);

        setSelectedCompanyIds([]);
        setCurrentCompany(undefined);

        companyForm.resetFields();
        setCompanyOpen(false);
    };

    const onDeleteUserDebt = () => {
        serverFetch(`userdebts/${currentUserDebt?.id}`, { method: 'DELETE' })
            .then(() => {
                setUserDebtsRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления долга', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDeleteDiscount = () => {
        serverFetch(`userdiscounts/${currentDiscount?.id}`, { method: 'DELETE' })
            .then(() => {
                setDiscountsRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления скидки', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDeleteCompany = () => {
        serverFetch(`companies/${currentCompany?.id}`, { method: 'DELETE' })
            .then(() => {
                setCompaniesRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления контрагента', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDeleteEmployee = () => {
        serverFetch(`users/${currentEmployee?.id}`, { method: 'DELETE' })
            .then(() => {
                setEmployeesRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления сотрудника', ex, () => d(userLoaded(undefined)));
            });
    };

    const onUserDebtSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedUserDebtIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = userDebts.find((c) => c.id === selectedRowKeys[0]);
            setCurrentUserDebt(entity);
        } else {
            setCurrentUserDebt(undefined);
        }
    };

    const onDiscountSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedDiscountIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = discounts.find((c) => c.id === selectedRowKeys[0]);
            setCurrentDiscount(entity);
        } else {
            setCurrentDiscount(undefined);
        }
    };

    const onCompanySelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedCompanyIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = companies.find((c) => c.id === selectedRowKeys[0]);
            setCurrentCompany(entity);
        } else {
            setCurrentCompany(undefined);
        }
    };

    const onEmployeeSelectChange = (selectedRowKeys: React.Key[]) => {
        setEmployeeSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = employees.find((c) => c.id === selectedRowKeys[0]);
            setCurrentEmployee(entity);
        } else {
            setCurrentEmployee(undefined);
        }
    };

    const renderPermissions = () => {
        return (
            <div style={{ marginTop: -74 }}>
                <Form.Item wrapperCol={{ span: 11 }}>
                    <Divider orientation='left'>Разрешения</Divider>
                </Form.Item>

                {userPermissions.map((p) => {
                    if (p.permissionCode < 0) {
                        return (
                            <Form.Item key={p.permissionCode} wrapperCol={{ offset: 1, span: 9 }}>
                                <Divider className='permissionGroup'>{p.name}</Divider>
                            </Form.Item>
                        );
                    }

                    return (
                        <Form.Item
                            labelAlign='left'
                            key={p.permissionCode}
                            label={p.name || p.permissionCode}
                            labelCol={{ offset: 1, span: 8 }}
                        >
                            <Switch
                                disabled={isViewOnly}
                                defaultChecked={p.isActive}
                                onChange={(value) => {
                                    let entities = [...userPermissions];

                                    let entity = entities.find((e) => e.permissionCode === p.permissionCode);

                                    if (entity) {
                                        entity.isActive = value;

                                        setUserPermissions([...entities]);
                                    }
                                }}
                            />
                        </Form.Item>
                    );
                })}
            </div>
        );
    };

    const renderUserDebtToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        disabled: loading || loadingUserDebts || !id,
                        key: 'refresh',
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setUserDebtsRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить ',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        disabled: isViewOnly,
                        onClick: () => {
                            setSelectedUserDebtIds([]);
                            setCurrentUserDebt(undefined);

                            userDebtForm.setFieldValue('currency', Currency.Usd);
                            setUserDebtOpen(true);
                        },
                    },
                    {
                        label: 'Изменить',
                        key: 'edit',
                        disabled: !currentUserDebt || isViewOnly,
                        icon: <EditOutlined />,
                        onClick: () => {
                            setUserDebtOpen(true);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentUserDebt || isViewOnly,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            modal.confirm({
                                title: `Удалить долг "${currentUserDebt?.debtName}" на сумму ${currentUserDebt?.amount} ${currencySign(
                                    currentUserDebt?.currency
                                )}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDeleteUserDebt();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const renderDiscountToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        disabled: loading || loadingDiscounts || !id,
                        key: 'refresh',
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setDiscountsRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить ',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        disabled: isViewOnly,
                        onClick: () => {
                            setSelectedDiscountIds([]);
                            setCurrentDiscount(undefined);

                            setDiscountOpen(true);
                        },
                    },
                    {
                        label: 'Изменить',
                        key: 'edit',
                        disabled: !currentDiscount || isViewOnly,
                        icon: <EditOutlined />,
                        onClick: () => {
                            setDiscountOpen(true);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentDiscount || isViewOnly,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            modal.confirm({
                                title: `Удалить скидку "${discountTypeLabel(currentDiscount?.type)}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDeleteDiscount();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const renderCompanyToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        disabled: loading || loadingCompanies || !id,
                        key: 'refresh',
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setCompaniesRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить ',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        disabled: isViewOnly,
                        onClick: () => {
                            setSelectedCompanyIds([]);
                            setCurrentCompany(undefined);

                            setCompanyOpen(true);
                        },
                    },
                    {
                        label: 'Изменить',
                        key: 'edit',
                        disabled: !currentCompany || isViewOnly,
                        icon: <EditOutlined />,
                        onClick: () => {
                            setCompanyOpen(true);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentCompany || isViewOnly,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (currentCompany?.isMain) {
                                warning(api, 'Нельзя удалить основного контрагента');
                                return;
                            }

                            modal.confirm({
                                title: `Удалить контрагента "${currentCompany?.inn}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDeleteCompany();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const renderEmployeeToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        disabled: loading || loadingCompanies || !id,
                        key: 'refresh',
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setCompaniesRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить ',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        disabled: isViewOnly,
                        onClick: () => {
                            setOpenEmployee(true);
                        },
                    },
                    {
                        label: 'Изменить',
                        key: 'edit',
                        disabled: !currentEmployee || isViewOnly,
                        icon: <EditOutlined />,
                        onClick: () => {
                            setOpenEmployee(true);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentEmployee || isViewOnly,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            modal.confirm({
                                title: `Удалить сотрудника "${currentEmployee?.login}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDeleteEmployee();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const renderAccounts = () => {
        return (
            accounts.length > 0 && (
                <div style={{ marginTop: -74 }}>
                    <Divider orientation='left'>Cчета</Divider>

                    <Flex justify='flex-start' align='center' gap='large'>
                        {accounts.map((a) => {
                            return (
                                <Flex key={a.currency} justify='center'>
                                    {a.currency == Currency.Rub ? (
                                        <RubleFilledIcon />
                                    ) : a.currency == Currency.Usd ? (
                                        <UsdFilledIcon />
                                    ) : (
                                        <EuroFilledIcon />
                                    )}
                                    <div style={{ fontSize: 18, fontWeight: 600 }}>{toFinanceString(a.amount || 0, 2)}</div>
                                </Flex>
                            );
                        })}
                    </Flex>
                </div>
            )
        );
    };

    const renderUserDebts = () => {
        const columns: ColumnsType<IUserDebt> = [
            {
                title: 'Название',
                width: 250,
                render: (_: any, record) => {
                    return record.debtName;
                },
            },
            {
                title: 'Сумма',
                width: 180,
                render: (_: any, record) => {
                    return (
                        <>
                            {record.amount} {currencySign(record.currency)}
                        </>
                    );
                },
            },
            {},
        ];

        return (
            <div style={{ marginTop: 40 }}>
                <Divider style={{ background: 'var(--main-red)' }} orientation='left'>
                    Долги перед клиентом
                </Divider>

                {renderUserDebtToolbar()}

                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={userDebts}
                    loading={{
                        spinning: loadingUserDebts,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    rowSelection={{
                        selectedRowKeys: selectedUserDebtIds,
                        onChange: onUserDebtSelectChange,
                        columnWidth: 35,
                        type: 'radio',
                    }}
                    onRow={(record) => {
                        return {
                            onClick: (event) => {
                                onUserDebtSelectChange([record.id || '']);
                            },
                        };
                    }}
                    pagination={false}
                    scroll={{ y: `calc(50vh - 180px)`, x: 'max-content' }}
                />
            </div>
        );
    };

    const renderDiscounts = () => {
        const columns: ColumnsType<IUserDiscount> = [
            {
                title: 'Тип',
                width: 250,
                render: (_: any, record) => {
                    return discountTypeLabel(record.type);
                },
            },
            {
                title: 'Скидка',
                width: 180,
                render: (_: any, record) => {
                    return <>{record.value} $</>;
                },
            },
            {
                title: 'В счет долга',
                width: 180,
                render: (_: any, record) => {
                    return record.debtName && <Tag color='var(--main-red)'>{record.debtName}</Tag>;
                },
            },
            {},
        ];

        return (
            <div style={{ marginTop: 40 }}>
                <Divider orientation='left'>Скидки</Divider>

                {renderDiscountToolbar()}

                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={discounts}
                    loading={{
                        spinning: loadingDiscounts,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    rowSelection={{
                        selectedRowKeys: selectedDiscountIds,
                        onChange: onDiscountSelectChange,
                        columnWidth: 35,
                        type: 'radio',
                    }}
                    onRow={(record) => {
                        return {
                            onClick: (event) => {
                                onDiscountSelectChange([record.id || '']);
                            },
                        };
                    }}
                    pagination={false}
                    scroll={{ y: `calc(50vh - 180px)`, x: 'max-content' }}
                />
            </div>
        );
    };

    const renderCompanies = () => {
        const columns: ColumnsType<ICompany> = [
            {
                title: 'Название',
                dataIndex: 'legalName',
                width: 250,
            },
            {
                title: 'ИНН',
                width: 180,
                dataIndex: 'inn',
            },
            {
                title: 'Юр. адрес',
                width: 300,
                dataIndex: 'legalAddress',
            },
            {
                title: 'Основной',
                width: 90,
                align: 'center',
                render: (_: any, record) => {
                    return record.isMain ? <CheckOutlined /> : '';
                },
            },
            {},
        ];

        return (
            <div style={{ marginTop: 40 }}>
                <Divider orientation='left'>Контрагенты</Divider>

                {renderCompanyToolbar()}

                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={companies}
                    loading={{
                        spinning: loadingCompanies,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    rowSelection={{
                        selectedRowKeys: selectedCompanyIds,
                        onChange: onCompanySelectChange,
                        columnWidth: 35,
                        type: 'radio',
                    }}
                    onRow={(record) => {
                        return {
                            onClick: (event) => {
                                onCompanySelectChange([record.id || '']);
                            },
                        };
                    }}
                    pagination={false}
                    scroll={{ y: `calc(50vh - 180px)`, x: 'max-content' }}
                />
            </div>
        );
    };

    const renderEmployees = () => {
        const columns: ColumnsType<IUser> = [
            {
                title: 'Логин',
                dataIndex: 'login',
                width: 200,
            },
            {
                title: 'Разрешения',
                width: 150,
                align: 'left',
                render: (_, record) => {
                    return (
                        <ul>
                            {record.userSettings.showBills && <li>Счета</li>}
                            {record.userSettings.showNotifications && <li>Уведомления</li>}
                        </ul>
                    );
                },
            },
            {
                title: 'Активно',
                dataIndex: 'isActive',
                width: 80,
                align: 'center',
                render: (_: any, record: IUser) => {
                    return record.isActive ? <CheckOutlined /> : '';
                },
            },
            {
                title: 'ФИО',
                dataIndex: 'fullName',
                width: 300,
            },
            {
                title: 'Телефон',
                dataIndex: 'phone',
                width: 150,
            },
            {
                width: 35,
                align: 'center',
                render: (_, record) => {
                    return record.firebaseToken ? (
                        <Tooltip title='Приложение "My Freshline" используется'>
                            <MobileIcon style={{ color: 'green', fontSize: 20 }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title='Приложение "My Freshline" не используется.'>
                            <MobileNoneIcon style={{ color: 'red', fontSize: 20 }} />
                        </Tooltip>
                    );
                },
            },
            {
                title: 'Email',
                dataIndex: 'email',
                width: 300,
            },
            {},
        ];

        return (
            <div style={{ marginTop: 40 }}>
                <Divider orientation='left'>Сотрудники</Divider>

                {renderEmployeeToolbar()}

                <Table
                    rowKey='id'
                    size='small'
                    columns={columns}
                    dataSource={employees}
                    loading={{
                        spinning: loadingEmployees,
                        indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                    }}
                    rowSelection={{
                        selectedRowKeys: selectedEmployeeIds,
                        onChange: onEmployeeSelectChange,
                        columnWidth: 35,
                        type: 'radio',
                    }}
                    onRow={(record) => {
                        return {
                            onClick: (event) => {
                                onEmployeeSelectChange([record.id || '']);
                            },
                        };
                    }}
                    pagination={false}
                    scroll={{ y: `calc(50vh - 20px)`, x: 'max-content' }}
                />
            </div>
        );
    };

    const renderSettings = () => {
        return (
            <>
                <Form.Item wrapperCol={{ offset: 2, span: 22 }}>
                    <Divider orientation='left' style={{ marginBottom: 0 }}>
                        Способ оплаты
                    </Divider>
                </Form.Item>
                <Form.Item
                    key='useCashPayments'
                    label='Наличный'
                    initialValue={entity?.useCashPayments}
                    valuePropName='checked'
                    name='useCashPayments'
                    style={{ marginBottom: 0 }}
                >
                    <Switch
                        disabled={isViewOnly || entity?.type == UserType.SubClient}
                        onChange={(value) => {
                            if (!entity) return;

                            setEntity({ ...entity, useCashPayments: value });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    key='useNonCashPayments'
                    label='Безналичный'
                    initialValue={entity?.useNonCashPayments}
                    valuePropName='checked'
                    name='useNonCashPayments'
                    style={{ marginBottom: 0 }}
                >
                    <Switch
                        disabled={isViewOnly || entity?.type == UserType.SubClient}
                        onChange={(value) => {
                            if (!entity) return;

                            setEntity({ ...entity, useNonCashPayments: value });
                        }}
                    />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 2, span: 22 }}>
                    <Divider orientation='left' style={{ marginBottom: 0 }}>
                        Разрешения
                    </Divider>
                </Form.Item>
                <Form.Item
                    key='showBills'
                    label='Счета'
                    initialValue={entity?.userSettings?.showBills}
                    valuePropName='checked'
                    name='showBills'
                    style={{ marginBottom: 0 }}
                >
                    <Switch
                        disabled={isViewOnly || entity?.type == UserType.SubClient}
                        onChange={(value) => {
                            if (!entity) return;

                            setEntity({ ...entity, userSettings: { ...entity.userSettings, showBills: value } });
                        }}
                    />
                </Form.Item>
                <Form.Item
                    key='showNotifications'
                    label='Уведомления'
                    initialValue={entity?.userSettings?.showNotifications}
                    valuePropName='checked'
                    name='showNotifications'
                    style={{ marginBottom: 0 }}
                >
                    <Switch
                        disabled={isViewOnly || entity?.type == UserType.SubClient}
                        onChange={(value) => {
                            if (!entity) return;

                            setEntity({ ...entity, userSettings: { ...entity.userSettings, showNotifications: value } });
                        }}
                    />
                </Form.Item>

                {entity?.type != UserType.Employee && (
                    <>
                        <Form.Item wrapperCol={{ offset: 2, span: 22 }}>
                            <Divider orientation='left' style={{ marginBottom: 0 }}>
                                Дни доставки
                            </Divider>
                        </Form.Item>

                        {days.map((s) => {
                            return (
                                <Form.Item
                                    key={s.type}
                                    label={s.label}
                                    initialValue={s.checked}
                                    valuePropName='checked'
                                    name={s.label}
                                    style={{ marginBottom: 0 }}
                                >
                                    <Switch
                                        disabled={isViewOnly || entity?.type == UserType.SubClient}
                                        onChange={(value) => {
                                            let tmpDays = [...days];
                                            let day = tmpDays.find((e) => e.type === s.type);

                                            if (day) day.checked = value;

                                            setDays(tmpDays);
                                        }}
                                    />
                                </Form.Item>
                            );
                        })}
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <FormHeader title={`${id ? (isViewOnly ? 'Информация о пользователе' : 'Изменить пользователя') : 'Добавить пользователя'}`} />
            {entity && (
                <Form colon={false} labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} onFinish={onFinish}>
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                initialValue={entity?.login}
                                required
                                validateFirst={true}
                                label='Логин'
                                name='login'
                                rules={[
                                    { required: true, message: 'Укажите логин' },
                                    {
                                        validator: async (_, value) => {
                                            let isEmailExist = await serverFetch(`users/loginexist/${id}/${value}`, { method: 'GET' })
                                                .then((data) => {
                                                    return data;
                                                })
                                                .catch((ex) => {
                                                    exception(api, 'Ошибка проверки логина', ex, () => d(userLoaded(undefined)));
                                                });

                                            if (!id && isEmailExist)
                                                return Promise.reject('Учетная запись с указанным логином уже существует');
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    autoFocus
                                    disabled={isViewOnly || !!id}
                                    onChange={(data) => {
                                        setEntity({ ...entity, login: data.target.value });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item required label='Пароль' name='newPassword' rules={[{ required: !id, message: 'Укажите пароль' }]}>
                                <Space.Compact style={{ width: '100%' }}>
                                    <Input.Password
                                        placeholder={id && !allowSetPassword ? '*******' : ''}
                                        disabled={!allowSetPassword || isViewOnly}
                                        onChange={(data) => {
                                            setEntity({ ...entity, newPassword: data.target.value });
                                        }}
                                    />
                                    {id && (
                                        <Button
                                            disabled={isViewOnly}
                                            type='primary'
                                            onClick={() => {
                                                setAllowSetPassword(true);
                                            }}
                                        >
                                            Сменить пароль
                                        </Button>
                                    )}
                                </Space.Compact>
                            </Form.Item>
                            <Form.Item
                                initialValue={entity?.type}
                                required
                                label='Тип'
                                name='type'
                                rules={[{ required: true, message: 'Укажите тип' }]}
                            >
                                <Select
                                    disabled={isViewOnly || entity.type == UserType.MainClient || entity.type == UserType.SubClient}
                                    onChange={(value) => {
                                        setEntity({ ...entity, type: value });
                                    }}
                                    options={userTypes.map((t) => {
                                        return { value: t.value, label: t.label };
                                    })}
                                />
                            </Form.Item>
                            {entity?.type == UserType.System && (
                                <Form.Item initialValue={entity.warehouseId} label='Склад' name='warehouse'>
                                    <Select
                                        allowClear
                                        disabled={isViewOnly}
                                        showSearch
                                        onChange={(value) => setEntity({ ...entity, warehouseId: value })}
                                        optionFilterProp='children'
                                        filterOption={(input, option) =>
                                            (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                        }
                                        filterSort={(a, b) =>
                                            (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                        }
                                        options={warehouses.map((w) => {
                                            return { value: w.id, label: `${w.code} (${w.cityName})` };
                                        })}
                                    />
                                </Form.Item>
                            )}
                            <Form.Item
                                initialValue={entity?.fullName}
                                required
                                label='ФИО'
                                name='fullName'
                                rules={[{ required: true, message: 'Укажите ФИО' }]}
                            >
                                <Input
                                    disabled={isViewOnly}
                                    onChange={(data) => {
                                        setEntity({ ...entity, fullName: data.target.value });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                initialValue={entity?.phone}
                                required
                                name='phone'
                                label='Телефон'
                                rules={[{ required: true, message: 'Укажите телефон' }]}
                            >
                                <MaskedInput
                                    disabled={isViewOnly}
                                    size='middle'
                                    mask={'+0 (000) 000-00-00'}
                                    onChange={(data) => {
                                        setPhone(data.maskedValue);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                initialValue={entity?.email}
                                validateFirst={true}
                                label='Email'
                                name='email'
                                rules={[
                                    { type: 'email', message: 'Неверный формат Email' },
                                    {
                                        validator: async (_, value) => {
                                            let isEmailExist = await serverFetch(`users/emailexist/${id}/${value}`, { method: 'GET' })
                                                .then((data) => {
                                                    return data;
                                                })
                                                .catch((ex) => {
                                                    exception(api, 'Ошибка проверки Email', ex, () => d(userLoaded(undefined)));
                                                });

                                            if (!id && isEmailExist)
                                                return Promise.reject('Учетная запись с указанным email уже существует');
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    disabled={isViewOnly}
                                    autoComplete='none'
                                    onChange={(data) => {
                                        setEntity({ ...entity, email: data.target.value });
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label='Активно' name='isActive' valuePropName='checked'>
                                <Switch
                                    disabled={isViewOnly}
                                    defaultChecked={entity?.isActive}
                                    onChange={(value) => {
                                        setEntity({ ...entity, isActive: value });
                                    }}
                                />
                            </Form.Item>

                            {entity.type != UserType.System && renderSettings()}

                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Space size={'small'} style={{ float: 'right' }}>
                                    <Button type='link' onClick={() => navigate(-1)}>
                                        Отменить
                                    </Button>
                                    {!isViewOnly &&
                                        (entity.isArchived ? (
                                            hasPermission(userSession.permissions, Permission.FullAccess) && (
                                                <Button type='primary' onClick={() => onRestore()}>
                                                    Восстановить
                                                </Button>
                                            )
                                        ) : (
                                            <Button type='primary' htmlType='submit' loading={loading}>
                                                Сохранить
                                            </Button>
                                        ))}
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col span={15} offset={1}>
                            {entity.type === UserType.System ? (
                                renderPermissions()
                            ) : entity.type === UserType.MainClient || entity.type === UserType.Supplier ? (
                                <>
                                    {renderAccounts()}
                                    {renderUserDebts()}
                                    {renderDiscounts()}
                                    {renderCompanies()}
                                    {renderEmployees()}
                                </>
                            ) : (
                                <></>
                            )}
                        </Col>
                    </Row>
                </Form>
            )}

            <Modal
                destroyOnClose={true}
                maskClosable={false}
                title={currentUserDebt ? 'Изменить долг' : 'Добавить долг'}
                open={userDebtOpen}
                okText='ОК'
                onOk={() => userDebtForm.submit()}
                onCancel={() => {
                    userDebtForm.resetFields();
                    setUserDebtOpen(false);
                }}
                width={500}
            >
                <Form
                    preserve={false}
                    colon={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    style={{ marginTop: 20 }}
                    onFinish={onSaveUserDebt}
                    form={userDebtForm}
                >
                    <Form.Item hidden name='id' initialValue={currentUserDebt?.id}>
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name='currency' initialValue={currentUserDebt?.currency}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label='Тип'
                        initialValue={currentUserDebt?.debtId}
                        required
                        name='debtId'
                        rules={[{ required: true, message: 'Укажите тип долга' }]}
                    >
                        <Select
                            disabled={isViewOnly}
                            options={debts.map((d) => {
                                return { value: d.id, label: d.name };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Сумма'
                        initialValue={currentUserDebt?.amount}
                        name='amount'
                        rules={[{ required: true, message: 'Укажите сумму долга' }]}
                    >
                        <InputNumber
                            precision={2}
                            decimalSeparator=','
                            min={0}
                            style={{ width: '100%' }}
                            addonAfter={
                                <Select
                                    style={{ width: 90 }}
                                    defaultValue={Currency.Usd}
                                    onChange={(value: Currency) => {
                                        userDebtForm.setFieldValue('currency', value);
                                    }}
                                    options={currencies
                                        .filter((c) => c.value != Currency.Rub)
                                        .map((t) => {
                                            return { value: t.value, label: t.label };
                                        })}
                                />
                            }
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                destroyOnClose={true}
                maskClosable={false}
                title={currentDiscount ? 'Изменить скидку' : 'Добавить скидку'}
                open={discountOpen}
                okText='ОК'
                onOk={() => discountForm.submit()}
                onCancel={() => {
                    discountForm.resetFields();
                    setDiscountOpen(false);
                }}
                width={500}
            >
                <Form
                    preserve={false}
                    colon={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    style={{ marginTop: 20 }}
                    onFinish={onSaveDiscount}
                    form={discountForm}
                >
                    <Form.Item hidden name='id' initialValue={currentDiscount?.id}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label='Тип'
                        initialValue={currentDiscount?.type}
                        name='type'
                        rules={[{ required: true, message: 'Укажите тип скидки' }]}
                    >
                        <Select
                            disabled={isViewOnly}
                            options={discountTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        label='Скидка'
                        initialValue={currentDiscount?.value}
                        name='value'
                        required={true}
                        rules={[{ required: true, message: 'Укажите значение скидки' }]}
                    >
                        <InputNumber decimalSeparator=',' disabled={isViewOnly} addonAfter='$' />
                    </Form.Item>
                    <Form.Item label='В счет долга' initialValue={currentDiscount?.userDebtId} name='userDebtId'>
                        <Select
                            disabled={isViewOnly}
                            options={userDebts
                                .filter((d) => d.currency == Currency.Usd)
                                .map((d) => {
                                    return { value: d.id, label: `${d.debtName} (${d.amount}${currencySign(d.currency)})` };
                                })}
                        />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                destroyOnClose={true}
                maskClosable={false}
                title={currentCompany ? 'Изменить контрагента' : 'Добавить контрагента'}
                open={companyOpen}
                okText='ОК'
                onOk={() => companyForm.submit()}
                onCancel={() => {
                    companyForm.resetFields();
                    setCompanyOpen(false);
                }}
                width={500}
            >
                <Form
                    preserve={false}
                    colon={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    style={{ marginTop: 20 }}
                    onFinish={onSaveCompany}
                    form={companyForm}
                >
                    <Form.Item hidden name='id' initialValue={currentCompany?.id}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        initialValue={currentCompany?.legalName}
                        label='Название'
                        name='legalName'
                        required={true}
                        rules={[{ required: true, message: 'Укажите контрагента' }]}
                    >
                        <Input autoFocus disabled={isViewOnly} />
                    </Form.Item>
                    <Form.Item
                        label='ИНН'
                        initialValue={currentCompany?.inn}
                        name='inn'
                        required={true}
                        rules={[{ required: true, message: 'Укажите ИНН' }]}
                    >
                        <Input disabled={isViewOnly} />
                    </Form.Item>
                    <Form.Item
                        label='Юр. адрес'
                        initialValue={currentCompany?.legalAddress}
                        name='legalAddress'
                        required={true}
                        rules={[{ required: true, message: 'Укажите юридический адрес' }]}
                    >
                        <TextArea disabled={isViewOnly} />
                    </Form.Item>
                    <Form.Item label='Основной' name='IsMain' valuePropName='checked'>
                        <Switch disabled={isViewOnly} defaultChecked={currentCompany ? currentCompany.isMain : companies.length <= 0} />
                    </Form.Item>
                </Form>
            </Modal>

            {entity && openEmployee && (
                <Employee
                    id={currentEmployee?.id}
                    parentUserId={entity.id}
                    parentLogin={entity.login}
                    onClose={(refreshRequired: boolean) => {
                        setEmployeesRefreshRequired(refreshRequired);
                        setEmployeeSelectedIds([]);
                        setCurrentEmployee(undefined);
                        setOpenEmployee(false);
                    }}
                />
            )}

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default User;

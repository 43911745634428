import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { notification } from 'antd';

import { Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LoadingOutlined, ReloadOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { exception, noAccessError } from '@extensions/notification';
import { Permission, hasPermission } from '@enums/permission';

import { userLoaded } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { IUserSession } from '@entities/user-session';
import { ITradingPlatform } from '@entities/trading-platform';

import { serverFetch } from '@src/core/server';

const TradingPlatforms = () => {
    const navigate = useNavigate();
    const resolved = useResolvedPath('');
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [tradingPlatforms, setTradingPlatforms] = useState<Array<ITradingPlatform>>([]);
    const [currentTradingPlatform, setCurrentTradingPlatform] = useState<ITradingPlatform>();

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);
        init();
    }, [refreshRequired]);

    const init = () => {
        setTradingPlatforms([]);

        setLoading(true);

        serverFetch('tradingplatforms', { method: 'GET' })
            .then((data) => {
                setTradingPlatforms(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения торговых площадок', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onDelete = () => {
        if (!currentTradingPlatform) return;

        serverFetch(`tradingplatforms/${currentTradingPlatform.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления торговой площадки', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        type: 'primary',
                        label: 'Добавить',
                        key: 'add',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTradingPlatforms)) {
                                noAccessError(api, [Permission.ManageTradingPlatforms]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                    {
                        label: `${hasPermission(userSession.permissions, Permission.ManageTradingPlatforms) ? 'Изменить' : 'Информация о платформе'}`,
                        key: 'edit',
                        disabled: !currentTradingPlatform,
                        icon: <EditOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentTradingPlatform?.id}`);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentTradingPlatform,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteTradingPlatforms)) {
                                noAccessError(api, [Permission.DeleteTradingPlatforms]);
                                return;
                            }

                            Modal.confirm({
                                title: `Удалить город "${currentTradingPlatform?.name}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = tradingPlatforms.find((o) => o.id == selectedRowKeys[0]);
            setCurrentTradingPlatform(entity);
        } else {
            setCurrentTradingPlatform(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<ITradingPlatform> = [
            {
                title: 'Страна',
                dataIndex: 'countryName',
                width: 180,
            },
            {
                title: 'Площадка',
                dataIndex: 'name',
                width: 300,
            },
            { title: ' ' },
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'radio',
                }}
                columns={columns}
                dataSource={tradingPlatforms}
                loading={tableLoading}
                onRow={(record: ITradingPlatform) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 175px)` }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {renderTable()}

            {contextHolder}
        </>
    );
};

export default TradingPlatforms;

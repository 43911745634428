import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { v4 as uuid } from 'uuid';

import 'core-js/actual/array/group';

const borderColor = '#90e5fc';

const TableRow = ({ item }) => {
    const styles = StyleSheet.create({
        tableContainer: {
            flexDirection: 'column',
            flexWrap: 'wrap',
            border: 'none',
            width: '50%',
        },

        row: {
            flexDirection: 'row',
            alignItems: 'right',
            textAlign: 'center',
        },

        consigneeCode: {
            paddingTop: 2,
            width: '40px',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },

        marking: {
            paddingTop: 2,
            width: '25%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        phone: {
            paddingTop: 2,
            width: '25%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        country: {
            paddingTop: 2,
            width: '50%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        qty: {
            paddingTop: 2,
            width: 'calc(50% - 40px)',
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },

        footer: {
            width: '100%',
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },

        driverSign: {
            padding: '4px 2px 2px 4px',
            textAlign: 'left',
            width: '50%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },

        consigneeSign: {
            padding: '4px 2px 2px 4px',
            width: '50%',
            textAlign: 'left',
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
    });

    const rows = [];
    rows.push(
        <View style={styles.row} key={item.consigneeCode}>
            <Text style={styles.consigneeCode}>{item.consigneeCode}</Text>
            <Text style={styles.marking}>{item.markingCodes}</Text>
            <Text style={styles.phone}>{item.phone}</Text>
            <View style={styles.tableContainer}>
                {item.boxGroups.map((b) => {
                    return (
                        <View style={styles.row} key={uuid()}>
                            <Text style={styles.country}>{b.countryName}</Text>
                            <Text style={styles.qty}>{b.qty}</Text>
                        </View>
                    );
                })}
            </View>
        </View>
    );

    rows.push(
        <View style={styles.row} key={uuid()}>
            <Text style={styles.driverSign}>Доставил:</Text>
            <Text style={styles.consigneeSign}>Принял:</Text>
        </View>
    );

    rows.push(
        <View style={styles.row} key={uuid()}>
            <Text style={styles.footer}></Text>
        </View>
    );

    return <Fragment>{rows}</Fragment>;
};

export default TableRow;

import React from 'react';
import Icon from '@ant-design/icons';

const WarehouseIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18 }} {...props} />;
};
export default WarehouseIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512.003 512.003'>
        <g id='SVGRepo_bgCarrier'></g>
        <g id='SVGRepo_tracerCarrier'></g>
        <g id='SVGRepo_iconCarrier'>
            <g>
                <path d='m208.002,256.002h-16v32h-16v-32h-16c-17.672,0-32,14.328-32,32v48c0,17.672 14.328,32 32,32h48c17.672,0 32-14.328 32-32v-48c0-17.672-14.328-32-32-32z'></path>
                <path d='m352.002,256.002h-16v32h-16v-32h-16c-17.672,0-32,14.328-32,32v48c0,17.672 14.328,32 32,32h48c17.672,0 32-14.328 32-32v-48c0-17.672-14.328-32-32-32z'></path>
                <path d='m208.002,400.002h-16v32h-16v-32h-16c-17.672,0-32,14.328-32,32v48c0,17.672 14.328,32 32,32h48c17.672,0 32-14.328 32-32v-48c0-17.672-14.328-32-32-32z'></path>
                <path d='m352.002,400.002h-16v32h-16v-32h-16c-17.672,0-32,14.328-32,32v48c0,17.672 14.328,32 32,32h48c17.672,0 32-14.328 32-32v-48c0-17.672-14.328-32-32-32z'></path>
                <path d='m494.314,115.377l-224-112c-9.016-4.5-19.609-4.5-28.625,0l-224,112c-13.273,6.641-20.242,21.531-16.828,35.977 3.406,14.445 16.141,24.648 31.141,24.648v304c0,17.672 14.328,32 32,32 17.672,0 32-14.328 32-32v-256h320v256c0,17.672 14.328,32 32,32s32-14.328 32-32v-304c15,0 27.734-10.203 31.141-24.648 3.413-14.446-3.555-29.337-16.829-35.977zm-302.312,44.625h-32v-32h32v32zm96,0h-64v-32h64v32zm64,0h-32v-32h32v32z'></path>
            </g>
        </g>
    </svg>
);

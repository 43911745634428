export enum OperationType {
    Cash = 1,
    NonCash = 2,
}

export const enumLabel = (value: OperationType) => {
    switch (value) {
        case OperationType.Cash:
            return 'Наличный';
        case OperationType.NonCash:
            return 'Безналичный';

        default:
            return '';
    }
};

import React from 'react';
import Icon from '@ant-design/icons';

const CartIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18 }} {...props} />;
};
export default CartIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 330 330'>
        <g id='SVGRepo_bgCarrier' ></g>
        <g id='SVGRepo_tracerCarrier'></g>
        <g id='SVGRepo_iconCarrier'>
            <g id='XMLID_223_'>
                <path
                    id='XMLID_224_'
                    d='M15.5,15c0,8.284,6.716,15,15,15h25v225c0,8.284,6.716,15,15,15h7.58c-1.665,4.695-2.58,9.742-2.58,15 c0,24.813,20.187,45,45,45s45-20.187,45-45c0-5.258-0.915-10.305-2.58-15h34.16c-1.665,4.695-2.58,9.742-2.58,15 c0,24.813,20.186,45,45,45s45-20.187,45-45c0-5.258-0.915-10.305-2.58-15h17.58c8.284,0,15-6.716,15-15c0-8.284-6.716-15-15-15h-15 V115.999c0-8.284-6.716-15-15-15h-139c-8.284,0-15,6.716-15,15V240h-30V15c0-8.284-6.716-15-15-15h-40C22.216,0,15.5,6.716,15.5,15 z M239.5,270c8.271,0,15,6.729,15,15s-6.729,15-15,15s-15-6.729-15-15S231.229,270,239.5,270z M190.5,161h19 c8.284,0,15,6.716,15,15s-6.716,15-15,15h-19c-8.284,0-15-6.716-15-15S182.216,161,190.5,161z M120.5,270c8.271,0,15,6.729,15,15 s-6.729,15-15,15s-15-6.729-15-15S112.229,270,120.5,270z'
                ></path>
            </g>
        </g>
    </svg>
);

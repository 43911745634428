import React, { useState, useEffect, useRef } from 'react';

import { saveAs } from 'file-saver';
import { Dayjs } from 'dayjs';

import { useElementSize } from 'usehooks-ts';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { Table, Input, Tag, Select, notification, DatePicker, Form, Flex, Modal, Tooltip, Button, Drawer } from 'antd';
import { ColumnsType } from 'antd/es/table';
import type { GetRef } from 'antd';

import {
    LoadingOutlined,
    ReloadOutlined,
    FilterFilled,
    FileExcelOutlined,
    NotificationOutlined,
    CommentOutlined,
    MessageFilled,
    HistoryOutlined,
    SwapOutlined,
    QuestionCircleOutlined,
    WalletOutlined,
} from '@ant-design/icons';

import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';
import Transactions from '@controls/transactions/transactions';

import DepositingModal from '@src/components/balances/depositing-modal';
import TransferAccountsModal from '@src/components/balances/transfer-accounts-modal';

import { serverFetch } from '@src/core/server';

import { exception, success, noAccessError } from '@extensions/notification';
import { delayAction, dataURItoBlob, getEnumList, toFinanceString } from '@extensions/utils';
import { userLoaded } from '@store/actions';

import { IBillFilter } from '@entities/bill-filter';
import { ITruck } from '@entities/truck';
import { ITransactionParams } from '@entities/transaction-params';
import { IUserAccount } from '@entities/user-account';
import { IUserSession } from '@entities/user-session';

import { OperationType, enumLabel } from '@src/core/enums/operation-type';
import { IEnumItem } from '@enums/enum-item';
import { Permission, hasPermission } from '@enums/permission';
import { Currency } from '@enums/currency';

import { ServicePayIcon } from '@icons/index';
import { RubleFilledIcon, UsdFilledIcon, EuroFilledIcon } from '@icons/filled/index';

const dayjs = require('dayjs');

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IDebtHeader {
    loadingOn: Dayjs;
    totalDebt?: number;
    debts: Array<IDebt>;
}

interface IDebt {
    key: string;
    userId: string;
    userLogin: string;
    legalName: string;
    cityNames: string;
    consigneeCodes: string;
    markingCodes: string;
    totalDebt: number;
    useCashPayments: boolean;
    useNonCashPayments: boolean;
    billIds: Array<string>;
    currencyRate?: number;
    currencyRateOn?: Dayjs;

    userAccounts?: Array<IUserAccount>;

    loadingOn: Dayjs;

    debtComment?: string;
    debtCommentByName?: string;
    debtCommentOn?: Dayjs;
}

type TableRef = GetRef<typeof Table>;

const BillDebts = () => {
    const [containerRef, { width, height }] = useElementSize();
    const [filterRef, { width: filterWidth, height: filterHeight }] = useElementSize();

    const [modal, modalContextHolder] = Modal.useModal();

    const { RangePicker } = DatePicker;
    const { TextArea } = Input;

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [commentForm] = Form.useForm();

    const tableRef = useRef<TableRef>(null);

    const d = useAppDispatch();

    const [api, notificationContextHolder] = notification.useNotification();

    const [filter, setFilter] = useState<IBillFilter>({
        completedOnly: true,
        isPaid: false,
        isArchived: false,
    });
    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [headers, setHeaders] = useState<Array<IDebtHeader>>([]);
    const [currentDebt, setCurrentDebt] = useState<IDebt>();
    const [paymentTypes] = useState<Array<IEnumItem>>(getEnumList(OperationType, enumLabel));
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [trucks, setTrucks] = useState<Array<ITruck>>([]);

    const [openDepositingDialog, setOpenDepositingDialog] = useState<boolean>(false);
    const [openTransferAccountsDialog, setOpenTransferAccountsDialog] = useState<boolean>(false);

    const [totalBill, setTotalBill] = useState<number>();

    const [lastSelectedKey, setLastSelectedKey] = useState<React.Key>();

    const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
    const [openTransactions, setOpenTransactions] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setSelectedIds([]);
        setCurrentDebt(undefined);
        setHeaders([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('bills/debts', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения задолжности', ex, () => d(userLoaded(undefined)));
                    }),
                await serverFetch('bills/trucks', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setHeaders(result[0][0]);
                setTrucks(result[0][1].sort((a: ITruck, b: ITruck) => (dayjs(a.departureOn).isAfter(dayjs(b.departureOn)) ? -1 : 1)));

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!loading && lastSelectedKey) {
            tableRef.current?.scrollTo({ key: lastSelectedKey });
            setLastSelectedKey(undefined);
        }
    }, [loading]);

    useEffect(() => {
        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        let bill: number = 0;

        headers &&
            headers.map((h) => {
                bill += h.totalDebt || 0;
            });

        setTotalBill(bill);
    }, [headers]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            setLastSelectedKey(selectedRowKeys[0]);

            headers.map((p) => {
                p.debts.map((d) => {
                    if (d.key === selectedRowKeys[0]) {
                        setCurrentDebt(d);
                    }
                });
            });
        } else {
            setCurrentDebt(undefined);
        }
    };

    const onExport = () => {
        serverFetch('bills/debts/export', { method: 'GET', queryParams: filter })
            .then((data) => {
                let fileBody = `data:${data.contentType};base64,${data.fileContents}`;
                saveAs(dataURItoBlob(fileBody), data.fileDownloadName);
            })
            .catch((ex) => {
                exception(api, 'Ошибка выгрузки счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDepositin = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/depositing`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenDepositingDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка внесения денежных средств', ex, () => d(userLoaded(undefined)));
            });
    };

    const onTransfer = (entity: ITransactionParams) => {
        if (!entity) return;

        serverFetch(`transactions/transfer`, { method: 'POST', bodyData: entity })
            .then(() => {
                setOpenTransferAccountsDialog(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка перевода между счетами', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDebiting = () => {
        if (!currentDebt) return;

        let entity: ITransactionParams = {
            billIds: currentDebt.billIds,
            userId: currentDebt.userId,
        };

        serverFetch(`transactions/debiting`, { method: 'POST', bodyData: entity })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setRefreshRequired(true);
                exception(api, 'Ошибка оплаты счетов', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDebtComment = (data: any) => {
        if (!currentDebt) return;

        serverFetch(`bills/debtcomment`, { method: 'POST', bodyData: { billIds: currentDebt.billIds, comment: data.comment } })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
                setOpenCancelDialog(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка добавления комментария', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    items={[
                        <Input
                            style={{ width: 180 }}
                            key='userLogin'
                            placeholder='Логин'
                            value={filter.userLogin}
                            onChange={(data) => {
                                setFilter({ ...filter, userLogin: data.target.value });
                            }}
                        />,
                        <Input
                            style={{ width: 180 }}
                            key='legalName'
                            placeholder='Контрагент'
                            value={filter.legalName}
                            onChange={(data) => {
                                setFilter({ ...filter, legalName: data.target.value });
                            }}
                        />,
                        <Input
                            style={{ width: 100 }}
                            key='consigneeCode'
                            placeholder='ID клиента'
                            value={filter.consigneeCode}
                            onChange={(data) => {
                                setFilter({ ...filter, consigneeCode: data.target.value });
                            }}
                        />,
                        <Input
                            style={{ width: 150 }}
                            key='markingCode'
                            placeholder='Маркировка'
                            value={filter.markingCode}
                            onChange={(data) => {
                                setFilter({ ...filter, markingCode: data.target.value });
                            }}
                        />,
                        <Select
                            key='truck'
                            placeholder='Машина'
                            value={filter.truckIds}
                            allowClear={true}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            style={{ width: 180 }}
                            showSearch
                            onChange={(value) => setFilter({ ...filter, truckIds: value })}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            options={trucks.map((t) => {
                                return {
                                    value: t.id,
                                    label: `${dayjs.utc(t.departureOn).local().format('DD.MM.YYYY')} / ${(<b>{t.number}</b>)}`,
                                };
                            })}
                        />,
                        <Select
                            key='userType'
                            placeholder='Тип платежей'
                            value={filter?.paymentTypes}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            allowClear
                            style={{ width: 160 }}
                            onChange={(value: any) => setFilter({ ...filter, paymentTypes: value })}
                            options={paymentTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />,
                        <RangePicker
                            style={{ width: 220 }}
                            allowEmpty={[true, true]}
                            key='loading'
                            format='DD.MM.YYYY'
                            placeholder={['Зфгрузка с', 'до']}
                            cellRender={(current) => {
                                return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                            }}
                            value={filter.loadingFrom && filter.loadingTo && [filter.loadingFrom, filter.loadingTo]}
                            onChange={(value) => {
                                if (!value) {
                                    setFilter({
                                        ...filter,
                                        loadingFrom: undefined,
                                        loadingTo: undefined,
                                    });

                                    return;
                                }

                                setFilter({
                                    ...filter,
                                    loadingFrom: value[0]
                                        ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0)
                                        : undefined,
                                    loadingTo: value[1]
                                        ? dayjs(value[1]).utc(true).set('hour', 23).set('minute', 59).set('second', 59)
                                        : undefined,
                                });
                            }}
                        />,
                    ]}
                    onClear={() => setFilter({ completedOnly: true, isPaid: true, isArchived: false })}
                />
            </div>
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Оплатить счета',
                        disabled: loading || !currentDebt,
                        key: 'pay',
                        type: 'primary',
                        icon: <ServicePayIcon />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.AllowRevertPayment)) {
                                noAccessError(api, [Permission.AllowRevertPayment]);
                                return;
                            }

                            let loadingOn = currentDebt?.loadingOn && dayjs.utc(currentDebt.loadingOn).local().format('DD.MM.YYYY');

                            modal.confirm({
                                title: `Оплатить счет ${currentDebt?.userLogin} от ${loadingOn} на сумму ${currentDebt?.totalDebt}$?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDebiting();
                                },
                            });
                        },
                    },
                    {
                        label: 'Добавить комментарий',
                        key: 'commnet',
                        disabled: loading || !currentDebt,
                        icon: <CommentOutlined />,
                        onClick: () => {
                            setOpenCancelDialog(true);
                        },
                    },
                    {
                        label: 'Выгрузить в Excel',
                        key: 'exportToExcel',
                        icon: <FileExcelOutlined />,
                        onClick: () => onExport(),
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const expandedDebts = (root: IDebtHeader) => {
        const columns: ColumnsType<IDebt> = [
            {
                title: 'Логин',
                width: 180,
                render: (_, record) => {
                    return (
                        <div>
                            <Flex align='center' gap='small'>
                                <div style={{ fontWeight: 600 }}>{record.userLogin}</div>
                                {record.debtComment && (
                                    <Tooltip
                                        placement='topRight'
                                        title={
                                            <div>
                                                <div style={{ borderBottom: '1px solid #ffffff', marginBottom: 14 }}>
                                                    {record.debtCommentByName}{' '}
                                                    {dayjs.utc(record.debtCommentOn).local().format('DD.MM.YYYY')}
                                                </div>
                                                <div>{record.debtComment}</div>
                                            </div>
                                        }
                                    >
                                        <MessageFilled style={{ color: 'red', float: 'right' }} />
                                    </Tooltip>
                                )}
                            </Flex>

                            <Flex align='center' gap='small' style={{ marginTop: 7 }}>
                                <Tooltip placement='topLeft' title='Внести денежные средства на счет'>
                                    <Button
                                        icon={<WalletOutlined />}
                                        type='primary'
                                        size='small'
                                        onClick={() => {
                                            setCurrentDebt(root.debts?.find((b) => b.userId == record.userId));
                                            setOpenDepositingDialog(true);
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip placement='topLeft' title='Перевод между счетами'>
                                    <Button
                                        icon={<SwapOutlined />}
                                        size='small'
                                        onClick={() => {
                                            setCurrentDebt(root.debts?.find((b) => b.userId == record.userId));
                                            setOpenTransferAccountsDialog(true);
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip placement='topLeft' title='История операций'>
                                    <Button
                                        icon={<HistoryOutlined />}
                                        size='small'
                                        onClick={() => {
                                            setCurrentDebt(root.debts?.find((b) => b.userId == record.userId));
                                            setOpenTransactions(true);
                                        }}
                                    />
                                </Tooltip>
                            </Flex>

                            <Flex align='start' gap='small' vertical={true} style={{ marginTop: 7 }}>
                                {record.useCashPayments && (
                                    <Tag color='green' style={{ color: '#000000' }}>
                                        Наличный
                                    </Tag>
                                )}
                                {record.useNonCashPayments && (
                                    <Tag color='#ffee96' style={{ color: '#000000' }}>
                                        Безналичный
                                    </Tag>
                                )}
                            </Flex>
                        </div>
                    );
                },
            },
            {
                title: 'Расчётный счёт',
                width: 150,
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return (
                        <Flex gap='small' vertical={true}>
                            {record.userAccounts?.map((a) => {
                                return (
                                    <Flex key={a.id} align='center'>
                                        {a.currency == Currency.Rub ? (
                                            <RubleFilledIcon style={{ fontSize: 18, marginRight: 2 }} />
                                        ) : a.currency == Currency.Usd ? (
                                            <UsdFilledIcon style={{ fontSize: 18, marginRight: 2 }} />
                                        ) : (
                                            <EuroFilledIcon style={{ fontSize: 18, marginRight: 2 }} />
                                        )}

                                        <span>{toFinanceString(a.amount || 0, 2)}</span>
                                    </Flex>
                                );
                            })}
                        </Flex>
                    );
                },
            },
            {
                title: 'Контрагент',
                width: 250,
                dataIndex: 'legalName',
            },
            {
                title: 'Город',
                width: 250,
                dataIndex: 'cityNames',
            },
            {
                title: 'Сумма ($)',
                align: 'center',
                width: 120,
                onCell: (record) => ({
                    style: {
                        background: '#ffcccc',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return toFinanceString(record.totalDebt, 2);
                },
            },
            {
                title: 'ID',
                width: 350,
                dataIndex: 'consigneeCodes',
            },
            {
                title: 'Маркировка',
                width: 600,
                dataIndex: 'markingCodes',
            },
            {},
        ];

        return (
            <Table
                ref={tableRef}
                rowKey='key'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    columnWidth: 35,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChange([record.key || '']);
                        },
                    };
                }}
                columns={columns}
                dataSource={root.debts}
                pagination={false}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IDebtHeader> = [
            {
                title: 'Отгрузка',
                width: 130,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        background: '#FAFAFA',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return record.loadingOn && dayjs.utc(record.loadingOn).local().format('DD.MM.YYYY');
                },
            },
            {
                title: 'Неоплаченно ($)',
                align: 'center',
                width: 180,

                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                        background: 'var(--main-red)',
                        color: '#FFFFFF',
                    },
                }),
                render: (_, record) => {
                    return toFinanceString(record.totalDebt, 2);
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='loadingOn'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{ expandedRowRender: expandedDebts }}
                dataSource={headers}
                pagination={false}
                footer={() => (
                    <span
                        style={{
                            fontWeight: 700,
                            fontSize: 16,
                        }}
                    >
                        <span style={{ marginLeft: 5 }}>
                            Итого сумма
                            <Tag color='#294e3f' style={{ marginLeft: 5, fontSize: 18 }}>
                                {toFinanceString(totalBill, 2)} $
                            </Tag>
                        </span>
                    </span>
                )}
                scroll={{ y: height + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 233px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            {currentDebt && openDepositingDialog && (
                <DepositingModal
                    userId={currentDebt.userId}
                    userLogin={currentDebt.userLogin}
                    onCancel={() => setOpenDepositingDialog(false)}
                    onSave={(value: ITransactionParams) => onDepositin(value)}
                    api={api}
                />
            )}

            {currentDebt && openTransferAccountsDialog && (
                <TransferAccountsModal
                    userId={currentDebt.userId}
                    userLogin={currentDebt.userLogin}
                    accounts={currentDebt.userAccounts || []}
                    onCancel={() => setOpenTransferAccountsDialog(false)}
                    onSave={(value: ITransactionParams) => onTransfer(value)}
                    api={api}
                />
            )}

            <Modal
                width={400}
                title='Комментарий к задолженности'
                open={openCancelDialog}
                okText='ОК'
                closable={false}
                onOk={() => {
                    commentForm.submit();
                }}
                onCancel={() => {
                    commentForm.resetFields();
                    setOpenCancelDialog(false);
                }}
            >
                <Form colon={false} wrapperCol={{ span: 24 }} form={commentForm} onFinish={onDebtComment}>
                    <Form.Item name='comment' required rules={[{ required: true, message: 'Укажите комментарий' }]}>
                        <TextArea rows={6} />
                    </Form.Item>
                </Form>
            </Modal>

            {currentDebt && openTransactions && (
                <Drawer
                    closable
                    destroyOnClose
                    title={`Операции "${currentDebt.userLogin}"`}
                    placement='right'
                    open={true}
                    onClose={() => setOpenTransactions(false)}
                    width={1200}
                >
                    <Transactions userId={currentDebt.userId} isViewMode={false} />
                </Drawer>
            )}

            {notificationContextHolder}
            {modalContextHolder}
        </div>
    );
};

export default BillDebts;

import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { v4 as uuid } from 'uuid';

import 'core-js/actual/array/group';

const borderColor = '#90e5fc';

const TableRow = ({ remainings }) => {
    const styles = StyleSheet.create({
        row: {
            flexDirection: 'row',
            alignItems: 'right',
        },
        city: {
            backgroundColor: '#F3F6F4',
            textAlign: 'left',
            width: '90%',
            fontSize: 8,
            paddingLeft: 5,
            borderRightColor: borderColor,
            borderRightWidth: 1,
        },
        cityTotal: {
            backgroundColor: '#F3F6F4',
            paddingTop: 2,
            width: '10%',
        },

        consigneeCode: {
            paddingTop: 2,
            width: '10%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        marking: {
            paddingTop: 2,
            width: '30%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        country: {
            paddingTop: 2,
            width: '30%',
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        total: {
            paddingTop: 2,
            width: '10%',
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },

        none: {
            width: '20%',
            paddingTop: 2,
            borderRightColor: borderColor,
            borderRightWidth: 1,
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
        footer: {
            width: '100%',
            borderTopColor: borderColor,
            borderTopWidth: 1,
        },
    });

    const rows = [];
    remainings &&
        remainings.map((r) => {
            rows.push(
                <View style={styles.row} key={r.cityName}>
                    <Text style={styles.city}>
                        {r.cityName}
                        {r.tag && `   #${r.tag}`}
                    </Text>
                    <Text style={styles.cityTotal}>{r.totalQty}</Text>
                </View>
            );

            r.remainingBoxGroups.map((b) => {
                rows.push(
                    <View style={styles.row} key={uuid()}>
                        <Text style={styles.consigneeCode}>{b.consigneeCode}</Text>
                        <Text style={styles.marking}>{b.markingCode}</Text>
                        <Text style={styles.country}>{b.countryName}</Text>
                        <Text style={styles.none}></Text>
                        <Text style={styles.total}>{b.qty}</Text>
                    </View>
                );
            });

            rows.push(
                <View style={styles.row} key={uuid()}>
                    <Text style={styles.footer}></Text>
                </View>
            );
        });

    return <Fragment>{rows}</Fragment>;
};

export default TableRow;

import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { Dayjs } from 'dayjs';

import { Input, Modal, Table, Select, DatePicker, Tooltip, Form, Tag, Switch, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    ReloadOutlined,
    CarOutlined,
    DeleteOutlined,
    PlusOutlined,
    MergeCellsOutlined,
    PrinterOutlined,
    FilterFilled,
    UnorderedListOutlined,
    QuestionCircleOutlined,
    MessageFilled,
    EyeFilled,
    CheckCircleOutlined,
    CalendarOutlined,
} from '@ant-design/icons';

import { usePDF } from '@react-pdf/renderer';
import printJS from 'print-js';

import PrintProcessing from '@controls/print-processing';
import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import TruckAcceptancePdf from '@print-forms/truck-acceptance/truck-acceptance-pdf';
import TrucksPdf from '@print-forms/trucks/trucks-pdf';

import { exception, noAccessError } from '@extensions/notification';
import { getEnumList, delayAction } from '@extensions/utils';

import { userLoaded } from '@store/actions';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { ITruckFilter } from '@entities/truck-filter';
import { ITruck } from '@entities/truck';
import { IConsignment } from '@entities/consignment';
import { ICountry } from '@entities/country';
import { ICity } from '@entities/city';

import { TruckType } from '@enums/truck-type';
import { TruckStatus, enumLabel as truckStatusLabel } from '@enums/truck-status';
import { Permission, hasPermission } from '@enums/permission';
import { IEnumItem } from '@enums/enum-item';
import { WarehouseType, enumLabel as warehouseTypeLabel } from '@enums/warehouse-type';

import { TariffIcon, HoldIcon, BoxesIcon } from '@icons/index';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Trucks = () => {
    const { RangePicker } = DatePicker;

    const [modal, contextHolder] = Modal.useModal();
    const [api, notificationContextHolder] = notification.useNotification();

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [filter, setFilter] = useState<ITruckFilter>({
        type: TruckType.Import,
        statuses: [TruckStatus.New, TruckStatus.OnWay],
        isArchived: false,
    });
    const [trucks, setTrucks] = useState<Array<ITruck>>([]);
    const [currentTruck, setCurrentTruck] = useState<ITruck>();

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [selectedConsignments, setSelectedConsignments] = useState<Array<IConsignment>>([]);
    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [statuses] = useState<Array<IEnumItem>>(getEnumList(TruckStatus, truckStatusLabel));
    const [cities, setCities] = useState<Array<ICity>>([]);

    const [loadingPrintData, setLoadingPrintData] = useState(false);

    const [startAcceptancePrint, setStartAcceptancePrint] = useState<boolean>(false);
    const [printAcceptanceData, setPrintAcceptanceData] = useState<any>();
    const [pdfAcceptanceInstance, updateAcceptancePdf] = usePDF({
        document: <TruckAcceptancePdf printData={printAcceptanceData} countries={countries} />,
    });

    const [startPrint, setStartPrint] = useState<boolean>(false);
    const [printData, setPrintData] = useState<any>();
    const [pdfInstance, updatePdf] = usePDF({
        document: <TrucksPdf printData={printData} countries={countries} />,
    });

    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        setCountries([]);
        setTrucks([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('trucks/import', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0]);
                setCities(result[0][1]);
                setTrucks(result[0][2]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (printAcceptanceData) {
            updateAcceptancePdf();
            setLoadingPrintData(false);
            setStartAcceptancePrint(true);
        }
    }, [printAcceptanceData]);

    useEffect(() => {
        if (startAcceptancePrint && !pdfAcceptanceInstance.loading && pdfAcceptanceInstance.blob) {
            setStartAcceptancePrint(false);
            setPrintAcceptanceData(undefined);

            const blobURL = URL.createObjectURL(pdfAcceptanceInstance.blob);
            printJS(blobURL);
        }
    }, [startAcceptancePrint, pdfAcceptanceInstance]);

    useEffect(() => {
        if (printData) {
            updatePdf();
            setLoadingPrintData(false);
            setStartPrint(true);
        }
    }, [printData]);

    useEffect(() => {
        if (startPrint && !pdfInstance.loading && pdfInstance.blob) {
            setStartPrint(false);
            setPrintData(undefined);

            const blobURL = URL.createObjectURL(pdfInstance.blob);
            printJS(blobURL);
        }
    }, [startPrint, pdfInstance]);

    const onDelete = () => {
        if (!currentTruck) return;

        serverFetch(`trucks/${currentTruck.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления машины', ex, () => d(userLoaded(undefined)));
            });
    };

    const getPrintAcceptanceData = (printBoxes: boolean) => {
        setLoadingPrintData(true);

        serverFetch(`trucks/${currentTruck?.id}/print`, { method: 'GET' })
            .then((data) => {
                let printData = {
                    truckNumber: currentTruck?.number,
                    consignments: data,
                };

                if (printBoxes) {
                    setPrintAcceptanceData(printData);
                } else {
                    setPrintData(printData);
                }
            })
            .catch((ex) => {
                setLoadingPrintData(false);
                exception(api, 'Ошибка получения груза', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить машину',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTruck)) {
                                noAccessError(api, [Permission.ManageTruck]);
                                return;
                            }

                            navigate(`${resolved.pathname}/new`);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageTruck) ? 'Управление машиной' : 'Информация о машине'
                        }`,
                        key: 'edit',
                        disabled: !currentTruck,
                        icon: <CarOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/${currentTruck?.id}`);
                        },
                    },
                    {
                        label: 'Удалить машину',
                        key: 'delete',
                        disabled: !currentTruck || currentTruck.status !== TruckStatus.New,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteTruck)) {
                                noAccessError(api, [Permission.DeleteTruck]);
                                return;
                            }

                            modal.confirm({
                                title: `Удалить машину "${currentTruck?.number}"?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageTruckConsignment)
                                ? 'Управление грузом'
                                : 'Информация о грузе'
                        }`,
                        key: 'consignments',
                        disabled: !currentTruck,
                        icon: <UnorderedListOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/consignments/${currentTruck?.id}`);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageTruckTariff)
                                ? 'Управление тарифом'
                                : 'Информация о тарифе'
                        }`,
                        key: 'tariffs',
                        disabled: !currentTruck,
                        icon: <TariffIcon />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/tariffs/${currentTruck?.id}`);
                        },
                    },
                    {
                        label: 'Объединить машины',
                        key: 'merge',
                        disabled: selectedIds.length <= 1,
                        icon: <MergeCellsOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/merge/${JSON.stringify(selectedIds)}`);
                        },
                    },
                    {
                        label: 'Печать',
                        key: 'print',
                        disabled: loading || !currentTruck || startAcceptancePrint || startPrint,
                        icon: <PrinterOutlined />,
                        children: [
                            {
                                key: 'withoutBoxes',
                                label: 'Без коробок',
                                icon: <PrinterOutlined />,
                                onClick: () => {
                                    getPrintAcceptanceData(false);
                                },
                            },
                            {
                                key: 'withBoxes',
                                label: 'С коробками',
                                icon: <PrinterOutlined />,
                                onClick: () => {
                                    getPrintAcceptanceData(true);
                                },
                            },
                        ],
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                items={[
                    <Input
                        style={{ width: 150 }}
                        key='number'
                        placeholder='Машина'
                        value={filter.number}
                        onChange={(data) => {
                            setFilter({ ...filter, number: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='consigneeCode'
                        placeholder='ID клиента'
                        value={filter.consigneeCode}
                        onChange={(data) => {
                            setFilter({ ...filter, consigneeCode: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='userLogin'
                        placeholder='Логин'
                        value={filter.userLogin}
                        onChange={(data) => {
                            setFilter({ ...filter, userLogin: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 180 }}
                        key='legalName'
                        placeholder='Контрагент'
                        value={filter.legalName}
                        onChange={(data) => {
                            setFilter({ ...filter, legalName: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter.markingCode}
                        onChange={(data) => {
                            setFilter({ ...filter, markingCode: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter.awbNumber}
                        onChange={(data) => {
                            setFilter({ ...filter, awbNumber: data.target.value });
                        }}
                    />,
                    <Select
                        key='city'
                        placeholder='Город доставки'
                        value={filter.cityIds}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 200 }}
                        onChange={(value) => setFilter({ ...filter, cityIds: value })}
                        filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                        filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                        options={cities.map((s) => {
                            return { value: s.id, label: s.name };
                        })}
                    />,
                    <Select
                        key='status'
                        placeholder='Статус машины'
                        value={filter.statuses}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 200 }}
                        onChange={(value) => setFilter({ ...filter, statuses: value })}
                        options={statuses.map((s) => {
                            return { value: s.value, label: s.label };
                        })}
                    />,
                    <RangePicker
                        style={{ width: 200 }}
                        allowEmpty={[true, true]}
                        key='departure'
                        format='DD.MM.YYYY'
                        placeholder={['Отправка с', 'до']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={filter.departureFrom && filter.departureTo && [filter.departureFrom, filter.departureTo]}
                        onChange={(value) => {
                            if (!value) {
                                setFilter({
                                    ...filter,
                                    departureFrom: undefined,
                                    departureTo: undefined,
                                });

                                return;
                            }

                            if (value[0]) {
                                setFilter({
                                    ...filter,
                                    departureFrom: dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0),
                                });
                            }

                            if (value[1]) {
                                setFilter({
                                    ...filter,
                                    departureTo: dayjs(value[1]).utc(true).set('hour', 23).set('minute', 59).set('second', 59),
                                });
                            }
                        }}
                    />,
                    <RangePicker
                        style={{ width: 200 }}
                        allowEmpty={[true, true]}
                        key='loading'
                        format='DD.MM.YYYY'
                        placeholder={['Загрузка с', 'до']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={filter.loadingFrom && filter.loadingTo && [filter.loadingFrom, filter.loadingTo]}
                        onChange={(value) => {
                            if (!value) {
                                setFilter({
                                    ...filter,
                                    loadingFrom: undefined,
                                    loadingTo: undefined,
                                });

                                return;
                            }

                            if (value[0]) {
                                setFilter({
                                    ...filter,
                                    loadingFrom: dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0),
                                });
                            }

                            if (value[1]) {
                                setFilter({
                                    ...filter,
                                    loadingTo: dayjs(value[1]).utc(true).set('hour', 23).set('minute', 59).set('second', 59),
                                });
                            }
                        }}
                    />,
                    <Form.Item key='isArchived' label='Архив' style={{ margin: 0 }}>
                        <Switch
                            checked={filter.isArchived}
                            onChange={(value: any) => {
                                setFilter({ ...filter, statuses: [], isArchived: value });
                            }}
                        />
                    </Form.Item>,
                ]}
                onClear={() =>
                    setFilter({
                        type: TruckType.Import,
                        statuses: [TruckStatus.New, TruckStatus.OnWay],
                        isArchived: false,
                    })
                }
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let item = trucks.find((o) => o.id === selectedRowKeys[0]);
            setCurrentTruck(item);
        } else {
            setCurrentTruck(undefined);
        }

        let items: Array<IConsignment> = [];
        selectedRowKeys.map((e) => {
            let item = trucks.find((o) => o.id === e);
            if (item && item.consignments) items.push(...item.consignments);
        });

        setSelectedConsignments(items);
    };

    const expandedConsignments = (record: ICity) => {
        let consignments = record.consignments?.map((p) => {
            let boxGroups: any = [];
            let totalQty: number = 0;
            let totalVolume: number = 0;

            p.boxGroups?.map((b) => {
                if (!b.countryId) return;

                boxGroups[b.countryId] = b.qty;
                totalQty += b.qty || 0;
                totalVolume += b.calcVolume || 0;
            });

            p = {
                ...p,
                ...boxGroups,
                totalQty: totalQty,
                totalVolume: totalVolume,
            };
            return p;
        });

        const columns: ColumnsType<IConsignment> = [
            {
                title: 'ID',
                width: 60,
                align: 'center',
                render: (_: any, record: IConsignment) => {
                    return (
                        <>
                            {record.consigneeCode}
                            <Tooltip title={`${warehouseTypeLabel(record.warehouseType)} склад`}>
                                <div className={record.warehouseType === WarehouseType.Main ? 'mainWarehouseTag' : 'transitWarehouseTag'}>
                                    {record.warehouseCode}
                                </div>
                            </Tooltip>
                        </>
                    );
                },
            },
            {
                title: 'Маркировка',
                width: 130,
                dataIndex: 'markingCode',
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 110,
                dataIndex: 'totalQty',
                onCell: (record) => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
            },
            {
                title: (
                    <>
                        Объем / м<sup>3</sup>
                    </>
                ),
                align: 'center',
                dataIndex: 'totalVolume',
                width: 110,
                onCell: () => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return record.totalVolume?.toFixed(2);
                },
            },
        ];

        countries.map((c) => {
            columns.push({
                title: c.name,
                width: 230,
                align: 'center',
                onCell: () => ({
                    style: {
                        paddingTop: 0,
                    },
                }),
                render: (_: any, record: IConsignment) => {
                    let boxGroups = record.boxGroups?.filter((bg) => bg.countryId === c.id);
                    if (!boxGroups || boxGroups.length <= 0) return <></>;

                    return boxGroups.map((bg) => {
                        return (
                            <Form colon={false} key={bg.id} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ marginBottom: 5 }}>
                                <Form.Item
                                    label={
                                        <Tooltip title='Количество'>
                                            <BoxesIcon />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                    style={{ fontWeight: 600 }}
                                >
                                    {bg.qty}
                                    {bg.onHold && (
                                        <Tooltip placement='top' title={bg.onHoldReason} color='darkorchid'>
                                            <HoldIcon style={{ color: 'darkorchid', fontSize: 18, marginLeft: 5 }} />
                                        </Tooltip>
                                    )}
                                </Form.Item>
                                {bg.loadingOn && (
                                    <Form.Item
                                        label={
                                            <Tooltip title='Дата отгрузки'>
                                                <CalendarOutlined style={{ fontSize: 18 }} />
                                            </Tooltip>
                                        }
                                        className='country-form-item'
                                    >
                                        <span style={{ backgroundColor: '#FFDD2D', padding: '0 4px' }}>
                                            {dayjs.utc(bg.loadingOn).local().format('DD.MM.YYYY')}
                                        </span>
                                    </Form.Item>
                                )}
                                {bg.awbNumber && (
                                    <Form.Item label='AWB' className='country-form-item'>
                                        <span style={{ backgroundColor: '#efefef', padding: '0 4px' }}>{bg.awbNumber}</span>
                                    </Form.Item>
                                )}
                            </Form>
                        );
                    });
                },
            });
        });

        return <Table rowKey='id' size='small' columns={columns} dataSource={consignments} pagination={false} />;
    };

    const expandedCities = (record: ITruck) => {
        let cities: Array<ICity> = [];

        record.consignments?.map((e) => {
            let city = cities.find((c) => c.name === e.cityName && c.tag === e.tag);
            if (!city) {
                city = {
                    cityKey: `${e.cityName}#${e.tag}`,
                    name: e.cityName,
                    tag: e.tag,
                    totalQty: 0,
                    totalVolume: 0,
                    consignments: [],
                };

                cities.push(city);
            }

            e.boxGroups?.map((b) => {
                if (city) {
                    if (!city.totalQty) city.totalQty = 0;
                    if (!city.totalVolume) city.totalVolume = 0;

                    city.totalQty += b.qty || 0;
                    city.totalVolume += b.calcVolume || 0;
                }
            });

            city.consignments?.push(e);
        });

        const columns: ColumnsType<ICity> = [
            {
                title: 'Город доставки',
                width: 210,
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span>{record.name}</span>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 110,
                dataIndex: 'totalQty',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
            },
            {
                title: (
                    <>
                        Объем / м<sup>3</sup>
                    </>
                ),
                align: 'center',
                width: 110,
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return record.totalVolume?.toFixed(2);
                },
            },
            { title: ' ' },
        ];

        return (
            <Table
                rowKey='cityKey'
                size='small'
                columns={columns}
                dataSource={cities}
                pagination={false}
                expandable={{ expandedRowRender: expandedConsignments /* , defaultExpandedRowKeys: cityNames  */ }}
            />
        );
    };

    const renderTable = () => {
        let lines = trucks.map((t) => {
            let totalQty = 0;
            let totalVolume = 0;

            t.consignments?.map((p) => {
                p.boxGroups?.map((b) => {
                    totalQty += b.qty || 0;
                    totalVolume += b.calcVolume || 0;
                });
            });

            t = {
                ...t,
                totalQty: totalQty,
                totalVolume: totalVolume,
            };
            return t;
        });

        const columns: ColumnsType<ITruck> = [
            { title: 'Машина', dataIndex: 'number', key: 'number', width: 150 },
            {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (_, record) => {
                    return (
                        <>
                            {record.status ? truckStatusLabel(record.status) : 'Не назначена'}
                            {record.trackingUrl && (
                                <Tooltip placement='topRight' title='Показать на карте'>
                                    <a className='trackingUrl' href={record.trackingUrl} target='_blank' rel='noreferrer'>
                                        <EyeFilled />
                                    </a>
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Дата отправки',
                width: 130,
                render: (_, record) => {
                    return record.departureOn && dayjs.utc(record.departureOn).local().format('DD.MM.YYYY');
                },
            },
            { title: 'Маршрут', dataIndex: 'waypointHeaderName', key: 'waypointHeaderName', width: 200 },
            {
                width: 40,
                align: 'center',
                render: (_, record) => {
                    return (
                        record.comment && (
                            <Tooltip placement='topRight' title={record.comment}>
                                <div className='comment-btn'>
                                    <MessageFilled />
                                </div>
                            </Tooltip>
                        )
                    );
                },
            },

            {
                title: 'Общее кол-во / шт',
                dataIndex: 'totalQty',
                key: 'totalQty',
                width: 160,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 700,
                    },
                }),
            },
            {
                title: (
                    <>
                        Общий объем / м<sup>3</sup>
                    </>
                ),
                width: 160,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return record.totalVolume?.toFixed(2);
                },
            },
            {
                title: ' ',
                width: 40,
                align: 'center',
                render: (_, record) => {
                    return (
                        <>
                            {record.isTariffCompleted && (
                                <Tooltip placement='top' title='Расчеты проведены' color='green'>
                                    <CheckCircleOutlined style={{ color: 'green', fontSize: 18, marginTop: 5 }} />
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },

            { title: ' ' },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                expandable={{ expandedRowRender: expandedCities }}
                dataSource={lines}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            onSelectChange([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - ${showFilter ? '240px' : '175px'})`, x: 'max-content' }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            {(loadingPrintData || startAcceptancePrint || startPrint) && <PrintProcessing />}

            {contextHolder}
            {notificationContextHolder}
        </>
    );
};

export default Trucks;

import React from 'react';
import Icon from '@ant-design/icons';

const HoldIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18 }} {...props} />;
};
export default HoldIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 59.066 59.066'>
        <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
        <g id='SVGRepo_tracerCarrier' strokeLinecap='round' strokeLinejoin='round'></g>
        <g id='SVGRepo_iconCarrier'>
            <path d='M52.846,27.12C52.415,26.398,51.691,26,50.793,26c-1.667,0.006-3.673,0.667-5.235,1.724 c-1.441,0.975-3.53,2.782-5.293,5.863c-0.331,0.578-0.874,0.97-1.455,1.063c-0.002-0.134-0.001-0.287-0.001-0.431 c0-0.31,0.001-0.678-0.012-1.084V11c0-2.206-1.794-4-4-4s-4,1.794-4,4v15.5c0,0.275-0.225,0.5-0.5,0.5s-0.5-0.225-0.5-0.5V4 c0-2.206-1.794-4-4-4s-4,1.794-4,4v22.5c0,0.275-0.225,0.5-0.5,0.5s-0.5-0.225-0.5-0.5v-18c0-1.93-1.57-3.5-3.5-3.5 s-3.5,1.57-3.5,3.5v20c0,0.275-0.225,0.5-0.5,0.5s-0.5-0.225-0.5-0.5V16c0-1.93-1.57-3.5-3.51-3.5c-1.93,0-3.5,1.57-3.5,3.5 l0.002,27.197c0.968,13.983,9.545,15.869,17.808,15.869c6.846,0,12.995-3.396,16.027-8.824c0.39-0.63,1.445-2.288,2.696-4.254 c3.128-4.913,5.748-9.041,6.135-9.775c0.863-1.634,1.826-2.911,2.784-3.693c0.867-0.708,1.397-1.611,1.828-2.404 C53.289,29.704,53.483,28.189,52.846,27.12z'></path>{' '}
        </g>
    </svg>
);

import React from 'react';
import Icon from '@ant-design/icons';

const TariffIcon = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18 }} {...props} />;
};
export default TariffIcon;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} version='1.1' id='_x32_' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <g id='SVGRepo_iconCarrier'>
            <g>
                <path d='M379.828,384.969c-2.422,4.531-5.734,8.313-9.938,11.406c-4.234,3.063-9.266,5.375-15.141,6.891 c-2.578,0.641-5.25,1.109-7.984,1.484v17.094h-20.406v-16.766c-5.797-0.484-11.375-1.391-16.594-2.828 c-7.984-2.188-18.438-11.016-18.438-11.016c-0.906-0.516-1.484-1.438-1.609-2.469c-0.125-1.016,0.219-2.047,0.938-2.797 l10.234-10.219c1.109-1.094,2.813-1.297,4.141-0.5c0,0,7.641,6.656,13.453,8.25c5.797,1.563,11.531,2.375,17.281,2.375 c7.234,0,13.219-1.281,17.953-3.844c4.75-2.578,7.125-6.563,7.125-12c0-3.906-1.156-7-3.5-9.281 c-2.344-2.25-6.297-3.656-11.875-4.281l-18.313-1.563c-10.828-1.063-19.203-4.094-25.063-9.047 c-5.891-4.969-8.828-12.516-8.828-22.594c0-5.578,1.141-10.547,3.391-14.922c2.266-4.375,5.359-8.063,9.281-11.063 c3.922-3.016,8.484-5.281,13.656-6.781c2.188-0.625,4.453-1.047,6.766-1.422v-14.734h20.406v14.438 c4.766,0.469,9.297,1.234,13.516,2.375c7.141,1.969,14.672,7.844,14.672,7.844c0.938,0.5,1.578,1.422,1.734,2.469 s-0.188,2.094-0.922,2.844l-9.594,9.75c-1,1.031-2.594,1.313-3.891,0.625c0,0-5.688-4.031-10.594-5.313 c-4.891-1.281-10.047-1.938-15.469-1.938c-7.094,0-12.328,1.375-15.703,4.063c-3.391,2.719-5.078,6.281-5.078,10.625 c0,3.938,1.188,6.938,3.609,9.031c2.406,2.125,6.469,3.5,12.188,4.078l16.047,1.375c11.906,1.047,20.906,4.203,27.016,9.469 c6.094,5.281,9.141,12.969,9.141,23.047C383.438,375.156,382.234,380.438,379.828,384.969z'></path>
                <path d='M336.563,225.156c67.781,0,122.938,55.156,122.938,122.938s-55.156,122.938-122.938,122.938 s-122.922-55.156-122.922-122.938S268.781,225.156,336.563,225.156 M336.563,184.188c-90.531,0-163.891,73.375-163.891,163.906 S246.031,512,336.563,512c90.516,0,163.922-73.375,163.922-163.906S427.078,184.188,336.563,184.188z'></path>
                <path d='M144.391,0C71.016,0,11.516,28.969,11.516,64.719v34.563c0,35.75,59.5,64.719,132.875,64.719 c73.359,0,132.859-28.969,132.859-64.719V64.719C277.25,28.969,217.75,0,144.391,0z M144.391,26.875 c64.703,0,105.969,24.844,105.969,37.844s-41.266,37.844-105.969,37.844c-64.719,0-105.984-24.844-105.984-37.844 S79.672,26.875,144.391,26.875z'></path>
                <path d='M144.375,216c24.578,0,47.609-3.281,67.359-8.938c-19.016,16.719-34.578,37.375-45.563,60.563 c-7.063,0.563-14.344,0.906-21.797,0.906c-73.359,0-132.859-29.016-132.859-64.75v-40.625c0-0.656,0.5-1.25,1.156-1.313 c0.344-0.094,0.656,0.063,0.891,0.406c-0.156,0,0,0.469,0.922,2.688C27.344,194.125,80.625,216,144.375,216z'></path>
                <path d='M148.047,348.094c0,7.969,0.5,15.75,1.578,23.438c-1.734,0.094-3.453,0.094-5.25,0.094 c-73.359,0-132.859-28.938-132.859-64.656v-40.75c0-0.563,0.5-1.125,1.156-1.219c0.531-0.063,0.891,0.25,1.125,0.625 c-0.313-0.406-0.641-0.625,0.688,2.5c12.859,29.156,66.141,51.063,129.891,51.063c1.969,0,4.016,0,5.984-0.188 C148.781,328.516,148.047,338.188,148.047,348.094z'></path>
                <path d='M193.203,470.281c-15.078,2.969-31.547,4.516-48.828,4.516c-73.359,0-132.859-28.922-132.859-64.734v-40.656 c0-0.656,0.5-1.156,1.156-1.219c0.5-0.094,0.984,0.156,1.156,0.656c-0.344-0.406-0.734-0.813,0.656,2.375 c12.859,29.25,66.141,51.125,129.891,51.125c6.297,0,12.609-0.25,18.672-0.656C170.594,439.469,180.828,455.875,193.203,470.281z'></path>
            </g>
        </g>
    </svg>
);

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from '@store/hooks';

import { Button, Input, Form, Space } from 'antd';
import { notification } from 'antd';

import FormHeader from '@controls/form-header/form-header';

import { userLoaded } from '@store/actions';
import { exception } from '@extensions/notification';
import { serverFetch } from '@src/core/server';

import { IUser } from '@entities/user';
import { IUserSession } from '@entities/user-session';

const UserAccount = () => {
    const navigate = useNavigate();

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const d = useAppDispatch();
    const [api, contextHolder] = notification.useNotification();

    const [user, setUser] = useState<IUser>();
    const [loading, setLoading] = useState(false);
    const [allowSetPassword, setAllowSetPassword] = useState(false);

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        setLoading(true);

        serverFetch(`users/account`, { method: 'GET' })
            .then((data) => {
                setUser(data);
                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения учетной записи', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    };

    const onFinishSave = (entity: IUser) => {
        setLoading(true);

        serverFetch(`users/account`, { method: 'PUT', bodyData: entity })
            .then(() => {
                let tmpUserSession = { ...userSession };
                tmpUserSession.fullName = entity.fullName;

                d(userLoaded(tmpUserSession));

                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения учетной записи', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <FormHeader title={`Личный кабинет`} />
            {!loading && (
                <Form colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 8 }} onFinish={onFinishSave}>
                    <Form.Item initialValue={user?.email} label='Email' name='email'>
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        initialValue={user?.fullName}
                        required
                        label='ФИО'
                        name='fullName'
                        rules={[{ required: true, message: 'Укажите ФИО' }]}
                    >
                        <Input autoFocus />
                    </Form.Item>

                    <Form.Item required name='newPassword' label='Пароль' rules={[{ required: true, message: 'Укажите пароль' }]}>
                        <Space.Compact style={{ width: '100%' }}>
                            <Input.Password disabled={!allowSetPassword} placeholder={!allowSetPassword ? '*******' : ''} />
                            <Button
                                type='primary'
                                onClick={() => {
                                    setAllowSetPassword(true);
                                }}
                            >
                                Сменить пароль
                            </Button>
                        </Space.Compact>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 3, span: 8 }}>
                        <Space size={'small'} style={{ float: 'right', marginTop: 20 }}>
                            <Button type='link' onClick={() => navigate(-1)}>
                                Отменить
                            </Button>
                            <Button type='primary' htmlType='submit' loading={loading}>
                                Сохранить
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            )}

            {contextHolder}
        </>
    );
};

export default UserAccount;

import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { Modal, Table, Select, Row, Col, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    QuestionCircleOutlined,
    LoadingOutlined,
    ReloadOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    CheckOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';

import { userLoaded } from '@store/actions';

import { exception, noAccessError } from '@extensions/notification';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getEnumList, delayAction } from '@extensions/utils';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IWaypoint } from '@entities/waypoint';
import { IWaypointFilter } from '@entities/waypoint-filter';
import { IWaypointHeader } from '@entities/waypoint-header';

import { Permission, hasPermission } from '@enums/permission';
import { enumLabel as waypointTypeLabel } from '@enums/waypoint-type';
import { RouteZone, enumLabel as routeZoneLabel } from '@enums/route-zone';

const Waypoints = () => {
    const navigate = useNavigate();
    const resolved = useResolvedPath('');
    const [api, contextHolder] = notification.useNotification();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [headers, setHeaders] = useState<Array<IWaypointHeader>>([]);
    const [currentHeader, setCurrentHeader] = useState<IWaypointHeader>();
    const [selectedHeaderIds, setSelectedHeaderIds] = useState<React.Key[]>();
    const [headerRefreshRequired, setHeaderRefreshRequired] = useState<boolean>(true);
    const [headersLoading, setHeaderLoading] = useState<boolean>(false);

    const [waypoints, setWaypoints] = useState<Array<IWaypoint>>([]);
    const [currentWaypoint, setCurrentWaypoint] = useState<IWaypoint>();
    const [selectedWaypointIds, setSelectedWaypointIds] = useState<React.Key[]>([]);
    const [waypointRefreshRequired, setWaypointRefreshRequired] = useState<boolean>();

    const [waypointLoading, setWaypointLoading] = useState<boolean>(false);
    const [routeZones] = useState(getEnumList(RouteZone, routeZoneLabel));
    const [currentRouteZone, setCurrentRouteZone] = useState<RouteZone>(RouteZone.Foreign);

    useEffect(() => {
        if (!headerRefreshRequired) return;

        setHeaderRefreshRequired(false);

        setHeaderLoading(true);

        serverFetch(`waypointheaders`, { method: 'GET' })
            .then((data) => {
                setHeaders(data);

                let entity = data[0];
                if (entity) {
                    setSelectedHeaderIds([entity.id]);
                    setCurrentHeader(entity);
                }

                setHeaderLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения маршрутов', ex, () => d(userLoaded(undefined)));
                setHeaderLoading(false);
            });
    }, [headerRefreshRequired]);

    useEffect(() => {
        if (!waypointRefreshRequired) return;

        setWaypointRefreshRequired(false);
        setWaypointLoading(true);

        var filter: IWaypointFilter = { routeZone: currentRouteZone, headerId: currentHeader?.id };

        serverFetch(`waypoints/all`, { method: 'GET', queryParams: filter })
            .then((data) => {
                setWaypoints(data);
                setWaypointLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения путевых точек', ex, () => d(userLoaded(undefined)));
                setWaypointLoading(false);
            });
    }, [waypointRefreshRequired]);

    useEffect(() => {
        setWaypointRefreshRequired(true);
    }, [currentHeader]);

    const onDelete = () => {
        if (!currentWaypoint) return;

        serverFetch(`waypoints/${currentWaypoint.id}`, { method: 'DELETE' })
            .then(() => {
                onSelectChangeWaypoint([]);
                setWaypointRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления путевой точки', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderHeaderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: waypointLoading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setHeaderRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Добавить',
                        key: 'add',
                        type: 'primary',
                        icon: <PlusOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageWaypoints)) {
                                noAccessError(api, [Permission.ManageWaypoints]);
                                return;
                            }

                            navigate(`${resolved.pathname}/header/new`);
                        },
                    },
                    {
                        label: `${
                            hasPermission(userSession.permissions, Permission.ManageWaypoints)
                                ? 'Изменить'
                                : 'Информация о маршрутной точке'
                        }`,
                        key: 'edit',
                        disabled: !currentWaypoint,
                        icon: <EditOutlined />,
                        onClick: () => {
                            navigate(`${resolved.pathname}/header/${currentWaypoint?.id}`);
                        },
                    },
                    {
                        label: 'Удалить',
                        key: 'delete',
                        disabled: !currentWaypoint,
                        icon: <DeleteOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteWaypoint)) {
                                noAccessError(api, [Permission.DeleteWaypoint]);
                                return;
                            }

                            Modal.confirm({
                                title: `Удалить маршрутную точку "${currentWaypoint?.name}"?`,
                                okType: 'primary',
                                icon: <QuestionCircleOutlined />,
                                okText: 'Удалить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete();
                                },
                            });
                        },
                    },
                ]}
            />
        );
    };

    const renderWaypointToolbar = () => {
        let commands: Array<any> = [
            {
                label: 'Обновить',
                key: 'refresh',
                disabled: headersLoading,
                icon: <ReloadOutlined />,
                onClick: () => {
                    setWaypointRefreshRequired(true);
                },
            },
            {
                label: 'Добавить',
                key: 'add',
                type: 'primary',
                icon: <PlusOutlined />,
                onClick: () => {
                    if (!hasPermission(userSession.permissions, Permission.ManageWaypoints)) {
                        noAccessError(api, [Permission.ManageWaypoints]);
                        return;
                    }

                    navigate(`${resolved.pathname}/new/${currentRouteZone}/${currentHeader?.id}`);
                },
            },
            {
                label: `${
                    hasPermission(userSession.permissions, Permission.ManageWaypoints) ? 'Изменить' : 'Информация о маршрутной точке'
                }`,
                key: 'edit',
                disabled: !currentWaypoint,
                icon: <EditOutlined />,
                onClick: () => {
                    navigate(`${resolved.pathname}/${currentWaypoint?.id}`);
                },
            },
            {
                label: 'Удалить',
                key: 'delete',
                disabled: !currentWaypoint,
                icon: <DeleteOutlined />,
                onClick: () => {
                    if (!hasPermission(userSession.permissions, Permission.DeleteWaypoint)) {
                        noAccessError(api, [Permission.DeleteWaypoint]);
                        return;
                    }

                    Modal.confirm({
                        title: `Удалить маршрутную точку "${currentWaypoint?.name}"?`,
                        okType: 'primary',
                        icon: <QuestionCircleOutlined />,
                        okText: 'Удалить',
                        cancelText: 'Отмена',
                        onOk: () => {
                            onDelete();
                        },
                    });
                },
            },
        ];

        return <Toolbar commands={commands} />;
    };

    const renderFilter = () => {
        return (
            <Select
                key='routeZones'
                placeholder='Тип'
                value={currentRouteZone}
                size='middle'
                style={{ width: 220, marginBottom: 20 }}
                onChange={(value) => setCurrentRouteZone(value)}
                options={routeZones.map((t) => {
                    return { value: t.value, label: t.label };
                })}
            />
        );
    };

    const onSelectChangeHeader = (selectedRowKeys: React.Key[]) => {
        setSelectedHeaderIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = headers.find((o) => o.id === selectedRowKeys[0]);
            setCurrentHeader(entity);
        } else {
            setCurrentHeader(undefined);
        }
    };

    const onSelectChangeWaypoint = (selectedRowKeys: React.Key[]) => {
        setSelectedWaypointIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = waypoints.find((o) => o.id === selectedRowKeys[0]);
            setCurrentWaypoint(entity);
        } else {
            setCurrentWaypoint(undefined);
        }
    };

    const renderHeaderTable = () => {
        const columns: ColumnsType<IWaypointHeader> = [
            {
                title: 'Склад',
                dataIndex: 'name',
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedHeaderIds,
                    onChange: onSelectChangeHeader,
                    type: 'radio',
                }}
                columns={columns}
                dataSource={headers}
                loading={{
                    spinning: headersLoading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChangeHeader([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 235px)` }}
            />
        );
    };

    const renderWaypointTable = () => {
        const columns: ColumnsType<IWaypoint> = [
            {
                title: '#',
                dataIndex: 'sequence',
                width: 40,
            },
            {
                title: 'Тип',
                width: 180,
                render: (_, record) => {
                    return <>{waypointTypeLabel(record.type)} </>;
                },
            },
            {
                title: 'Приемка груза',
                width: 120,
                render: (_, record) => {
                    return <div style={{ textAlign: 'center' }}> {record.allowAcceptance ? <CheckOutlined /> : ''}</div>;
                },
            },
            {
                title: 'Название',
                dataIndex: 'name',
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedWaypointIds,
                    onChange: onSelectChangeWaypoint,
                    type: 'radio',
                }}
                columns={columns}
                dataSource={waypoints}
                loading={{
                    spinning: waypointLoading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            onSelectChangeWaypoint([record.id || '']);
                        },
                    };
                }}
                pagination={false}
                scroll={{ y: `calc(100vh - 235px)` }}
            />
        );
    };

    return (
        <>
            <Row>{renderFilter()}</Row>
            <Row>
                {currentRouteZone === RouteZone.Foreign && <Col span={6}> {renderHeaderToolbar()}</Col>}
                <Col offset={currentRouteZone === RouteZone.Foreign ? 1 : 0} span={17}>
                    {renderWaypointToolbar()}
                </Col>
            </Row>
            <Row>
                {currentRouteZone === RouteZone.Foreign && <Col span={6}>{renderHeaderTable()}</Col>}
                <Col offset={currentRouteZone === RouteZone.Foreign ? 1 : 0} span={17}>
                    {renderWaypointTable()}
                </Col>
            </Row>
            {contextHolder}
        </>
    );
};

export default Waypoints;

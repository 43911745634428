import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: borderColor,
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 20,
        textAlign: 'center',
        fontWeight: 600,
        fontSize: 7,
        flexGrow: 1,
    },
    consigneeCode: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    marking: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    country: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    none: {
        width: '20%',
    },
    total: {
        borderLeftWidth: 1,
        borderLeftColor: borderColor,

        width: '10%',
    },
});

const TableHeader = () => {
    return (
        <View style={styles.container}>
            <Text style={styles.consigneeCode}>ID</Text>
            <Text style={styles.marking}>Маркировка</Text>
            <Text style={styles.country}>Страна</Text>
            <Text style={styles.none}></Text>
            <Text style={styles.total}>Итого</Text>
        </View>
    );
};

export default TableHeader;

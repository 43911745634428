import React from 'react';

import { Flex, Space, Button, Tooltip, Divider } from 'antd';
import { ClearOutlined } from '@ant-design/icons';

const Filter = ({ items = [], onClear }) => {
    return (
        <>
            <Divider style={{ marginTop: 0, marginBottom: 10 }} />
            <Flex justify='space-between' style={{marginBottom:15}}>
                <Space wrap>
                    {items.map((item) => {
                        return item;
                    })}
                </Space>
                <Space direction='vertical'>
                    <Tooltip placement='left' title='Очистить фильтр'>
                        <Button icon={<ClearOutlined />} onClick={onClear} size='middle'></Button>
                    </Tooltip>
                </Space>
            </Flex>
        </>
    );
};

export default Filter;

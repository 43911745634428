import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Input, Form, Row, Col, Space, Button, Tooltip, Modal, notification } from 'antd';
import { RollbackOutlined, QuestionCircleOutlined, TruckFilled } from '@ant-design/icons';

import { MaskedInput } from 'antd-mask-input';

import FormHeader from '@controls/form-header/form-header';

import { exception } from '@extensions/notification';
import { userLoaded } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { ITruck } from '@entities/truck';
import { IUserSession } from '@entities/user-session';
import { TruckType } from '@enums/truck-type';
import { TruckStatus, enumLabel } from '@enums/truck-status';
import { Permission, hasPermission } from '@enums/permission';

//import '../warehouse.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Truck = () => {
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const { journalId, truckId } = useParams();
    const [api, contextHolder] = notification.useNotification();

    const { TextArea } = Input;

    const d = useAppDispatch();
    const navigate = useNavigate();

    const [modal, modalContextHolder] = Modal.useModal();
    const [entity, setEntity] = useState<ITruck>();
    const [isViewOnly, setIsViewOnly] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [phone, setPhone] = useState<string>();

    useEffect(() => {
        initData();
    }, []);

    useEffect(() => {
        setIsViewOnly(!journalId || entity?.status === TruckStatus.Finished);
    }, [entity]);

    const initData = async () => {
        if (!truckId) {
            setEntity({
                id: undefined,
                number: undefined,
                departureOn: null,
                type: TruckType.Delivery,
                status: TruckStatus.New,
            });
            return;
        }

        setLoading(true);

        serverFetch(`trucks/${truckId}`, { method: 'GET' })
            .then((data) => {
                setEntity(data);
                setPhone(data.driverPhone);
                setLoading(false);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка получения машины', ex, () => d(userLoaded(undefined)));
            });
    };

    const onRevertOnway = () => {
        if (!truckId) return;

        serverFetch(`trucks/delivery/${truckId}/revertonway`, { method: 'POST' })
            .then(() => {
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка изменения статуса', ex, () => d(userLoaded(undefined)));
            });
    };

    const onCompleteShipment = () => {
        setLoading(true);

        serverFetch(`warehouse/completeshipment/${journalId}`, { method: 'POST' })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка отправки машины', ex, () => d(userLoaded(undefined)));
            });
    };

    const onFinish = (data: any) => {
        if (!entity) return;

        setLoading(true);

        entity.driverPhone = phone;
        let result = { ...entity, ...data, journalId: journalId };

        serverFetch(`trucks`, { method: truckId ? 'PUT' : 'POST', bodyData: result })
            .then(() => {
                setLoading(false);
                navigate(-1);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка сохранения машины', ex, () => d(userLoaded(undefined)));
            });
    };

    return (
        <>
            <Row>
                <FormHeader title={`${truckId ? 'Управление машиной' : 'Добавить машину'}`} />
            </Row>

            {entity && (
                <>
                    <Row>
                        <Col span={12}>
                            <Form colon={false} labelCol={{ span: 6 }} wrapperCol={{ span: 10 }} onFinish={onFinish}>
                                <Form.Item
                                    initialValue={entity?.number}
                                    required={true}
                                    label='Номер машины'
                                    name='number'
                                    rules={[{ required: true, message: 'Укажите номер машины' }]}
                                    wrapperCol={{ span: 5 }}
                                >
                                    <Input
                                        disabled={isViewOnly}
                                        autoFocus
                                        onChange={(data) => {
                                            if (!entity) return;

                                            setEntity({ ...entity, number: data.target.value });
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label='Статус' wrapperCol={{ span: 5 }}>
                                    <Space.Compact style={{ width: '100%' }}>
                                        <Input disabled={true} defaultValue={enumLabel(entity?.status)} />
                                        {hasPermission(userSession.permissions, Permission.ManageDeliveryStatus) &&
                                            entity.status === TruckStatus.Finished && (
                                                <Tooltip title='Вернуть статус "В Пути"'>
                                                    <Button
                                                        type='primary'
                                                        icon={<RollbackOutlined />}
                                                        onClick={() => {
                                                            modal.confirm({
                                                                title: `Изменить статус машины на "В Пути"?`,
                                                                okType: 'primary',
                                                                icon: <QuestionCircleOutlined />,
                                                                okText: 'ОК',
                                                                cancelText: 'Отмена',
                                                                onOk: () => {
                                                                    onRevertOnway();
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}

                                        {hasPermission(userSession.permissions, Permission.ManageDeliveryStatus) &&
                                            entity.status === TruckStatus.New && (
                                                <Tooltip title='Отправить машину'>
                                                    <Button
                                                        type='primary'
                                                        icon={<TruckFilled />}
                                                        onClick={() => {
                                                            modal.confirm({
                                                                title: `Изменить статус машины на "В Пути"?`,
                                                                okType: 'primary',
                                                                icon: <QuestionCircleOutlined />,
                                                                okText: 'ОК',
                                                                cancelText: 'Отмена',
                                                                onOk: () => {
                                                                    onCompleteShipment();
                                                                },
                                                            });
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                    </Space.Compact>
                                </Form.Item>
                                <Form.Item
                                    initialValue={entity?.driverPhone}
                                    required={true}
                                    label='Телефон водителя'
                                    name='driverPhone'
                                    rules={[{ required: true, message: 'Укажите телефон водителя' }]}
                                >
                                    <MaskedInput
                                        disabled={isViewOnly}
                                        size='middle'
                                        mask={'+7 (000) 000-00-00'}
                                        onChange={(data) => {
                                            setPhone(data.maskedValue);
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item initialValue={entity?.trackingUrl} label='Трекинг URL' name='trackingUrl'>
                                    <Input
                                        disabled={isViewOnly}
                                        onChange={(data) => {
                                            if (!entity) return;

                                            setEntity({ ...entity, trackingUrl: data.target.value });
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label='Комментарий' name='comment' initialValue={entity?.comment}>
                                    <TextArea
                                        disabled={isViewOnly}
                                        rows={4}
                                        onChange={(data) => {
                                            setEntity({ ...entity, comment: data.target.value });
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
                                    <Space size={'small'} style={{ float: 'right' }}>
                                        <Button type='link' onClick={() => navigate(-1)}>
                                            Закрыть
                                        </Button>
                                        {!isViewOnly && (
                                            <Button type='primary' htmlType='submit' loading={loading}>
                                                Сохранить
                                            </Button>
                                        )}
                                    </Space>
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </>
            )}

            {contextHolder}
            {modalContextHolder}
        </>
    );
};

export default Truck;

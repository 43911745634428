import React from 'react';
import Icon from '@ant-design/icons';

const CustomerRate = ({ ...props }) => {
    return <Icon component={svg} style={{ fontSize: 18 }} {...props} />;
};
export default CustomerRate;

const svg = ({ ...props }) => (
    <svg fill='currentColor' {...props} version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
        <g id='SVGRepo_iconCarrier'>
            <g>
                <g>
                    <g>
                        <path d='M255.997,159.418c36.473,0,66.145-29.673,66.145-66.145s-29.673-66.145-66.145-66.145 c-36.473,0-66.145,29.673-66.145,66.145S219.524,159.418,255.997,159.418z'></path>
                        <path d='M149.327,318.736h213.341c8.818,0,15.967-7.149,15.967-15.967c0-67.623-55.015-122.637-122.637-122.637 S133.36,235.146,133.36,302.769C133.36,311.587,140.509,318.736,149.327,318.736z'></path>
                        <path d='M320.438,380.025l-34.632-5.033l-15.488-31.381c-5.848-11.851-22.791-11.843-28.636,0l-15.488,31.381l-34.632,5.033 c-13.077,1.9-18.306,18.016-8.849,27.234l25.059,24.427l-5.916,34.492c-2.234,13.025,11.477,22.977,23.167,16.832l30.976-16.284 l30.976,16.284c11.595,6.096,25.418-3.71,23.167-16.832l-5.916-34.492l25.059-24.427 C338.749,398.034,333.507,381.924,320.438,380.025z'></path>
                        <path d='M142.55,380.025l-34.632-5.033L92.43,343.611c-5.848-11.849-22.791-11.843-28.636,0l-15.487,31.381l-34.632,5.033 c-13.077,1.9-18.305,18.016-8.849,27.234l25.059,24.427l-5.916,34.492c-2.234,13.025,11.477,22.977,23.167,16.832l30.976-16.285 l30.976,16.285c11.595,6.096,25.418-3.71,23.167-16.832l-5.916-34.492l25.06-24.427 C160.862,398.034,155.619,381.924,142.55,380.025z'></path>
                        <path d='M498.324,380.025l-34.632-5.033l-15.487-31.381c-4.797-9.721-15.384-8.901-14.318-8.901 c-6.077,0-11.628,3.451-14.318,8.901l-15.488,31.381l-34.632,5.033c-13.077,1.9-18.305,18.016-8.849,27.234l25.061,24.427 l-5.916,34.492c-2.234,13.025,11.476,22.977,23.167,16.832l30.976-16.285l30.976,16.285c11.595,6.096,25.418-3.71,23.167-16.832 l-5.916-34.492l25.06-24.427C516.635,398.036,511.395,381.924,498.324,380.025z'></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

import React, { useState, useEffect } from 'react';
import { useNavigate, useResolvedPath } from 'react-router-dom';

import { Dayjs } from 'dayjs';

import { Table, Input, Select, Modal, Tooltip, Tag, DatePicker, Form, Switch, notification } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    ReloadOutlined,
    CarOutlined,
    FilterFilled,
    CheckOutlined,
    QuestionCircleOutlined,
    EyeFilled,
    CalendarOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception, noAccessError, error } from '@extensions/notification';

import { useAppDispatch, useAppSelector } from '@store/hooks';
import { userLoaded } from '@store/actions';
import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { ITruckFilter } from '@entities/truck-filter';
import { getEnumList, delayAction } from '@extensions/utils';
import { ITruck } from '@entities/truck';
import { ICity } from '@entities/city';
import { IConsignment } from '@entities/consignment';
import { ICountry } from '@entities/country';

import { Permission, hasPermission } from '@enums/permission';
import { TruckStatus, enumLabel as truckStatusLabel } from '@enums/truck-status';
import { TruckType } from '@enums/truck-type';
import { IEnumItem } from '@enums/enum-item';

import { BoxesIcon } from '@icons/index';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const Trucks = () => {
    const { RangePicker } = DatePicker;

    const navigate = useNavigate();
    const resolved = useResolvedPath('');

    const [api, notificationContextHolder] = notification.useNotification();
    const [modal, contextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [trucks, setTrucks] = useState<Array<ITruck>>([]);
    const [currentTruck, setCurrentTruck] = useState<ITruck>();
    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [cities, setCities] = useState<Array<ICity>>([]);

    const [statuses] = useState<Array<IEnumItem>>(getEnumList(TruckStatus, truckStatusLabel));

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [showFilter, setShowFilter] = useState<boolean>(true);
    const [filter, setFilter] = useState<ITruckFilter>({
        type: TruckType.Delivery,
        statuses: [TruckStatus.New, TruckStatus.OnWay],
        isArchived: false,
    });

    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setSelectedIds([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch('countries', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('trucks/delivery', { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машин', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setCountries(result[0][0]);
                setCities(result[0][1]);
                setTrucks(result[0][2]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    const onFinish = () => {
        setLoading(true);

        var journalIds: Array<string> = [];

        trucks.map((t: ITruck) => {
            if (t.id && t.journalId && selectedIds.includes(t.id)) {
                journalIds.push(t.journalId);
            }
        });

        serverFetch(`trucks/delivery/finish`, { method: 'POST', bodyData: journalIds })
            .then(() => {
                setLoading(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка завершения доставки', ex, () => d(userLoaded(undefined)));
            });
    };

    useEffect(() => {
        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Управление машиной',
                        key: 'add',
                        disabled: !currentTruck,
                        icon: <CarOutlined />,
                        onClick: () => {
                            if (currentTruck?.id) {
                                navigate(`${resolved.pathname}/${currentTruck.id}/${currentTruck?.journalId}`);
                            } else {
                                navigate(`${resolved.pathname}/new/${currentTruck?.journalId}`);
                            }
                        },
                    },
                    {
                        label: 'Завершить доставку',
                        key: 'complete',
                        type: 'primary',
                        disabled: selectedIds.length <= 0,
                        icon: <CheckOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageDeliveryStatus)) {
                                noAccessError(api, [Permission.ManageDeliveryStatus]);
                                return;
                            }

                            if (!currentTruck?.canFinishDelivery && !hasPermission(userSession.permissions, Permission.FullAccess)) {
                                error(
                                    api,
                                    'Завершение доставки',
                                    'Доставка может быть завершена только на конечном складе или администратором'
                                );
                                return;
                            }

                            modal.confirm({
                                title: `Завершить выбранные доставки?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'Завершить',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onFinish();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <Filter
                items={[
                    <Input
                        style={{ width: 130 }}
                        key='journalNumber'
                        placeholder='Журнал'
                        value={filter.journalNumber}
                        onChange={(data) => {
                            setFilter({ ...filter, journalNumber: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='number'
                        placeholder='Машина'
                        value={filter.number}
                        onChange={(data) => {
                            setFilter({ ...filter, number: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='consigneeCode'
                        placeholder='ID клиента'
                        value={filter.consigneeCode}
                        onChange={(data) => {
                            setFilter({ ...filter, consigneeCode: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='markingCode'
                        placeholder='Маркировка'
                        value={filter.markingCode}
                        onChange={(data) => {
                            setFilter({ ...filter, markingCode: data.target.value });
                        }}
                    />,
                    <Input
                        style={{ width: 130 }}
                        key='awbNumber'
                        placeholder='AWB'
                        value={filter.awbNumber}
                        onChange={(data) => {
                            setFilter({ ...filter, awbNumber: data.target.value });
                        }}
                    />,
                    <Select
                        key='status'
                        placeholder='Статус машины'
                        value={filter.statuses}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 200 }}
                        onChange={(value) => setFilter({ ...filter, statuses: value })}
                        options={statuses.map((s) => {
                            return { value: s.value, label: s.label };
                        })}
                    />,
                    <Select
                        key='city'
                        placeholder='Город доставки'
                        value={filter.cityIds}
                        size='middle'
                        mode='multiple'
                        maxTagCount='responsive'
                        allowClear
                        style={{ width: 200 }}
                        onChange={(value) => setFilter({ ...filter, cityIds: value })}
                        filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                        filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                        options={cities.map((s) => {
                            return { value: s.id, label: s.name };
                        })}
                    />,
                    <RangePicker
                        style={{ width: 200 }}
                        allowEmpty={[true, true]}
                        key='departure'
                        format='DD.MM.YYYY'
                        placeholder={['Отправка с', 'до']}
                        cellRender={(current) => {
                            return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                        }}
                        value={filter.departureFrom && filter.departureTo && [filter.departureFrom, filter.departureTo]}
                        onChange={(value) => {
                            if (!value) {
                                setFilter({
                                    ...filter,
                                    departureFrom: undefined,
                                    departureTo: undefined,
                                });

                                return;
                            }

                            if (value[0]) {
                                setFilter({
                                    ...filter,
                                    departureFrom: dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0),
                                });
                            }

                            if (value[1]) {
                                setFilter({
                                    ...filter,
                                    departureTo: dayjs(value[1]).utc(true).set('hour', 23).set('minute', 59).set('second', 59),
                                });
                            }
                        }}
                    />,
                    <Form.Item key='isArchived' label='Архив' style={{ margin: 0 }}>
                        <Switch
                            checked={filter.isArchived}
                            onChange={(value: any) => {
                                setFilter({ ...filter, statuses: [], isArchived: value });
                            }}
                        />
                    </Form.Item>,
                ]}
                onClear={() => setFilter({ type: TruckType.Delivery, statuses: [TruckStatus.New, TruckStatus.OnWay], isArchived: false })}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = trucks.find((t: ITruck) => t.id === selectedRowKeys[0]);

            setCurrentTruck(entity);
        } else {
            setCurrentTruck(undefined);
        }
    };

    const expandedConsignments = (record: ICity) => {
        let consignments = record.consignments?.map((p) => {
            let boxGroups: any = [];
            let totalQty: number = 0;

            p.boxGroups?.map((b) => {
                if (!b.countryId) return;

                boxGroups[b.countryId] = { qty: b.qty, awbNumber: b.awbNumber };
                totalQty += b.qty || 0;
            });

            p = {
                ...p,
                ...boxGroups,
                totalQty: totalQty,
            };
            return p;
        });

        const columns: ColumnsType<IConsignment> = [
            {
                title: 'ID',
                dataIndex: 'consigneeCode',
                width: 80,
                align: 'center',
            },
            {
                title: 'Маркировка',
                width: 132,
                dataIndex: 'markingCode',
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 118,
                dataIndex: 'totalQty',
                onCell: (record) => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
            },
        ];

        countries.map((c) => {
            columns.push({
                title: c.name,
                width: 230,
                render: (_: any, record: IConsignment) => {
                    let boxGroup = record.boxGroups?.find((bg) => bg.countryId === c.id);

                    return (
                        <Form colon={false} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ margin: '-12px 0 0' }}>
                            {boxGroup?.qty && (
                                <Form.Item
                                    label={
                                        <Tooltip title='Количество'>
                                            <BoxesIcon />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                    style={{ fontWeight: 600 }}
                                >
                                    {boxGroup.qty}
                                </Form.Item>
                            )}
                            {boxGroup?.loadingOn && (
                                <Form.Item
                                    label={
                                        <Tooltip title='Дата отгрузки'>
                                            <CalendarOutlined style={{ fontSize: 18 }} />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                >
                                    <span style={{ backgroundColor: '#FFDD2D', padding: '0 4px' }}>
                                        {dayjs.utc(boxGroup?.loadingOn).local().format('DD.MM.YYYY')}
                                    </span>
                                </Form.Item>
                            )}
                            {boxGroup?.awbNumber && (
                                <Form.Item label='AWB' className='country-form-item'>
                                    <span style={{ backgroundColor: '#efefef', padding: '0 4px' }}>{boxGroup.awbNumber}</span>
                                </Form.Item>
                            )}
                        </Form>
                    );
                },
            });
        });

        return <Table rowKey='id' size='small' columns={columns} dataSource={consignments} pagination={false} />;
    };

    const expandedCities = (record: ITruck) => {
        let cities: Array<ICity> = [];
        let cityNames = [];

        record.consignments?.map((e) => {
            let city = cities.find((c) => c.name === e.cityName && c.tag === e.tag);
            if (!city) {
                city = {
                    cityKey: `${e.cityId}#${e.tag}`,
                    name: e.cityName,
                    tag: e.tag,
                    totalQty: 0,
                    consignments: [],
                };

                cities.push(city);
                cityNames.push(e.cityName);
            }

            e.boxGroups?.map((b) => {
                if (city) {
                    if (!city.totalQty) city.totalQty = 0;

                    city.totalQty += b.qty || 0;
                }
            });

            city.consignments?.push(e);
        });

        const columns: ColumnsType<ICity> = [
            {
                title: 'Город доставки',
                width: 225,
                onCell: () => ({
                    style: {
                        fontWeight: 700,
                    },
                }),
                render: (_, record) => {
                    return (
                        <>
                            <span>{record.name}</span>
                            {record.tag && (
                                <Tag color='var(--primary-color)' style={{ marginLeft: 10, color: '#000000' }}>
                                    #{record.tag}
                                </Tag>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Кол-во / шт',
                align: 'center',
                width: 130,
                dataIndex: 'totalQty',
                onCell: () => ({
                    style: {
                        background: '#fff8d5',
                        fontWeight: 700,
                    },
                }),
            },
            { title: ' ' },
        ];

        return (
            <Table
                rowKey='cityKey'
                size='small'
                columns={columns}
                dataSource={cities}
                pagination={false}
                expandable={{ expandedRowRender: expandedConsignments /* , defaultExpandedRowKeys: cityNames  */ }}
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<ITruck> = [
            {
                title: 'Журнал',
                dataIndex: 'journalNumber',
                width: 100,
            },
            {
                title: 'Номер',
                dataIndex: 'number',
                width: 100,
            },
            {
                title: 'Статус',
                width: 100,
                render: (_, record) => {
                    return (
                        <>
                            {record.status ? truckStatusLabel(record.status) : 'Не назначена'}
                            {record.trackingUrl && (
                                <Tooltip placement='topRight' title='Показать на карте'>
                                    <a className='trackingUrl' href={record.trackingUrl} target='_blank' rel='noreferrer'>
                                        <EyeFilled />
                                    </a>
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: 'Дата / время отправки',
                width: 180,
                align: 'center',
                onCell: () => ({
                    style: {
                        background: '#fafafa',
                        fontWeight: 600,
                    },
                }),
                render: (_, record) => {
                    return record.departureOn && dayjs.utc(record.departureOn).local().format('DD.MM.YYYY HH:mm');
                },
            },
            {
                title: 'Общее кол-во / шт',
                align: 'center',
                width: 160,
                dataIndex: 'totalQty',
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
            },
            {
                title: 'Телефон водителя',
                dataIndex: 'driverPhone',
                width: 150,
            },
            {
                title: 'Маршрут',
                key: 'route',
                render: (_, record) => {
                    return <>{record.citiesRoute?.join(' - ')} </>;
                },
            },
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                columns={columns}
                dataSource={trucks}
                pagination={false}
                scroll={{ y: `calc(100vh - ${showFilter ? 240 : 182}px)`, x: 'max-content' }}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                }}
                onRow={(record: ITruck) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                expandable={{ expandedRowRender: expandedCities }}
            />
        );
    };

    return (
        <>
            {renderToolbar()}
            {showFilter && renderFilter()}
            {renderTable()}

            {contextHolder}
            {notificationContextHolder}
        </>
    );
};

export default Trucks;

import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const PrintProcessing = () => {
    return (
        <div className='processing'>
            <Spin className='processing-area' tip='Начало печати...' indicator={<LoadingOutlined style={{ fontSize: 44 }} spin />} />
        </div>
    );
};

export default PrintProcessing;

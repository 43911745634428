import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Steps, Input, Button, Form, Space, Tooltip, Modal, Row, Col, DatePicker, Switch } from 'antd';
import { NodeIndexOutlined, ReloadOutlined, CarOutlined, RollbackOutlined, QuestionCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { notification } from 'antd';

import Toolbar from '@controls/toolbar/toolbar';
import { userLoaded } from '@store/actions';

import { exception } from '@extensions/notification';
import { useAppDispatch, useAppSelector } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IWaypoint } from '@entities/waypoint';
import { ITrackpoint } from '@entities/trackpoint';
import { ITruck } from '@entities/truck';

import { WaypointType, enumLabel as waypointTypeLabel } from '@enums/waypoint-type';
import { Permission, hasPermission } from '@enums/permission';
import { TruckStatus } from '@src/core/enums/truck-status';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface IPoint {
    waypointid: string | undefined;
    warehouseid: string | undefined;
    title: string;
    description?: string;
    subTitle?: string;
}

interface ITrackpoints {
    truck: ITruck;
    viewOnly: boolean;
}

const Trackpoints = (props: ITrackpoints) => {
    const navigate = useNavigate();

    const { TextArea } = Input;
    const [api, contextHolder] = notification.useNotification();

    const { truck, viewOnly } = props;

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);

    const [warehouseIds, setWarehouseIds] = useState<Array<string | undefined>>([]);
    const [baseWaypoints, setBaseWaypoints] = useState<Array<IWaypoint>>([]);
    const [points, setPoints] = useState<Array<IPoint>>([]);
    const [trackpoints, setTrackpoints] = useState<Array<ITrackpoint>>([]);
    const [canAddExtraTrackpoint, setCanAddExtraTrackpoint] = useState<boolean>();
    const [currentindex, setCurrentIndex] = useState<number>(-1);
    const [nextPoint, setNextPoint] = useState<ITrackpoint>();

    const [openAddPoint, setOpenAddPoint] = useState<boolean>(false);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setCurrentIndex(-1);
        setNextPoint(undefined);
        setPoints([]);
        setTrackpoints([]);
        setBaseWaypoints([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch(`trackpoints/${truck.id}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения маршрутных точек', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`trucks/${truck.id}/foreignwaypoints`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения путевых точек', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setTrackpoints(result[0][0]);

                var waypoints = result[0][1] as Array<IWaypoint>;
                setBaseWaypoints(waypoints);

                setWarehouseIds([...new Set(waypoints.map((w) => w.warehouseId))]);
                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        setCanAddExtraTrackpoint(false);

        let points: Array<IPoint> = [];

        let tmpTrackpoints = [...trackpoints];
        let lastTrackPoint: ITrackpoint | undefined = tmpTrackpoints.pop();

        if (baseWaypoints.length <= 0) return;

        let waypoints: Array<IWaypoint> = [];
        let extraTrackpoints = trackpoints.filter((t) => t.isExtraPoint);

        baseWaypoints.map((w) => {
            let extraPoints = extraTrackpoints.filter((t) => t.waypointId === w.id);
            if (extraPoints.length > 0) {
                extraPoints.map((p) => {
                    waypoints.push({ ...w, type: WaypointType.Onway, extraTrackpointId: p.id });
                });
            }
            waypoints.push(w);
        });

        waypoints.map((w) => {
            let title: any = '';

            let trackpoint = w.extraTrackpointId
                ? trackpoints.find((t) => t.id === w.extraTrackpointId)
                : trackpoints.find((t) => !t.isExtraPoint && t.waypointId === w.id);

            if (w.type === WaypointType.Onway) {
                title = (
                    <>
                        <CarOutlined />
                        {lastTrackPoint?.waypointId === w.id && renderDeleteButton(lastTrackPoint?.id)}
                    </>
                );
            } else {
                title = (
                    <>
                        {w.name} ({waypointTypeLabel(w.type)})
                        {lastTrackPoint?.waypointId === w.id && !lastTrackPoint?.isExtraPoint && renderDeleteButton(lastTrackPoint?.id)}
                        {truck.status == TruckStatus.OnWay &&
                            lastTrackPoint?.waypointId === w.id &&
                            !lastTrackPoint?.isExtraPoint &&
                            w.type == WaypointType.Destination &&
                            hasPermission(userSession.permissions, Permission.FullAccess) &&
                            renderFinishButton(truck.id)}
                    </>
                );
            }

            let point: IPoint = { waypointid: w.id, warehouseid: w.warehouseId, title: title };

            if (trackpoint) {
                point.description = trackpoint.comment;
                point.subTitle = trackpoint && trackpoint.date && dayjs.utc(trackpoint.date).local().format('DD.MM.YYYY');
            }

            points.push(point);
        });

        if (lastTrackPoint) {
            let waypoint = lastTrackPoint.isExtraPoint
                ? waypoints.find((w) => w.extraTrackpointId === lastTrackPoint?.id)
                : waypoints.find((w) => !w.extraTrackpointId && w.id === lastTrackPoint?.waypointId);

            if (waypoint) {
                setCanAddExtraTrackpoint(waypoint.type == WaypointType.Onway);

                let index = waypoints.indexOf(waypoint);
                setCurrentIndex(index);

                let nexWaypoint = waypoints[index + 1];

                if (nexWaypoint) {
                    setNextPoint({
                        id: undefined,
                        truckId: truck.id,
                        waypointId: nexWaypoint.id,
                        prevWaypointId: nexWaypoint.type == WaypointType.Onway ? waypoint.id : undefined,
                        isExtraPoint: false,
                    });
                }
            }
        } else {
            setNextPoint({
                id: undefined,
                truckId: truck.id,
                waypointId: waypoints[0].id,
                isExtraPoint: false,
            });
        }

        setPoints(points);
    }, [baseWaypoints, trackpoints]);

    const onFinish = () => {
        if (!nextPoint) return;

        if (!nextPoint.date) {
            nextPoint.date = dayjs();
        }

        serverFetch(`trackpoints`, { method: 'POST', bodyData: nextPoint })
            .then(() => {
                setLoading(false);
                setOpenAddPoint(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка добавления точки маршрута', ex, () => d(userLoaded(undefined)));
            });
    };

    const onDelete = (id: string | undefined) => {
        if (!id) return;

        serverFetch(`trackpoints/${id}`, { method: 'DELETE' })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка удаления точки маршрута', ex, () => d(userLoaded(undefined)));
            });
    };

    const onCompleteAcceptance = (id: string | undefined) => {
        if (!id) return;

        serverFetch(`warehouse/${id}/completeacceptance`, { method: 'POST' })
            .then(() => {
                navigate(-1);
            })
            .catch((ex) => {
                exception(api, 'Ошибка завершения доставки', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderDeleteButton = (lastTrackPointId: string | undefined) => {
        if (!hasPermission(userSession.permissions, Permission.ManageTruck)) return '';

        return (
            lastTrackPointId && (
                <Tooltip title='Отменить'>
                    <Button
                        style={{ marginLeft: 10 }}
                        icon={<RollbackOutlined />}
                        size={'small'}
                        type='primary'
                        onClick={() => {
                            Modal.confirm({
                                title: `Отменить маршрутную точку?`,
                                okType: 'primary',
                                icon: <QuestionCircleOutlined />,
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onDelete(lastTrackPointId);
                                },
                            });
                        }}
                    />
                </Tooltip>
            )
        );
    };

    const renderFinishButton = (lastTrackPointId: string | undefined) => {
        return (
            lastTrackPointId && (
                <Tooltip title='Завершить'>
                    <Button
                        style={{ marginLeft: 10 }}
                        icon={<CheckOutlined />}
                        size={'small'}
                        type='primary'
                        onClick={() => {
                            Modal.confirm({
                                title: `Завершить доставку?`,
                                okType: 'primary',
                                icon: <QuestionCircleOutlined />,
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onCompleteAcceptance(lastTrackPointId);
                                },
                            });
                        }}
                    />
                </Tooltip>
            )
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setOpenAddPoint(false);
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Перейти к следующей точке',
                        key: 'goNext',
                        disabled: !nextPoint,
                        type: 'primary',
                        icon: <NodeIndexOutlined />,
                        onClick: () => {
                            setOpenAddPoint(true);
                        },
                    },
                ]}
            />
        );
    };

    const renderSteps = () => {
        let addOffset: boolean = false;

        return warehouseIds.map((id) => {
            let element = (
                <Col key={id} offset={addOffset ? 2 : 0}>
                    <Steps progressDot direction='vertical' current={currentindex} items={points.filter((p) => p.warehouseid == id)} />
                </Col>
            );

            if (!addOffset) addOffset = true;

            return element;
        });
    };

    return (
        <>
            {!viewOnly && renderToolbar()}

            {openAddPoint && (
                <Form labelWrap={true} colon={false} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} onFinish={onFinish}>
                    <Form.Item label='Дата'>
                        <DatePicker
                            autoFocus
                            defaultValue={dayjs()}
                            format={'DD.MM.YYYY'}
                            onChange={(data) => {
                                nextPoint &&
                                    setNextPoint({
                                        ...nextPoint,
                                        date: dayjs(data).utc(true).set('hour', 0).set('minute', 0).set('second', 0),
                                    });
                            }}
                        />
                    </Form.Item>
                    {canAddExtraTrackpoint && (
                        <Form.Item label='Промежуточная точка'>
                            <Switch
                                onChange={(value) => {
                                    nextPoint && setNextPoint({ ...nextPoint, isExtraPoint: value });
                                }}
                            />
                        </Form.Item>
                    )}

                    <Form.Item label='Комментарий'>
                        <TextArea
                            rows={4}
                            onChange={(data) => {
                                nextPoint && setNextPoint({ ...nextPoint, comment: data.target.value });
                            }}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Space size={'small'} style={{ float: 'right' }}>
                            <Button onClick={() => setOpenAddPoint(false)}>Отменить</Button>
                            <Button type='primary' htmlType='submit' loading={loading}>
                                Добавить
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            )}

            <div style={{ marginTop: 20 }}>{!loading && <Row>{renderSteps()}</Row>}</div>

            {contextHolder}
        </>
    );
};

export default Trackpoints;
